/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ChannelService } from "../channel.service";
import { Member } from "../models/member.model";
import { File } from "../models/file.model";
import { debounceTime, delay, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from "rxjs/operators";
import {
  ChannelAdd,
  ChannelBulkAdd,
  ChannelDelete,
  ChannelFilesLoadRequest,
  ChannelFilesLoadSuccess,
  ChannelFilesOffsetUpdate,
  ChannelFilesRemove,
  ChannelInfoUpdate,
  ChannelMemberOffsetUpdate,
  ChannelMembersAdd,
  ChannelMembersLoadRequest,
  ChannelMembersLoadSuccess,
  ChannelMembersRemove,
  ChannelNoAccess,
  ChannelSearchStringUpdate,
  ChannelUpdate,
  ChannelUserJidUpdate,
  PrivateChannelInfoUpdate,
  PublicChannelInfoUpdate,
  SearchedChannelInfoUpdate,
  SetSelectedChannelId,
  SubscribedChannelInfoUpdate,
  ChannelSideBarTabChange,
  ChannelMembersUpdate,
  TrashedChannelInfoUpdate,
  ArchivedChannelInfoUpdate,
  FavoriteChannelInfoUpdate, ChannelAvatarFailedToLoad, FilteredTopicsInfoUpdate, MyChannelInfoUpdate,   SetArchiveChannelsSortBy, ChannelAvatarFailedToLoadRemove, ChannelNotFound, SubChannelBulkAdd, SubChannelInfoUpdate,  IOMChannelInfoUpdate,
  IntranetChannelsInfoUpdate,
  ExtranetChannelsInfoUpdate
} from "../store/actions/channel";
import { MembersAdd } from "../store/actions/members";
import { combineLatest, noop, Observable, of } from "rxjs";
import { ChannelFiles } from "../models/channel-files.model";
import { FilesAdd, FilesEdit } from "../store/actions/files";
import { Channel } from "../models/channel.model";
import { ConstantsUtil } from "../../talk/utils/constants.util";
import { Topic } from "../models/topic.model";
import {
  AllTopicsInfoUpdate, SetArchiveTopicsSortBy,ArchivedTopicsInfoUpdate,
  SetSelectedTopicId,
  TopicAdd,
  TopicBulkAdd, TopicChannelAdd,
  TopicCommentsLoadRequest, TopicCommentsLoadSuccess,
  TopicDefaultCoversAdd,
  TopicDelete, TopicFileRemove, TopicFilesAdd,
  TopicFilesLoadRequest,
  TopicFilesLoadSuccess,
  TopicFilesOffsetUpdate,
  TopicInfoUpdate,
  TopicMembersAdd,
  TopicMembersLoadRequest,
  TopicMembersLoadSuccess,
  TopicMembersOffsetUpdate,
  TopicMembersRemove, TopicsDelete, TopicSideBarTabChange,
  TopicUpdate,
} from "../store/actions/topic";

import { TranslateService } from "@ngx-translate/core";
import { getIsConversationListExpanded, getIsRightSideBarExpanded, getIsSideRostersExpanded, TalkRootState } from "app/talk/reducers";
import { SetActiveTab } from "app/actions/app";
import { NavigationEnd, Router } from "@angular/router";
import { Broadcaster } from "../../talk/shared/providers";
import { TopicFiles } from "../models/topic-files.model";
import { CommonUtil } from "../../talk/utils/common.util";
import {
  ChannelRootState,
  getAllChannelsList,
  getAllTopics,
  getChannelById,
  getChannelFiles,
  getChannelMembers,
  getChannelNoAccess,
  getChannels,
  getChannelSearchString,
  getChannelsInfo,
  getChannelUserJid,
  getCommentReplies,
  getCompleteFiles,
  getCompleteMembers,
  getParentCommentInTopicById,
  getPrivateChannelsInfo,
  getPublicChannelsInfo,
  getSearchedChannelsInfo,
  getSelectedChannelId,
  getSubscribedChannelsInfo,
  getTopicById,
  getTopicComments,
  getTopicDefaultCovers,
  getTopicFiles,
  getTopicMembers,
  getTopicSideBarTab,
  getTopicsInfoByChannelId,
  getNotificationInfo,
  getUnreadNotificationInfo,
  getAllNotificationList,
  getUnreadNotifications,
  getChannelSideBarTab,
  getCommentInfoByTopicId,
  getUnreadNotificationCount,
  getAllTopicsInfo,
  getAllSubscribeTopics,
  getAllSubscribeTopicsInfo,
  getDefaultCoverByName,
  getFavoriteChannelsInfo,
  getMediaTopicsInfoByChannelId,
  getFilteredTopicsInfo, getMyChannelsInfo, getArchiveChannelsSortBy, getArchiveTopicsSortBy, getChannelNotFound, getIOMChannelsInfo, getTrashedChannelsInfo, getArchivedChannelsInfo, getArchivedTopicsInfo, getSubChannelsInfo, getSubchannelsByParentId,

  getIntranetChannelsInfo,
  getExtranetChannelsInfo
} from "../store/reducers";
import { CommentAdd, CommentDelete } from "../store/actions/comment";
import { CommentItem, CommentService, EVENTS } from "vnc-library";
import {
  CommentBulkAdd,
  CommentRepliesLoadRequest,
  CommentRepliesLoadSuccess,
  CommentUpdate
} from "../store/actions/comment";
import { SnackbarType } from "../models/snackbar.model";
import { Invitation } from "../models/invitation.model";
import { ChannelSnackbarService } from "../channel-snackbar.service";
import { Subject } from "rxjs";
import {
  NotificationInfoUpdate,
  NotificationBulkAdd,
  UreadNotificationInfoUpdate,
  UnreadNotificationCountUpdate,
  NotificationUpdate,
  NotificationAdd,
  NotificationBulkDelete
} from "../store/actions/notification";
import { Notification, NotificationCenterItem, NotificationCenterItemType } from "../models/notification.model";
import { NotificationStatus } from "../models/notification-status.model";
import { DefaultCover } from "../models/default-cover.model";
import { SmartLinkErrorType } from "../smart-link.service";
import { BehaviorSubject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { UserConfig } from "app/shared/models/user-config.model";
import { getLastPhotoUpdate, getUserConfig, getUserJID } from "app/reducers";
import { SubsribeTopicDelete, SubsribeTopicAdd, SubsribeTopicLoadSuccess, AllSubscribeTopicsInfoUpdate } from "../store/actions/subscribe_topic";
import { TOPIC_FILE_REMOVED, TOPIC_FILES_ADDED } from "../topic-detail/topic-detail.component";
import { Attachment } from "../models/attachment.model";
import { ChannelTopicListingService } from "../channel-detail/channel-topic-listing.service";
import { ConfigService } from "app/config.service";
import { LoggerService } from "app/shared/services/logger.service";
import { SubchannelInfoUpdate } from "../store/actions/subchannel";
import { DocumentPreviewComponent } from "vnc-library";
import { ToastService } from "app/shared/services/toast.service";
import { FilePreviewDialogComponent } from "app/talk/shared/components/dialogs/file-preview-dialog/file-preview-dialog.component";
import { SharingService } from "app/talk/services/sharing.service";
import { NotificationService } from "app/talk/services/notification.service";
import { environment } from "app/environments/environment";

import { ChannelGroupType } from "app/talk/archive-window/archive-window-channels/archive-window-channels.component";
import { TopicGroupType } from "app/talk/archive-window/archive-window-topic/archive-window-topic.component";
import { ConversationRepository } from "app/talk/repositories/conversation.repository";
import { MessageUtil } from "app/talk/utils/message.util";
import { ElectronService } from "app/shared/providers/electron.service";
import { FilesStorageService } from "app/talk/services/files-storage.service";
@Injectable()
export class ChannelRepository {
  userConfig: UserConfig;
  notificationTruncateCharLength = 50;
  toDelete: number;
  userJID: any;

  constructor(private channelRootStore: Store<ChannelRootState>,
    private store: Store<TalkRootState>,
    private channelSnackBarService: ChannelSnackbarService,
    private translate: TranslateService,
    private broadcaster: Broadcaster,
    private router: Router,
    public matDialog: MatDialog,
    private commentService: CommentService,
    private channelService: ChannelService,
    private toastService: ToastService,
    private electronService: ElectronService,
    private _channelTopicListingService: ChannelTopicListingService,
    private logger: LoggerService,
    private sharingService: SharingService,
    private notificationService: NotificationService,
    private configService: ConfigService,
    private conversationRepository: ConversationRepository,
    private filesStorageService: FilesStorageService,
    ) {

    this.store.select(getUserConfig).pipe(filter(res => !!res)).subscribe(userConfig => {
      this.userConfig = userConfig;
    });

    this.store.select(getUserJID).pipe(filter(res => !!res)).subscribe(jid => {
      this.userJID = jid;
    });
  }

  getChannels() {
    return this.channelRootStore.select(getChannels);
  }


  selectChannels(): Observable<Channel[]> {
    return this.channelRootStore.select(getChannels);
  }


  updateChannel(channelId: Channel["id"], updatedChannel): Observable<any> {
    return this.channelService.updateChannel(channelId, updatedChannel)
      .pipe(tap(res => {
        this.updateChannelIntoStore(res.channel);
      }));
  }

  getAllChannelsInfo() {
    return this.channelRootStore.select(getChannelsInfo);
  }

  getPrivateChannelsInfo() {
    return this.channelRootStore.select(getPrivateChannelsInfo);
  }

  getPublicChannelsInfo() {
    return this.channelRootStore.select(getPublicChannelsInfo);
  }

  getIOMChannelsInfo() {
    return this.channelRootStore.select(getIOMChannelsInfo);
  }

  getSubscribedChannelsInfo() {
    return this.channelRootStore.select(getSubscribedChannelsInfo);
  }

  getFavoriteChannelsInfo() {
    return this.channelRootStore.select(getFavoriteChannelsInfo);
  }

  getTrashedChannelsInfo() {
    return this.channelRootStore.select(getTrashedChannelsInfo);
  }

  getArchivedChannelsInfo() {
    return this.channelRootStore.select(getArchivedChannelsInfo);
  }

  getMyChannelsInfo() {
    return this.channelRootStore.select(getMyChannelsInfo);
  }

  getIntranetChannelsInfo() {
    return this.channelRootStore.select(getIntranetChannelsInfo);
  }

  getExtranetChannelsInfo() {
    return this.channelRootStore.select(getExtranetChannelsInfo);
  }

  updateChannelIntoStore(channel: Channel, param1?: any, param2?: any) { /// update channel in store only
    // this.channelRootStore.dispatch(new ChannelUpdate({ ...channel, updated_on: new Date().toISOString(), avatar_url: channel?.avatar_url }));
    this.channelRootStore.dispatch(new ChannelUpdate({ ...channel, updated_on: new Date().toISOString(), avatar_url: channel?.avatar_url, archived: channel?.archived, deleted: channel?.deleted }));
  }
  getArchivedTopicsInfo() {
    return this.channelRootStore.select(getArchivedTopicsInfo);
  }
  channelAvatarFailedToLoad(channelId: string) {
    this.channelRootStore.dispatch(new ChannelAvatarFailedToLoad(channelId));
  }

  channelAvatarFailedToLoadRemove(channelId: string) {
    this.channelRootStore.dispatch(new ChannelAvatarFailedToLoadRemove(channelId));
  }

  getTopicsInfoByChannelId(channelId: string) {
    return this.channelRootStore.select(state => getTopicsInfoByChannelId(state, channelId));
  }

  getCommentInfoByTopicId(topicId: string) {
    return this.channelRootStore.select(state => getCommentInfoByTopicId(state, topicId));
  }

  addChannelIntoStore(channel: Channel) { // add channel in store only
    if (channel?.parent) {
      if (channel.parent?.sub_channels_count) {
        channel.parent.sub_channels_count += 1;
        this.channelRootStore.dispatch(new ChannelAdd(channel));
      } else {
        channel.parent.sub_channels_count = 1;
        this.channelRootStore.dispatch(new ChannelAdd(channel));
      }
    }
    else {

      this.channelRootStore.dispatch(new ChannelAdd(channel));
    }
  }

  removeChannelIntoStore(channelId: string) {
    this.channelRootStore.dispatch(new ChannelDelete(channelId));
  }

  removeAllTopicsIntoStore(channelId: string) {
    this.getTopics(channelId).pipe(take(1)).subscribe(topics => {
      if (topics && topics.length > 0) {
        let topicsIds = topics.map(t => t.id);
        this.store.dispatch(new TopicsDelete(topicsIds));
      }
    });
  }

  createChannel(channel: Partial<Channel>, channelMembers: { jid: string, role: string }[]): Observable<any> {
    return this.channelService.createChannel(channel, { ...channelMembers })
      .pipe(tap(res => {
        this.addChannelIntoStore(res.channel);
      }));
  }

  createSubchannel(channel: Partial<Channel & { parent_id: string }>, channelMembers: { jid: string, role: string }[], parent_id?: string): Observable<any> {
    return this.channelService.createSubchannel(channel, { ...channelMembers })
      .pipe(tap(res => {
        if (parent_id) {
          this.router.navigateByUrl(`/talk/channels/${res?.channel?.id}`);
          this.getChannelById(parent_id).pipe(take(1)).subscribe(parent => {
            this.addChannelIntoStore({ ...res.channel, parent });
            this.updateChannel(parent_id, { sub_channels_count: parent?.sub_channels_count + 1 });
            this.channelRootStore.select(getSubChannelsInfo).pipe(take(1)).subscribe(info => {
              info.parent[parent_id].subchannels = [...info.parent[parent_id]?.subchannels, res?.channel?.id];
              info.totalCount = info.totalCount + 1;
              this.channelRootStore.dispatch(new SubchannelInfoUpdate(info));
            });
          });
        } else {
          this.addChannelIntoStore(res?.channel);
          this.channelRootStore.dispatch(new SetSelectedChannelId(res?.channel?.id));
        }
      }));
  }
  updateAttachmentsURLs(attachments: Attachment[], is_iom: boolean = false): Attachment[] {
    if (is_iom) {
      const updatedAttachments = attachments?.map(attachment => {
        let content_url = attachment?.content_url;
        let thumbnail_url = attachment?.thumbnail_url;
        return { ...attachment, content_url, thumbnail_url };
      });
      return updatedAttachments;
    } else {
      const updatedAttachments = attachments?.map(attachment => {
        let content_url = CommonUtil.getAttachmentLocalAPIURL(attachment?.content_url);
        let thumbnail_url = CommonUtil.getAttachmentLocalAPIURL(attachment?.thumbnail_url);
        return { ...attachment, content_url, thumbnail_url };
      });
      return updatedAttachments;
    }
  }
  processTopics(oldTopics: any[], newTopics: any[]): any[] {
    const filterAndConcat = (oldTopics: Topic[], newTopics: Topic[]) => {
      const resultantTopics = [...oldTopics];
      newTopics?.forEach(newTopic => {
        const index = oldTopics?.findIndex(oldTopic => oldTopic?.id === newTopic?.id);
        if (index === -1) {
          resultantTopics.push(newTopic);
        }
      });
      return resultantTopics;
    };
    const resultantTopics = filterAndConcat(oldTopics, newTopics);

    return resultantTopics.map(topic => {
      let headerAttachments = [];
      if (topic) {
          topic.attachments = topic.attachments || [];
      }
      if (topic?.topic_type === "video") {
        if (topic?.external_video_url) {
          const thumbnail = topic?.attachments.find(attachment => attachment?.is_default_thumbnail === true);
          if (!!thumbnail) {
            headerAttachments = [{ ...thumbnail }];
          }
        } else {
          const video = topic?.attachments.find(attachment => attachment?.is_video === true);
          let defaultThumbnail = topic?.attachments?.find(attachment => attachment?.is_default_thumbnail === true);
          if (!!video) {
            let thumbnail_url = video?.thumbnail_url;
            if (video && defaultThumbnail) {
              thumbnail_url = defaultThumbnail?.thumbnail_url;
            }
            headerAttachments = [{ ...video, thumbnail_url }];
          }
        }
      } else {
        headerAttachments = topic?.attachments.filter(attachment => attachment?.is_header === true);
      }
      const heroAttachments = this.updateAttachmentsURLs(headerAttachments, topic?.is_iom);
      return { ...topic, heroAttachments };
    });
  }

  deleteChannel(channelId, moveToTrash = false, route = true) {
    return this.channelService.deleteChannel(channelId, moveToTrash)
      .pipe(tap(() => {
        this.getSelectedChannelId()
          .pipe(take(1))
          .subscribe(id => {
            if ((id !== null && id) || channelId) {
              this.setActiveTabChannel();
              this.getChannelById(id ?? channelId).pipe(take(1)).subscribe(resp => {
                if (resp?.sub_channels_count) {
                  resp.sub_channels_count = resp?.sub_channels_count - 1;
                  this.updateChannelIntoStore(resp);
                  if (!resp?.parent) {
                    this.channelRootStore.dispatch(new SetSelectedChannelId(null));
                  }
                }
              });
             if(!moveToTrash) this.removeChannelIntoStore(channelId);
            }
          });
        this.translate.get(moveToTrash ? "CHANNEL_MOVED_TO_TRASH" : "CHANNEL_DELETED").pipe(take(1)).subscribe(text => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
        });
        if (!!route) {
          of(null).pipe(delay(1)).subscribe(() => {
            this.router.navigateByUrl(`/talk/trash`).then().catch(() => {
            });
          });
        }
      }, err => {
        this.logger.info("[deleteChannel] err", err);
        if (err.error) {
          this.channelSnackBarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
        }
      }));
  }

  restoreChannel(channelId: string) {
    return this.channelService.restoreChannel(channelId)
      .pipe(tap(res => {
        this.logger.error(res);
        // this.addChannelIntoStore(res?.channel);
        if (res?.channel?.parent) {
          this.getChannelById(res?.channel?.parent?.id).pipe(take(1)).subscribe(respParent => {
            let subCount = respParent.sub_channels_count ? respParent.sub_channels_count : 0;
            respParent["sub_channels_count"] = subCount + 1;
            this.removeChannelIntoStore(respParent?.id);
            this.addChannelIntoStore(respParent);
          });
        }
        this.removeChannelIntoStore(res?.channel?.id);
        this.addChannelIntoStore(res?.channel);
        setTimeout(() => {
          this.setChannelCategory(res?.channel);
        }, 1000);
      }));
  }


  subscribeChannel(channelId: string, iom: boolean = false): Observable<any> {
    const membersForChannelMembers: { jid: string, role: string }[] = [];
    this.getChannelUserJid()
      .pipe(take(1))
      .subscribe(jid => {
        membersForChannelMembers.push(
          {
            jid: jid,
            role: "participant"
          });
      });
    return this.channelService.subscribeChannel(channelId, iom)
      .pipe(tap(res => {
        this.changeChannelCategory(res?.channel, iom ? "iom" : "subscribed");
        this.updateChannelIntoStore(res?.channel);
        this.channelRootStore.dispatch(new ChannelMembersAdd({ channelId, members: membersForChannelMembers }));
        this.translate.get("SUBSCRIBED_TO_THE_CHANNEL").pipe(take(1)).subscribe(text => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
        });
        if (iom) {
          this.router.navigateByUrl("/talk/channels/" + channelId);
        }
      }));
  }

  unsubscribeChannel(channelId: string, newUser?: any): Observable<any> {
    const memberIds: string[] = [];
    this.getChannelUserJid()
      .pipe(take(1))
      .subscribe(jid => {
        memberIds.push(jid);
      });
    return this.channelService.unsubscribeChannel(channelId, newUser)
      .pipe(tap(res => {
        this.changeChannelCategory(res?.channel, "subscribed");
        this.updateChannelIntoStore(res?.channel);
        this.channelRootStore.dispatch(new ChannelMembersRemove({ channelId, memberIds }));
        this.translate.get("UNSUBSCRIBED_FROM_THE_CHANNEL").pipe(take(1)).subscribe(text => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
        });
      },
        (err) => {
          if (err.error?.error?.localeCompare("Unprocessable Entity") === 0) {
            this.translate.get("CANT_UNSUBSCRIBE_FROM_THE_CHANNEL").pipe(take(1)).subscribe(text => {
              this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
            });
          }
          else {
            this.channelSnackBarService.openSnackBar(err.error.errors[0]);
          }
        }));
  }


  getSelectedChannelId(): Observable<string> {
    return this.channelRootStore.select(getSelectedChannelId);
  }

  getArchiveChannelsSortBy(): Observable<string> {
    return this.channelRootStore.select(getArchiveChannelsSortBy);
  }
  getArchiveTopicsSortBy(): Observable<string> {
    return this.channelRootStore.select(getArchiveTopicsSortBy);
  }


  getSelectedChannel(): Observable<Channel | undefined> {
    return this.getSelectedChannelId().pipe(
      switchMap((selectedChannelId: Channel["id"]) => {
        return this.channelRootStore?.select(state => getChannels(state)).pipe(
          map((channels: Channel[]) => channels?.find((channel: Channel) => channel?.id === selectedChannelId)),
          switchMap((channel: Channel | undefined) => {
            if (!channel) {
              return this.getSelectedChannelByApi();
            }
            return new Observable<Channel | undefined>(observer => observer?.next(channel));
          })
        );
      })
    );
  }

  getSelectedChannelByApi(): Observable<Channel | undefined> {
    return this.getSelectedChannelId().pipe(
      switchMap((selectedChannelId: Channel["id"]) => {
        return this.channelService?.getChannelById(selectedChannelId)?.pipe(
          map((data: any) => {
            if(!data) {
              return;
            }
            this.addChannelIntoStore(data?.channel);
            return data?.channel; // Modify this if necessary based on the actual structure of the data
          })
        );
      })
    );
  }

  setActiveTabChannel() {
    const tab = "channel";
    this.store.dispatch(new SetActiveTab(tab));
  }

  setActiveTabChat() {
    const tab = "chat";
    this.store.dispatch(new SetActiveTab(tab));
  }

  getIsLeftSideBarExpanded() {
    return this.store.select(getIsConversationListExpanded);
  }


  setSelectedChannelId(channelId) {
    if(!channelId){
      return;
    }
    this.channelRootStore.dispatch(new SetSelectedChannelId(channelId));
  }

  setChannelIdNull() {
    this.channelRootStore.dispatch(new SetSelectedChannelId(null));
  }

  setArchiveChannelsSortBy(sortBy) {
    this.channelRootStore.dispatch(new SetArchiveChannelsSortBy(sortBy));
  }

  setArchiveTopicsSortBy(sortBy) {
    this.channelRootStore.dispatch(new SetArchiveTopicsSortBy(sortBy));
  }
  extractIdsFromChannels(channels: Channel[], previousIds?: string[]) {
    let ids = [];
    if (previousIds && previousIds.length > 0) {
      channels.forEach(channel => !previousIds.includes(channel?.id) && ids.push(channel?.id));
    } else {
      channels.forEach(channel => ids.push(channel?.id));
    }
    return ids;
  }

  buildDataObject(totalCount: number, offset: number, ids: string[], isLoading: boolean, isLoaded: boolean) {
    return {
      totalCount,
      offset,
      ids,
      isLoading,
      isLoaded
    };
  }

  loadArchivedTopics(sort?: TopicGroupType) {
    const data = this.buildDataObject(0, 0, [], true, false);
    this.channelRootStore.dispatch(new ArchivedTopicsInfoUpdate(data));
    this.channelService
      .getArchivedTopics({
        offset: 0,
        limit: 0,
        q: "",
        sort
      })
      .subscribe((res) => {
        const topics: Topic[] = (res.topics as Topic[]).map((t) => {
          return { ...t, id: t.is_iom ? "iom-" + t.id : t.id };
        });
        const data = {
          totalCount: res.total_count,
          offset: res.topics.length,
          ids: this.extractIdsFromTopics(topics),
          isLoading: false,
          isLoaded: true
        };
        this.channelRootStore.dispatch(new TopicBulkAdd(topics));
        this.channelRootStore.dispatch(new ArchivedTopicsInfoUpdate(data));
      });
  }

  loadMoreArchivedTopics(offset: number, limit?: number, sort: TopicGroupType = TopicGroupType.DATE_ASC) {
    let totalCount = 0;
    let previousIds = [];
    let previousOffset = 0;
    this.channelRootStore.select(getArchivedTopicsInfo)
      .subscribe(info => {
        totalCount = info.totalCount;
        previousIds = info.ids;
        previousOffset = info.offset;
      });

    if (totalCount > offset) {
      const data = this.buildDataObject(totalCount, previousOffset, previousIds, true, false);
      this.channelRootStore.dispatch(new ArchivedTopicsInfoUpdate(data));
      this.channelService.getArchivedTopics({offset, limit, q: "", sort})
        .subscribe(res => {
          const topics: Topic[] = (res.topics as Topic[]).map((t) => {
            return { ...t, id: t.is_iom ? "iom-" + t.id : t.id };
          });
          const data = {
            totalCount: res.total_count,
            offset: offset + res.topics.length,
            ids: [...previousIds, ...this.extractIdsFromTopics(topics, previousIds)],
            isLoading: false,
            isLoaded: true
          };
          this.channelRootStore.dispatch(new TopicBulkAdd(topics));
          this.channelRootStore.dispatch(new ArchivedTopicsInfoUpdate(data));
        });
    }
  }

  selectArchivedTopics(): Observable<Topic[]> {
    let archivedTopics = [];
    const $archivedTopicsInfoOb = this.channelRootStore.select(getArchivedTopicsInfo);
    const $allAvailableTopicsOb = this.channelRootStore.select(getAllTopics);
    return combineLatest([$archivedTopicsInfoOb, $allAvailableTopicsOb])
      .pipe(map((value: [{totalCount: number, offset: number, ids: string[]}, Topic[]]) => {
        const ids = value?.[0]?.ids;
        const allAvailableTopics = value?.[1];
        archivedTopics = allAvailableTopics.filter(v => ids.includes(v.id));
        return archivedTopics;
      }));
  }
  loadTrashedChannels(sort?: ChannelGroupType, q:string = "") {
    const data = this.buildDataObject(0, 0, [], true, false);
    this.channelRootStore.dispatch(new TrashedChannelInfoUpdate(data));
    this.channelService
      .getTrashedChannels({
        offset: 0,
        limit: 0,
        q: q,
        sort
      })
      .subscribe((res) => {
        const channels: Channel[] = res.channels;
        const data = {
          totalCount: res.total_count,
          offset: res.channels.length,
          ids: this.extractIdsFromChannels(channels),
          isLoading: false,
          isLoaded: true
        };
        this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
        this.channelRootStore.dispatch(new TrashedChannelInfoUpdate(data));
      });
  }

  loadArchivedChannels(sort?: ChannelGroupType) {
    const data = this.buildDataObject(0, 0, [], true, false);
    this.channelRootStore.dispatch(new ArchivedChannelInfoUpdate(data));
    this.channelService
      .getArchivedChannels({
        offset: 0,
        limit: 0,
        q: "",
        sort
      })
      .subscribe((res) => {
        const channels: Channel[] = res.channels;
        const data = {
          totalCount: res.total_count,
          offset: res.channels.length,
          ids: this.extractIdsFromChannels(channels),
          isLoading: false,
          isLoaded: true
        };
        this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
        this.channelRootStore.dispatch(new ArchivedChannelInfoUpdate(data));
      });
  }

  loadAllChannels() {
    const data = this.buildDataObject(0, 0, [], true, false);
    this.channelRootStore.dispatch(new ChannelInfoUpdate(data));
    this.channelService.getChannels(0, null)
      .subscribe(res => {
        const channels: Channel[] = res.channels;
        const data = {
          totalCount: res.total_count,
          offset: res.channels.length,
          ids: this.extractIdsFromChannels(channels),
          isLoading: false,
          isLoaded: true
        };
        this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
        this.channelRootStore.dispatch(new ChannelInfoUpdate(data));
      });
  }

  getSubchannelsByChannel(parentChannel: Channel): Observable<Channel[]> {
    return this.channelRootStore.select(getSubChannelsInfo).pipe(
      take(1),
      switchMap(info => {
        let totalCount = info?.totalCount || 0;
        let previousParents: { [key: string]: { subchannels: string[], allLoaded: boolean } | null } = info?.parent || {};

        return this.channelRootStore.select(state => getSubchannelsByParentId(state, parentChannel?.id)).pipe(
          take(1),
          switchMap((res: Channel[]) => {
            if (!res || res.length === 0 || !previousParents[parentChannel?.id]?.allLoaded) {
              return this.channelService.getSubchannelsByUID(parentChannel?.uid).pipe(
                take(1),
                switchMap((response: any) => {
                  if (response && !response.error) {
                    const subChannels: Channel[] | null = response.channels?.map((c: any) => ({ ...c, parent: parentChannel }));
                    previousParents[parentChannel?.id] = {
                      subchannels: [...(previousParents[parentChannel?.id]?.subchannels || []), ...(subChannels?.map(s => s?.id) || [])],
                      allLoaded: true
                    };
                    const data = {
                      totalCount: response.total_count + totalCount,
                      parent: previousParents,
                      isLoading: false,
                      isLoaded: true
                    };
                    this.channelRootStore.dispatch(new SubChannelInfoUpdate(data));
                    this.channelRootStore.dispatch(new SubChannelBulkAdd(subChannels || []));
                    return of(subChannels || []);
                  } else {
                    return of([]); // Return empty array or handle error case
                  }
                })
              );
            } else {
              return of(res);
            }
          })
        );
      })
    );
  }
  loadAllTopics() {
    const data = this.buildDataObject(0, 0, [], true, false);
    this.channelRootStore.dispatch(new AllTopicsInfoUpdate(data));
    this.channelService.getAllTopics({ offset: 0, limit: 25 }).subscribe(res => {
      if (res && !res.error) {
        const topics: Topic[] = (res.topics as Topic[]).map((t) => {
          return { ...t, id: t.is_iom ? "iom-" + t.id : t.id };
        });
        const data = {
          totalCount: res.total_count,
          offset: res.topics.length,
          ids: this.extractIdsFromTopics(topics),
          isLoading: false,
          isLoaded: true
        };
        this.channelRootStore.dispatch(new TopicBulkAdd(topics));
        this.channelRootStore.dispatch(new AllTopicsInfoUpdate(data));
      }
      else {
        const data = this.buildDataObject(0, 0, [], false, true);
        this.channelRootStore.dispatch(new AllTopicsInfoUpdate(data));
      }
    });
  }

  setAllTopics(): Observable<Topic[]> {
    let allTopics = [];
    const allTopicsInfoOb$ = this.channelRootStore.select(getAllTopicsInfo);
    const allAvailableTopicsOb$ = this.channelRootStore.select(getAllTopics);
    return combineLatest([allTopicsInfoOb$, allAvailableTopicsOb$])
      .pipe(map((value: [{ totalCount: number, offset: number, ids: string[] }, Topic[]]) => {
        const ids = value?.[0]?.ids;
        const allAvailableTopics = value?.[1];
        allTopics = allAvailableTopics.filter(v => ids.includes(v.id));
        return allTopics;
      }));
  }

  extractIdsFromTopics(topics: Topic[], previousIds?: string[]) {
    let ids = [];
    if (previousIds && previousIds.length > 0) {
      topics.forEach(topic => !previousIds.includes(topic.id) && ids.push(topic.id));
    } else {
      topics.forEach(topic => ids.push(topic.id));
    }
    return ids;
  }

  loadPrivateChannels() {
    const data = this.buildDataObject(0, 0, [], true, false);
    this.channelRootStore.dispatch(new PrivateChannelInfoUpdate(data));
    this.channelService.getChannels(0, null, false, true)
      .subscribe(res => {
        const channels: Channel[] = res.channels;
        const data = {
          totalCount: res.total_count,
          offset: res.channels.length,
          ids: this.extractIdsFromChannels(channels),
          isLoading: false,
          isLoaded: true
        };
        this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
        this.channelRootStore.dispatch(new PrivateChannelInfoUpdate(data));
      });
  }

  loadPublicChannels() {
    const data = this.buildDataObject(0, 0, [], true, false);
    this.channelRootStore.dispatch(new PublicChannelInfoUpdate(data));
    this.channelService.getChannels(0, null, true, false)
      .subscribe(res => {
        const channels: Channel[] = res.channels;
        const data = {
          totalCount: res.total_count,
          offset: res.channels.length,
          ids: this.extractIdsFromChannels(channels),
          isLoading: false,
          isLoaded: true
        };
        this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
        this.channelRootStore.dispatch(new PublicChannelInfoUpdate(data));
      });
  }

  loadIOMChannels() {
    const data = {
      totalCount: 0,
      offset: 0,
      ids: [],
      subscribed: [],
      isLoading: true,
      isLoaded: false
    };
    this.channelRootStore.dispatch(new IOMChannelInfoUpdate(data));
    this.channelService.getChannels(0, null, false, false, true, false, false, false, null, true)
      .subscribe(res => {
        const channels: Channel[] = res.channels;
        const data = {
          totalCount: res.total_count,
          offset: res.channels.length,
          ids: this.extractIdsFromChannels(channels),
          subscribed: this.extractIdsFromChannels(channels.filter(c => c.subscribed)),
          isLoading: false,
          isLoaded: true
        };
        this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
        this.channelRootStore.dispatch(new IOMChannelInfoUpdate(data));
      });
  }

  loadSubscribedChannels() {
    const data = this.buildDataObject(0, 0, [], true, false);
    this.channelRootStore.dispatch(new SubscribedChannelInfoUpdate(data));
    this.channelService.getChannels(0, null, false, false, false, true)
      .subscribe(res => {
        const channels: Channel[] = res.channels;
        const data = {
          totalCount: res.total_count,
          offset: res.channels.length,
          ids: this.extractIdsFromChannels(channels),
          isLoading: false,
          isLoaded: true
        };
        this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
        this.channelRootStore.dispatch(new SubscribedChannelInfoUpdate(data));
      });
  }

  loadFavoriteChannels() {
    const data = this.buildDataObject(0, 0, [], true, false);
    this.channelRootStore.dispatch(new FavoriteChannelInfoUpdate(data));
    this.channelService.getChannels(0, null, false, false, false, false, true)
      .subscribe(res => {
        const channels: Channel[] = res.channels;
        const data = {
          totalCount: res.total_count,
          offset: res.channels.length,
          ids: this.extractIdsFromChannels(channels),
          isLoading: false,
          isLoaded: true
        };
        this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
        this.channelRootStore.dispatch(new FavoriteChannelInfoUpdate(data));
      });
  }

  loadIntranetChannels() {
    const data = this.buildDataObject(0, 0, [], true, false);
    this.channelRootStore.dispatch(new IntranetChannelsInfoUpdate(data));
    this.channelService.getChannels(0, null, false, false, false, false, false, true)
      .subscribe(res => {
        const channels: Channel[] = res.channels;
        const data = {
          totalCount: res.total_count,
          offset: res.channels.length,
          ids: this.extractIdsFromChannels(channels),
          isLoading: false,
          isLoaded: true
        };
        this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
        this.channelRootStore.dispatch(new IntranetChannelsInfoUpdate(data));
      });
  }

  loadExtranetChannels() {
    const data = this.buildDataObject(0, 0, [], true, false);
    this.channelRootStore.dispatch(new ExtranetChannelsInfoUpdate(data));
    this.channelService.getChannels(0, null, false, false, false, false, false, false, true)
      .subscribe(res => {
        const channels: Channel[] = res.channels;
        const data = {
          totalCount: res.total_count,
          offset: res.channels.length,
          ids: this.extractIdsFromChannels(channels),
          isLoading: false,
          isLoaded: true
        };
        this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
        this.channelRootStore.dispatch(new ExtranetChannelsInfoUpdate(data));
      });
  }

  loadMyChannels() {
    const data = this.buildDataObject(0, 0, [], true, false);
    this.channelRootStore.dispatch(new MyChannelInfoUpdate(data));
    this.channelService.getChannels(0, null, false, false, false, false, false, true, this.userConfig.id)
      .subscribe(res => {
        const channels: Channel[] = res.channels;
        const data = {
          totalCount: res.total_count,
          offset: res.channels.length,
          ids: this.extractIdsFromChannels(channels),
          isLoading: false,
          isLoaded: true
        };
        this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
        this.channelRootStore.dispatch(new MyChannelInfoUpdate(data));
      });
  }


  loadMoreChannels(offset: number, limit?: number) {
    let totalCount = 0;
    let previousIds = [];
    let previousOffset = 0;
    this.channelRootStore.select(getChannelsInfo).pipe(take(1))
      .subscribe(info => {
        totalCount = info.totalCount;
        previousIds = info.ids;
        previousOffset = info.offset;
      });

    if (totalCount > offset) {
      const data = this.buildDataObject(totalCount, previousOffset, previousIds, true, false);
      this.channelRootStore.dispatch(new ChannelInfoUpdate(data));
      this.channelService.getChannels(offset, limit)
        .subscribe(res => {
          const channels: Channel[] = res.channels;
          const data = {
            totalCount: res.total_count,
            offset: offset + res.channels.length,
            ids: [...previousIds, ...this.extractIdsFromChannels(channels, previousIds)],
            isLoading: false,
            isLoaded: true
          };
          this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
          this.channelRootStore.dispatch(new ChannelInfoUpdate(data));
        });
    }
  }

  selectAllChannels(): Observable<Channel[]> {
    let allChannels = [];
    const $allChannelsInfoOb = this.channelRootStore.select(getChannelsInfo);
    const $allAvailableChannelsOb = this.channelRootStore.select(getAllChannelsList);
    return combineLatest([$allChannelsInfoOb, $allAvailableChannelsOb])
      .pipe(map((value: [{ totalCount: number, offset: number, ids: string[] }, Channel[]]) => {
        const ids = value?.[0]?.ids;
        const allAvailableChannels = value?.[1];
        allChannels = allAvailableChannels.filter(v => ids.includes(v.id));
        return allChannels;
      }));
  }

  getLoadingLoadedForAllChannels(): Observable<{ loading: boolean, loaded: boolean }> {
    return this.channelRootStore.select(getChannelsInfo).pipe(map(info => {
      return {
        loading: info.isLoading,
        loaded: info.isLoaded
      };
    }));
  }


  loadMorePrivateChannels(offset: number, limit?: number) {
    let totalCount = 0;
    let previousIds = [];
    let previousOffset = 0;
    this.channelRootStore.select(getPrivateChannelsInfo).pipe(take(1))
      .subscribe(info => {
        totalCount = info.totalCount;
        previousIds = info.ids;
        previousOffset = info.offset;
      });

    if (totalCount > offset) {
      const data = this.buildDataObject(totalCount, previousOffset, previousIds, true, false);
      this.channelRootStore.dispatch(new PrivateChannelInfoUpdate(data));
      this.channelService.getChannels(offset, limit, false, true)
        .subscribe(res => {
          const channels: Channel[] = res.channels;
          const data = {
            totalCount: res.total_count,
            offset: offset + res.channels.length,
            ids: [...previousIds, ...this.extractIdsFromChannels(channels, previousIds)],
            isLoading: false,
            isLoaded: true
          };
          this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
          this.channelRootStore.dispatch(new PrivateChannelInfoUpdate(data));
        });
    }
  }

  selectPrivateChannels(): Observable<Channel[]> {
    let privateChannels = [];
    const $privateChannelsInfoOb = this.channelRootStore.select(getPrivateChannelsInfo);
    const $allAvailableChannelsOb = this.channelRootStore.select(getAllChannelsList);
    return combineLatest([$privateChannelsInfoOb, $allAvailableChannelsOb])
      .pipe(map((value: [{ totalCount: number, offset: number, ids: string[] }, Channel[]]) => {
        const ids = value?.[0]?.ids;
        const allAvailableChannels = value?.[1];
        privateChannels = allAvailableChannels.filter(v => ids.includes(v.id));
        return privateChannels;
      }));
  }

  getLoadingLoadedForPrivateChannels(): Observable<{ loading: boolean, loaded: boolean }> {
    return this.channelRootStore.select(getPrivateChannelsInfo).pipe(map(info => {
      return {
        loading: info.isLoading,
        loaded: info.isLoaded
      };
    }));
  }

  getLoadingLoadedForIOMChannels(): Observable<{ loading: boolean, loaded: boolean }> {
    return this.channelRootStore.select(getIOMChannelsInfo).pipe(map(info => {
      return {
        loading: info.isLoading,
        loaded: info.isLoaded
      };
    }));
  }



  loadMorePublicChannels(offset: number, limit?: number) {
    let totalCount = 0;
    let previousIds = [];
    let previousOffset = 0;
    this.channelRootStore.select(getPublicChannelsInfo).pipe(take(1))
      .subscribe(info => {
        totalCount = info.totalCount;
        previousIds = info.ids;
        previousOffset = info.offset;
      });

    if (totalCount > offset) {
      const data = this.buildDataObject(totalCount, previousOffset, previousIds, true, false);
      this.channelRootStore.dispatch(new PublicChannelInfoUpdate(data));
      this.channelService.getChannels(offset, limit, true, false)
        .subscribe(res => {
          const channels: Channel[] = res.channels;
          const data = {
            totalCount: res.total_count,
            offset: offset + res.channels.length,
            ids: [...previousIds, ...this.extractIdsFromChannels(channels, previousIds)],
            isLoading: false,
            isLoaded: true
          };
          this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
          this.channelRootStore.dispatch(new PublicChannelInfoUpdate(data));
        });
    }
  }

  loadMoreIOMChannels(offset: number, limit?: number) {
    let totalCount = 0;
    let previousIds = [];
    let previousSubscribed = [];
    let previousOffset = 0;
    this.channelRootStore.select(getIOMChannelsInfo).pipe(take(1))
      .subscribe(info => {
        totalCount = info.totalCount;
        previousIds = info.ids;
        previousSubscribed = info.subscribed;
        previousOffset = info.offset;
      });

    if (totalCount > offset) {
      const data = {
        totalCount: totalCount,
        offset: previousOffset,
        ids: previousIds,
        subscribed: previousSubscribed,
        isLoading: true,
        isLoaded: false
      };
      this.channelRootStore.dispatch(new IOMChannelInfoUpdate(data));
      this.channelService.getChannels(offset, limit, false, false, true, false, false, false, null, true)
        .subscribe(res => {
          const channels: Channel[] = res.channels;
          const data = {
            totalCount: res.total_count,
            offset: offset + res.channels.length,
            ids: [...previousIds, ...this.extractIdsFromChannels(channels, previousIds)],
            subscribed: [...previousSubscribed, ...this.extractIdsFromChannels(channels.filter(c => c.subscribed), previousSubscribed)],
            isLoading: false,
            isLoaded: true
          };
          this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
          this.channelRootStore.dispatch(new IOMChannelInfoUpdate(data));
        });
    }
  }

  selectPublicChannels(): Observable<Channel[]> {
    let publicChannels = [];
    const $publicChannelsInfoOb = this.channelRootStore.select(getPublicChannelsInfo);
    const $allAvailableChannelsOb = this.channelRootStore.select(getAllChannelsList);
    return combineLatest([$publicChannelsInfoOb, $allAvailableChannelsOb])
      .pipe(map((value: [{ totalCount: number, offset: number, ids: string[] }, Channel[]]) => {
        const ids = value?.[0]?.ids;
        const allAvailableChannels = value?.[1];
        publicChannels = allAvailableChannels.filter(v => ids.includes(v.id));
        return publicChannels;
      }));
  }

  selectIOMChannels(): Observable<Channel[]> {
    let iomChannels = [];
    const $iomChannelsInfoOb = this.channelRootStore.select(getIOMChannelsInfo);
    const $allAvailableChannelsOb = this.channelRootStore.select(getAllChannelsList);
    return combineLatest([$iomChannelsInfoOb, $allAvailableChannelsOb])
      .pipe(map((value: [{ totalCount: number, offset: number, ids: string[] }, Channel[]]) => {
        const ids = value?.[0]?.ids;
        const allAvailableChannels = value?.[1];
        iomChannels = allAvailableChannels.filter(v => ids.includes(v.id));
        return iomChannels;
      }));
  }

  selectSubscribedIOMChannels(): Observable<Channel[]> {
    let iomChannels = [];
    const $iomChannelsInfoOb = this.channelRootStore.select(getIOMChannelsInfo);
    const $allAvailableChannelsOb = this.channelRootStore.select(getAllChannelsList);
    return combineLatest([$iomChannelsInfoOb, $allAvailableChannelsOb])
      .pipe(map((value: [{ totalCount: number, offset: number, ids: string[], subscribed: string[] }, Channel[]]) => {
        const ids = value?.[0]?.subscribed;
        const allAvailableChannels = value?.[1];
        iomChannels = allAvailableChannels.filter(v => ids.includes(v.id));
        return iomChannels;
      }));
  }

  getLoadingLoadedForPublicChannels(): Observable<{ loading: boolean, loaded: boolean }> {
    return this.channelRootStore.select(getPublicChannelsInfo).pipe(map(info => {
      return {
        loading: info.isLoading,
        loaded: info.isLoaded
      };
    }));
  }

  loadMoreSubscribedChannels(offset: number, limit?: number) {
    let totalCount = 0;
    let previousIds = [];
    let previousOffset = 0;
    this.channelRootStore.select(getSubscribedChannelsInfo)
      .subscribe(info => {
        totalCount = info.totalCount;
        previousIds = info.ids;
        previousOffset = info.offset;
      });

    if (totalCount > offset) {
      const data = this.buildDataObject(totalCount, previousOffset, previousIds, true, false);
      this.channelRootStore.dispatch(new SubscribedChannelInfoUpdate(data));
      this.channelService.getChannels(offset, limit, false, false, false, true)
        .subscribe(res => {
          const channels: Channel[] = res.channels;
          const data = {
            totalCount: res.total_count,
            offset: offset + res.channels.length,
            ids: [...previousIds, ...this.extractIdsFromChannels(channels, previousIds)],
            isLoading: false,
            isLoaded: true
          };
          this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
          this.channelRootStore.dispatch(new SubscribedChannelInfoUpdate(data));
        });
    }
  }

  loadMoreFavoriteChannels(offset: number, limit?: number) {
    let totalCount = 0;
    let previousIds = [];
    let previousOffset = 0;
    this.channelRootStore.select(getFavoriteChannelsInfo).pipe(take(1))
      .subscribe(info => {
        totalCount = info.totalCount;
        previousIds = info.ids;
        previousOffset = info.offset;
      });

    if (totalCount > offset) {
      const data = this.buildDataObject(totalCount, previousOffset, previousIds, true, false);
      this.channelRootStore.dispatch(new FavoriteChannelInfoUpdate(data));
      this.channelService.getChannels(offset, limit, false, false, false, false, true)
        .subscribe(res => {
          const channels: Channel[] = res.channels;
          const data = {
            totalCount: res.total_count,
            offset: offset + res.channels.length,
            ids: [...previousIds, ...this.extractIdsFromChannels(channels, previousIds)],
            isLoading: false,
            isLoaded: true
          };
          this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
          this.channelRootStore.dispatch(new FavoriteChannelInfoUpdate(data));
        });
    }
  }

  loadMoreMyChannels(offset: number, limit?: number) {
    let totalCount = 0;
    let previousIds = [];
    let previousOffset = 0;
    this.channelRootStore.select(getMyChannelsInfo).pipe(take(1))
      .subscribe(info => {
        totalCount = info.totalCount;
        previousIds = info.ids;
        previousOffset = info.offset;
      });

    if (totalCount > offset) {
      const data = this.buildDataObject(totalCount, previousOffset, previousIds, true, false);
      this.channelRootStore.dispatch(new MyChannelInfoUpdate(data));
      this.channelService.getChannels(offset, limit, false, false, false, false, false, true, this.userConfig.id)
        .subscribe(res => {
          const channels: Channel[] = res.channels;
          const data = {
            totalCount: res.total_count,
            offset: offset + res.channels.length,
            ids: [...previousIds, ...this.extractIdsFromChannels(channels, previousIds)],
            isLoading: false,
            isLoaded: true
          };
          this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
          this.channelRootStore.dispatch(new MyChannelInfoUpdate(data));
        });
    }
  }

  loadMoreIntranetChannels(offset: number, limit?: number) {
    let totalCount = 0;
    let previousIds = [];
    let previousOffset = 0;
    this.channelRootStore.select(getIntranetChannelsInfo).pipe(take(1))
      .subscribe(info => {
        totalCount = info.totalCount;
        previousIds = info.ids;
        previousOffset = info.offset;
      });

    if (totalCount > offset) {
      const data = this.buildDataObject(totalCount, previousOffset, previousIds, true, false);
      this.channelRootStore.dispatch(new IntranetChannelsInfoUpdate(data));
      this.channelService.getChannels(offset, limit, false, false, false, false, true)
        .subscribe(res => {
          const channels: Channel[] = res.channels;
          const data = {
            totalCount: res.total_count,
            offset: offset + res.channels.length,
            ids: [...previousIds, ...this.extractIdsFromChannels(channels, previousIds)],
            isLoading: false,
            isLoaded: true
          };
          this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
          this.channelRootStore.dispatch(new IntranetChannelsInfoUpdate(data));
        });
    }
  }

  loadMoreExtranetChannels(offset: number, limit?: number) {
    let totalCount = 0;
    let previousIds = [];
    let previousOffset = 0;
    this.channelRootStore.select(getExtranetChannelsInfo).pipe(take(1))
      .subscribe(info => {
        totalCount = info.totalCount;
        previousIds = info.ids;
        previousOffset = info.offset;
      });

    if (totalCount > offset) {
      const data = this.buildDataObject(totalCount, previousOffset, previousIds, true, false);
      this.channelRootStore.dispatch(new ExtranetChannelsInfoUpdate(data));
      this.channelService.getChannels(offset, limit, false, false, false, false, false, true, this.userConfig.id)
        .subscribe(res => {
          const channels: Channel[] = res.channels;
          const data = {
            totalCount: res.total_count,
            offset: offset + res.channels.length,
            ids: [...previousIds, ...this.extractIdsFromChannels(channels, previousIds)],
            isLoading: false,
            isLoaded: true
          };
          this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
          this.channelRootStore.dispatch(new ExtranetChannelsInfoUpdate(data));
        });
    }
  }

  addChannelToFavorite(channelId: string): Observable<any> {
    return this.channelService.favoriteChannel(channelId).pipe(tap(res => {
      this.changeChannelCategory(res.channel, "favorited");
      this.updateChannelIntoStore(res?.channel, false);
      this.translate.get("ADDED_TO_FAVORITES").pipe(take(1)).subscribe(text => this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK));
    }, () => {
      this.translate.get("SOME_UNKNOWN_ERROR").pipe(take(1)).subscribe(text => this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE));
    }));
  }

  removeChannelFromFavorite(channelId: string): Observable<any> {
    return this.channelService.unfavoriteChannel(channelId).pipe(tap(res => {
      this.changeChannelCategory(res.channel, "favorited");
      this.updateChannelIntoStore(res?.channel, false);
      this.translate.get("REMOVED_FROM_FAVORITES").pipe(take(1)).subscribe(text => this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK));
    }, () => {
      this.translate.get("SOME_UNKNOWN_ERROR").pipe(take(1)).subscribe(text => this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE));
    }));
  }

  selectSubscribedChannels() {
    let subscribedChannels = [];
    const $subscribedChannelsInfoOb = this.channelRootStore.select(getSubscribedChannelsInfo);
    const $allAvailableChannelsOb = this.channelRootStore.select(getAllChannelsList);
    return combineLatest([$subscribedChannelsInfoOb, $allAvailableChannelsOb])
      .pipe(map((value: [{ totalCount: number, offset: number, ids: string[] }, Channel[]]) => {
        const ids = value?.[0]?.ids;
        const allAvailableChannels = value?.[1];
        subscribedChannels = allAvailableChannels.filter(v => ids.includes(v.id));
        return subscribedChannels;
      }));
  }

  selectFavoriteChannels() {
    let favoriteChannels = [];
    const $favoriteChannelsInfoOb = this.channelRootStore.select(getFavoriteChannelsInfo);
    const $allAvailableChannelsOb = this.channelRootStore.select(getAllChannelsList);
    return combineLatest([$favoriteChannelsInfoOb, $allAvailableChannelsOb])
      .pipe(map((value: [{ totalCount: number, offset: number, ids: string[] }, Channel[]]) => {
        const ids = value?.[0]?.ids;
        const allAvailableChannels = value?.[1];
        favoriteChannels = allAvailableChannels.filter(v => ids.includes(v.id));
        return favoriteChannels;
      }));
  }
  selectMyChannels() {
    let myChannels = [];
    const $myChannelsInfoOb = this.channelRootStore.select(getMyChannelsInfo);
    const $allAvailableChannelsOb = this.channelRootStore.select(getAllChannelsList);
    return combineLatest([$myChannelsInfoOb, $allAvailableChannelsOb])
      .pipe(map((value: [{ totalCount: number, offset: number, ids: string[] }, Channel[]]) => {
        const ids = value?.[0]?.ids;
        const allAvailableChannels = value?.[1];
        myChannels = allAvailableChannels.filter(v => ids.includes(v.id));
        return myChannels;
      }));
  }

  selectIntranetChannels() {
    let intranetChannels = [];
    const $intranetChannelInfoOb = this.channelRootStore.select(getIntranetChannelsInfo);
    const $allAvailableChannelsOb = this.channelRootStore.select(getAllChannelsList);
    return combineLatest([$intranetChannelInfoOb, $allAvailableChannelsOb])
      .pipe(map((value: [{ totalCount: number, offset: number, ids: string[] }, Channel[]]) => {

        const ids = value?.[0]?.ids;
        const allAvailableChannels = value?.[1];
        intranetChannels = allAvailableChannels.filter(v => v.channel_type === "intranet");
        return intranetChannels;
      }));
  }

  selectExtranetChannels() {
    let intranetChannels = [];
    const $intranetChannelInfoOb = this.channelRootStore.select(getExtranetChannelsInfo);
    const $allAvailableChannelsOb = this.channelRootStore.select(getAllChannelsList);
    return combineLatest([$intranetChannelInfoOb, $allAvailableChannelsOb])
      .pipe(map((value: [{ totalCount: number, offset: number, ids: string[] }, Channel[]]) => {

        const ids = value?.[0]?.ids;
        const allAvailableChannels = value?.[1];
        intranetChannels = allAvailableChannels.filter(v => v.channel_type === "extranet");
        return intranetChannels;
      }));
  }

  getLoadingLoadedForSubscribedChannels(): Observable<{ loading: boolean, loaded: boolean }> {
    return this.channelRootStore.select(getSubscribedChannelsInfo).pipe(map(info => {
      return {
        loading: info.isLoading,
        loaded: info.isLoaded
      };
    }));
  }

  getLoadingLoadedForFavoriteChannels(): Observable<{ loading: boolean, loaded: boolean }> {
    return this.channelRootStore.select(getFavoriteChannelsInfo).pipe(map(info => {
      return {
        loading: info.isLoading,
        loaded: info.isLoaded
      };
    }));
  }

  getLoadingLoadedForMyChannels(): Observable<{ loading: boolean, loaded: boolean }> {
    return this.channelRootStore.select(getMyChannelsInfo).pipe(map(info => {
      return {
        loading: info.isLoading,
        loaded: info.isLoaded
      };
    }));
  }

  getLoadingLoadedForIntranetChannels(): Observable<{ loading: boolean, loaded: boolean }> {
    return this.channelRootStore.select(getIntranetChannelsInfo).pipe(map(info => {
      return {
        loading: info.isLoading,
        loaded: info.isLoaded
      };
    }));
  }
  getLoadingLoadedForExranetChannels(): Observable<{ loading: boolean, loaded: boolean }> {
    return this.channelRootStore.select(getExtranetChannelsInfo).pipe(map(info => {
      return {
        loading: info.isLoading,
        loaded: info.isLoaded
      };
    }));
  }

  changeChannelCategory(channelData: Channel, changedProperty: "is_public" | "subscribed" | "archived" | "favorited" | "iom") {
    if (changedProperty === "is_public") {
      const wasPublic = channelData.is_public;
      const $publicChannelsInfoOb = this.channelRootStore.select(getPublicChannelsInfo);
      const $privateChannelsInfoOb = this.channelRootStore.select(getPrivateChannelsInfo);
      combineLatest([$publicChannelsInfoOb, $privateChannelsInfoOb])
        .pipe(take(1), map(([publicChannelsInfo, privateChannelsInfo]) => {
          return [
            {
              ...publicChannelsInfo,
              ids: wasPublic ? this.removeItem(publicChannelsInfo.ids, channelData.id) : this.includeItem(publicChannelsInfo.ids, channelData.id)
            },
            {
              ...privateChannelsInfo,
              ids: !wasPublic ? this.removeItem(privateChannelsInfo.ids, channelData.id) : this.includeItem(privateChannelsInfo.ids, channelData.id)
            }
          ];
        }))
        .subscribe(([newPublicChannelsInfo, newPrivateChannelsInfo]) => {
          // @ts-ignore
          this.channelRootStore.dispatch(new PublicChannelInfoUpdate(newPublicChannelsInfo));
          // @ts-ignore
          this.channelRootStore.dispatch(new PrivateChannelInfoUpdate(newPrivateChannelsInfo));
        });
    }
    if (changedProperty === "archived") {
      const hasBeenArchived = !channelData.archived;
      const $allChannelsInfoOb = this.channelRootStore.select(getChannelsInfo);
      const $subscribedChannelsInfoOb = this.channelRootStore.select(getSubscribedChannelsInfo);
      const $publicChannelsInfoOb = this.channelRootStore.select(getPublicChannelsInfo);
      const $privateChannelsInfoOb = this.channelRootStore.select(getPrivateChannelsInfo);
      combineLatest([$allChannelsInfoOb, $publicChannelsInfoOb, $privateChannelsInfoOb, $subscribedChannelsInfoOb])
        .pipe(take(1), map(([allChannelsInfo, publicChannelsInfo, privateChannelsInfo, subscribedChannelsInfo]) => {
          return [
            {
              ...allChannelsInfo,
              ids: hasBeenArchived ? this.removeItem(allChannelsInfo.ids, channelData.id) : allChannelsInfo.ids
            },
            {
              ...publicChannelsInfo,
              ids: hasBeenArchived ? this.removeItem(publicChannelsInfo.ids, channelData.id) : publicChannelsInfo.ids
            },
            {
              ...privateChannelsInfo,
              ids: hasBeenArchived ? this.removeItem(privateChannelsInfo.ids, channelData.id) : privateChannelsInfo.ids
            },
            {
              ...subscribedChannelsInfo,
              ids: hasBeenArchived ? this.removeItem(subscribedChannelsInfo.ids, channelData.id) : subscribedChannelsInfo.ids
            }
          ];
        }))
        .subscribe(([newAllChannelsInfo, newPublicChannelsInfo, newPrivateChannelsInfo, newSubscribedChannelsInfo]) => {
          // @ts-ignore
          this.channelRootStore.dispatch(new ChannelInfoUpdate(newAllChannelsInfo));
          // @ts-ignore
          this.channelRootStore.dispatch(new SubscribedChannelInfoUpdate(newSubscribedChannelsInfo));
          // @ts-ignore
          this.channelRootStore.dispatch(new PublicChannelInfoUpdate(newPublicChannelsInfo));
          // @ts-ignore
          this.channelRootStore.dispatch(new PrivateChannelInfoUpdate(newPrivateChannelsInfo));
        });
    }
    if (changedProperty === "subscribed") {
      const isSubscribed = channelData.subscribed;
      const $subscribedChannelsInfoOb = this.channelRootStore.select(getSubscribedChannelsInfo);
      $subscribedChannelsInfoOb.pipe(take(1), map(subscribedChannelsInfo => {
        return {
          ...subscribedChannelsInfo,
          ids: !isSubscribed ? this.removeItem(subscribedChannelsInfo.ids, channelData.id) : this.includeItem(subscribedChannelsInfo.ids, channelData.id)
        };
      }))
        .subscribe(newSubscribedChannelsInfo => {
          // @ts-ignore
          this.channelRootStore.dispatch(new SubscribedChannelInfoUpdate(newSubscribedChannelsInfo));
        });
    }
    if (changedProperty === "iom") {
      const isSubscribed = channelData.subscribed;
      const $subscribedChannelsInfoOb = this.channelRootStore.select(getSubscribedChannelsInfo);
      $subscribedChannelsInfoOb.pipe(take(1), map(subscribedChannelsInfo => {
        return {
          ...subscribedChannelsInfo,
          ids: !isSubscribed ? this.removeItem(subscribedChannelsInfo.ids, channelData.id) : this.includeItem(subscribedChannelsInfo.ids, channelData.id)
        };
      }))
        .subscribe(newSubscribedChannelsInfo => {
          // @ts-ignore
          this.channelRootStore.dispatch(new SubscribedChannelInfoUpdate(newSubscribedChannelsInfo));
        });
      // const isIOM = channelData.is_iom;
      const $IOMChannelsInfoOb = this.channelRootStore.select(getIOMChannelsInfo);
      $IOMChannelsInfoOb.pipe(take(1), map(IOMChannelsInfo => {
        return {
          ...IOMChannelsInfo,
          subscribed: !isSubscribed ? this.removeItem(IOMChannelsInfo.subscribed, channelData.id) : this.includeItem(IOMChannelsInfo.subscribed, channelData.id)
        };
      }))
        .subscribe(newIOMChannelsInfo => {
          // @ts-ignore
          this.channelRootStore.dispatch(new IOMChannelInfoUpdate(newIOMChannelsInfo));
        });
    }
    if (changedProperty === "favorited") {
      const isFavorite = channelData.favorited;
      const $favoritedChannelsInfoOb = this.channelRootStore.select(getFavoriteChannelsInfo);
      $favoritedChannelsInfoOb.pipe(take(1), map(favoritedChannelsInfo => {
        return {
          ...favoritedChannelsInfo,
          ids: !isFavorite ? this.removeItem(favoritedChannelsInfo.ids, channelData.id) : this.includeItem(favoritedChannelsInfo.ids, channelData.id)
        };
      }))
        .subscribe(newFavoriteChannelInfo => {
          this.channelRootStore.dispatch(new FavoriteChannelInfoUpdate(newFavoriteChannelInfo));
        });
    }
  }

  setChannelCategory(channelData: Channel) {
    const isPublic = channelData.is_public;
    const hasBeenSubscribed = channelData.subscribed;
    const parent = channelData.parent;

    const $allChannelsInfoOb = this.channelRootStore.select(getChannelsInfo);
    $allChannelsInfoOb.pipe(take(1), map(allChannelsInfo => {
      return {
        ...allChannelsInfo,
        ids: this.includeItem(allChannelsInfo.ids, channelData.id)
      };
    }))
      .subscribe(newAllChannelsInfo => {
        // @ts-ignore
        this.channelRootStore.dispatch(new ChannelInfoUpdate(newAllChannelsInfo));
      });

    if (isPublic) {
      const $publicChannelsInfoOb = this.channelRootStore.select(getPublicChannelsInfo);
      $publicChannelsInfoOb.pipe(take(1), map(publicChannelsInfo => {
        return {
          ...publicChannelsInfo,
          ids: this.includeItem(publicChannelsInfo.ids, channelData.id)
        };
      }))
        .subscribe(newPublicChannelsInfo => {
          // @ts-ignore
          this.channelRootStore.dispatch(new PublicChannelInfoUpdate(newPublicChannelsInfo));
        });
    }
    if (!isPublic) {
      const $privateChannelsInfoOb = this.channelRootStore.select(getPrivateChannelsInfo);
      $privateChannelsInfoOb.pipe(take(1), map(privateChannelsInfo => {
        return {
          ...privateChannelsInfo,
          ids: this.includeItem(privateChannelsInfo.ids, channelData.id)
        };
      }))
        .subscribe(newPrivateChannelsInfo => {
          // @ts-ignore
          this.channelRootStore.dispatch(new PrivateChannelInfoUpdate(newPrivateChannelsInfo));
        });
    }
    if (hasBeenSubscribed) {
      const $subscribedChannelsInfoOb = this.channelRootStore.select(getSubscribedChannelsInfo);
      $subscribedChannelsInfoOb.pipe(take(1), map(subscribedChannelsInfo => {
        return {
          ...subscribedChannelsInfo,
          ids: this.includeItem(subscribedChannelsInfo.ids, channelData.id)
        };
      }))
        .subscribe(newSubscribedChannelsInfo => {
          // @ts-ignore
          this.channelRootStore.dispatch(new SubscribedChannelInfoUpdate(newSubscribedChannelsInfo));
        });
    }
    if (parent) {
      const $subChannelsInfoOb = this.channelRootStore.select(getSubChannelsInfo);
      $subChannelsInfoOb.pipe(take(1), map(subChannelsInfo => {
        if (subChannelsInfo?.parent[channelData.parent?.id]?.subchannels) {
          subChannelsInfo.parent[channelData.parent?.id].subchannels = [...subChannelsInfo.parent[channelData.parent?.id].subchannels, channelData.id];
        }
        return subChannelsInfo;
      }))
        .subscribe(newSubchannelsInfo => {
          // @ts-ignore
          this.channelRootStore.dispatch(new SubchannelInfoUpdate(newSubchannelsInfo));
        });
    }
  }

  removeItem(arr: string[], item: string) {
    const index = arr.indexOf(item);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  includeItem(arr: string[], item: string) {
    const index = arr.indexOf(item);
    if (index == -1) {
      arr.unshift(item);
    }
    return arr;
  }

  searchChannels(keyword: string) {
    return this.channelService.searchChannels(keyword)
      .pipe(
        tap((res: any) => {
          const data = {
            keyword: keyword,
            ids: [...res.channels].map(v => v.id),
            totalCount: res.total_count,
            offset: res.channels.length
          };
          this.channelRootStore.dispatch(new SearchedChannelInfoUpdate(data));
        })
      );
  }


  loadMoreSearchChannels(keyword: string, offset: number) {
    let totalCount = 0;
    let ids = [];
    this.channelRootStore.select(getSearchedChannelsInfo).pipe(take(1))
      .subscribe(info => {
        totalCount = info.totalCount;
        ids = info.ids;
      });

    if (totalCount > offset) {
      return this.channelService.searchChannels(keyword, offset)
        .pipe(
          tap((res: any) => {
            const newIds = res.channels.map(v => v.id);
            const data = {
              keyword: keyword,
              ids: [...ids, ...newIds],
              totalCount: res.total_count,
              offset: totalCount + newIds.length,
            };
            this.channelRootStore.dispatch(new SearchedChannelInfoUpdate(data));
          })
        );
    }
    else {
      return of([]);
    }
  }


  getChannelSearchString(): Observable<string> {
    return this.channelRootStore.select(getChannelSearchString);
  }


  setChannelSearchString(searchString: string) {
    return this.channelRootStore.dispatch(new ChannelSearchStringUpdate(searchString));
  }


  getChannelUserJid(): Observable<string> {
    return this.channelRootStore.select(getChannelUserJid);
  }


  setChannelUserJid(userJid: string) {
    return this.channelRootStore.dispatch(new ChannelUserJidUpdate(userJid));
  }

  getChannelNoAccess(): Observable<boolean> {
    return this.channelRootStore.select(getChannelNoAccess);
  }

  getChannelNotFound(): Observable<boolean> {
    return this.channelRootStore.select(getChannelNotFound);
  }


  muteChannel(item): Observable<any> {
    return this.channelService.muteChannel(item.id)
      .pipe(tap(res => {
        this.updateChannelIntoStore(res.channel, false);
        this.translate.get("CHANNEL_MUTED").pipe(take(1)).subscribe(text => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
        });
      }));
  }


  unmuteChannel(item): Observable<any> {
    return this.channelService.unmuteChannel(item.id)
      .pipe(tap(res => {
        this.updateChannelIntoStore(res.channel, false);
        this.translate.get("CHANNEL_UNMUTED").pipe(take(1)).subscribe(text => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
        });
      }));
  }


  pinChannel(item): Observable<any> {
    return this.channelService.pinChannel(item.id)
      .pipe(tap(res => {
        this.updateChannelIntoStore(res.channel, false);
      }));
  }


  unpinChannel(item): Observable<any> {
    return this.channelService.unpinChannel(item.id)
      .pipe(tap(res => {
        this.updateChannelIntoStore(res.channel, false);
      }));
  }


  archiveChannel(channelId: string) {
    this.channelService.archiveChannel(channelId)
      .subscribe(() => {
        this.removeChannelIntoStore(channelId);
        this.getSelectedChannelId()
          .pipe(take(1))
          .subscribe(id => {
            if (id !== null) {
              this.setActiveTabChannel();
              this.broadcaster.broadcast(ConstantsUtil.CLOSE_SIDEBAR);
              this.getChannelById(id).pipe(take(1)).subscribe(resp => {
                if (!resp?.parent) {
                  this.channelRootStore.dispatch(new SetSelectedChannelId(null));
                }
              });
            }
          });
        this.translate.get("CHANNEL_ARCHIVED").pipe(take(1)).subscribe(text => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
        });
      }, err => {
        this.logger.info("[archiveChannel] err", err);
        if (err.error) {
          this.channelSnackBarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
        }
      });
  }
  selectTrashedChannels(): Observable<Channel[]> {
    let trashedChannels = [];
    const $trashedChannelsInfoOb = this.channelRootStore.select(getTrashedChannelsInfo);
    const $allAvailableChannelsOb = this.channelRootStore.select(getAllChannelsList);
    return combineLatest([$trashedChannelsInfoOb, $allAvailableChannelsOb])
      .pipe(map((value: [{totalCount: number, offset: number, ids: string[]}, Channel[]]) => {
        const ids = value?.[0]?.ids;
        const allAvailableChannels = value?.[1];
        trashedChannels = allAvailableChannels.filter(v => ids.includes(v.id));
        return trashedChannels;
      }));
  }

  selectArchivedChannels(): Observable<Channel[]> {
    let archivedChannels = [];
    const $archivedChannelsInfoOb = this.channelRootStore.select(getArchivedChannelsInfo);
    const $allAvailableChannelsOb = this.channelRootStore.select(getAllChannelsList);
    return combineLatest([$archivedChannelsInfoOb, $allAvailableChannelsOb])
      .pipe(map((value: [{totalCount: number, offset: number, ids: string[]}, Channel[]]) => {
        const ids = value?.[0]?.ids;
        const allAvailableChannels = value?.[1];
        archivedChannels = allAvailableChannels.filter(v => ids.includes(v.id));
        return archivedChannels;
      }));
  }
  loadMoreTrashedChannels(offset: number, limit?: number, sort: ChannelGroupType = ChannelGroupType.DATE_ASC, q?: string) {
    let totalCount = 0;
    let previousIds = [];
    let previousOffset = 0;
    this.channelRootStore.select(getTrashedChannelsInfo)
      .subscribe(info => {
        totalCount = info.totalCount;
        previousIds = info.ids;
        previousOffset = info.offset;
      });

    if (totalCount > offset) {
      const data = this.buildDataObject(totalCount, previousOffset, previousIds, true, false);
      this.channelRootStore.dispatch(new TrashedChannelInfoUpdate(data));
      this.channelService.getTrashedChannels({offset, limit, q, sort})
        .subscribe(res => {
          const channels: Channel[] = res.channels;
          const data = {
            totalCount: res.total_count,
            offset: offset + res.channels.length,
            ids: [...previousIds, ...this.extractIdsFromChannels(channels, previousIds)],
            isLoading: false,
            isLoaded: true
          };
          this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
          this.channelRootStore.dispatch(new TrashedChannelInfoUpdate(data));
        });
    }
  }

  loadMoreArchivedChannels(offset: number, limit?: number, sort: ChannelGroupType = ChannelGroupType.DATE_ASC, q?: string) {
    let totalCount = 0;
    let previousIds = [];
    let previousOffset = 0;
    let data = {
      offset : offset,
      limit : limit,
      sort : sort,
      q: q
    };
    this.channelRootStore.select(getArchivedChannelsInfo)
      .subscribe(info => {
        totalCount = info.totalCount;
        previousIds = info.ids;
        previousOffset = info.offset;
      });

    if (totalCount > offset) {
      const data = this.buildDataObject(totalCount, previousOffset, previousIds, true, false);
      this.channelRootStore.dispatch(new ArchivedChannelInfoUpdate(data));
      this.channelService.getArchivedChannels({offset, limit, q: "", sort})
        .subscribe(res => {
          const channels: Channel[] = res.channels;
          const data = {
            totalCount: res.total_count,
            offset: offset + res.channels.length,
            ids: [...previousIds, ...this.extractIdsFromChannels(channels, previousIds)],
            isLoading: false,
            isLoaded: true
          };
          this.channelRootStore.dispatch(new ChannelBulkAdd(channels));
          this.channelRootStore.dispatch(new ArchivedChannelInfoUpdate(data));
        });
    }
  }


  unarchiveChannel(channelId: string) {
    this.channelService.unarchiveChannel(channelId)
      .subscribe((res: any) => {
        this.removeChannelIntoStore(channelId);
        this.addChannelIntoStore(res?.channel);
        this.translate.get("UNARCHIVE_CHANNEL_SUCCESS").pipe(take(1)).subscribe(text => this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK));
      });
  }

  unarchiveTopic(topicId: string): Observable<any> {
    return this.channelService.unArchiveTopic(topicId).pipe(tap((res: any) => {
      res.topic.id = String(res.topic.id);
      if (res.topic.id.includes("iom"))
        res.topic.id = res.topic.is_iom ? res.topic.id : res.topic.id.replace("iom-", "");
      else
        res.topic.id = res.topic.is_iom ? "iom-" + res.topic.id : res.topic.id;
      this.updateTopicIntoStore(res?.topic);
      this.translate.get("UNARCHIVE_TOPIC_SUCCESS").pipe(take(1)).subscribe(text => this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK));
    }));
  }


  getChannelById(channelId: string) {
    const checkParentChannel = (channel: Channel) => {
      if (channel?.parent) {
        this.getChannelById(channel?.parent?.id)
          .pipe(map((v: any) => v?.channel as Channel), take(1))
          .subscribe((res) => {
            if (res) {
              this.updateChannel(channel?.id, { ...channel, parent: res });
            }
          });
      }
    };

    return this.channelRootStore.select(state => getChannelById(state, channelId))
      .pipe(
        distinctUntilChanged((p: Channel, q: Channel) => (p?.id === q?.id) && (p?.updated_on === q?.updated_on)),
        tap(res => {
          if (!res && !!channelId && this.router.url.includes(channelId)) {
            this.channelService.getChannelById(channelId)
              .pipe(map((v: any) => v.channel as Channel))
              .subscribe(res => {
                checkParentChannel(res);
                this.channelRootStore.dispatch(new ChannelNoAccess(false));
                this.channelRootStore.dispatch(new ChannelNotFound(false));
                this.addChannelIntoStore(res);
              }, err => {
                if (err && err?.error?.error?.includes("Forbidden")) {
                  this.channelRootStore.dispatch(new ChannelNoAccess(true));
                } else {
                  this.channelRootStore.dispatch(new ChannelNotFound(true));
                }
              });
          } else if (!!res && !!channelId) {
            this.channelRootStore.dispatch(new ChannelNoAccess(false));
            this.channelRootStore.dispatch(new ChannelNotFound(false));
          }
        }),
      );
  }

  getIOMChannelById(channelId: string) {
    return this.channelRootStore.select(state => getChannelById(state, channelId))
      .pipe(
        distinctUntilChanged((p: Channel, q: Channel) => (p?.id === q?.id) && (p?.updated_on === q?.updated_on)),
        tap(res => {
          if (!res && !!channelId) {
            this.channelService.getIOMChannelById(channelId)
              .pipe(map((v: any) => {
                this.logger.info("[Channel]:", v.channel);
                return v.channel as Channel;
              }))
              .subscribe(res => {
                this.channelRootStore.dispatch(new ChannelNoAccess(false));
                this.channelRootStore.dispatch(new ChannelNotFound(false));
                this.addChannelIntoStore(res);
              }, err => {
                if (err && err?.error?.error?.includes("Forbidden")) {
                  this.channelRootStore.dispatch(new ChannelNoAccess(true));
                } else {
                  this.channelRootStore.dispatch(new ChannelNotFound(true));
                }
              });
          } else if (!!res && !!channelId) {
            this.channelRootStore.dispatch(new ChannelNoAccess(false));
            this.channelRootStore.dispatch(new ChannelNotFound(false));
          }
        }),
      );
  }


  getTopics(channelId: string) {
    return this.channelRootStore.select(getAllTopics)
      .pipe(
        map(v => v.filter(topic => topic?.channel_id === channelId))
        /*tap(res => {
          let channelLoaded = false;
          this.channelRootStore.select(getChannelIdsWithLoadedTopics)
            .pipe(take(1))
            .subscribe(channelIds => {
              channelLoaded = channelIds.includes(channelId);
              if (!res || !channelLoaded) {
                this.channelService.getTopics(channelId)
                  .subscribe((res: any) => {
                    const topics: Topic[] = res.topics;
                    const info = {
                      totalCount: res.total_count,
                      offset: topics.length
                    };
                    this.topicsStore.dispatch(new TopicChannelAdd(channelId));
                    this.topicsStore.dispatch(new TopicInfoUpdate({channelId, info}));
                    this.topicsStore.dispatch(new TopicBulkAdd(topics));
                  });
              }
            });
        })*/
      );
  }


  loadMoreTopics(channelId: string, offset?: number, limit?: number) {
    let totalCount = 0;
    this.channelRootStore.select(state => getTopicsInfoByChannelId(state, channelId))
      .pipe(take(1))
      .subscribe(topicInfo => {
        if (topicInfo) {
          totalCount = topicInfo.totalCount;
          if (offset > topicInfo.offset) {
            offset = topicInfo.offset;
          }
        }
      });
    if (totalCount > offset) {
      this.channelService.getTopics(channelId, offset, limit)
        .pipe(take(1))
        .subscribe((res: any) => {
          if (res && !res.error) {
            const topics: Topic[] = (res.topics as Topic[]).map((t) => {
              return { ...t, id: t.is_iom ? "iom-" + t.id : t.id };
            });
            const info = {
              totalCount: res.total_count,
              offset: offset + topics.length,
              isLoaded: true
            };
            this.channelRootStore.dispatch(new TopicBulkAdd(topics));
            this.channelRootStore.dispatch(new TopicInfoUpdate({ channelId, info }));
          }
        });
    }
  }


  // ___________ CHANNELS ______________


  setSelectedTopic(topicId: string) { // returns all files of particular channel from store.
    this.channelRootStore.dispatch(new SetSelectedTopicId(topicId));
  }


  getSelectedTopicId(): Observable<string | null> {
    return this.channelRootStore.select(state => {
      // @ts-ignore
      return state?.channels?.topic?.selectedTopicId;
    });
  }


  getSelectedTopic(): Observable<Topic> {
    return this.getSelectedTopicId()
      .pipe(
        switchMap((selectedTopicId: Topic["id"]) => {
          // @ts-ignore
          return this.channelRootStore.select(state => state?.channels?.topic?.entities)
            .pipe(map((topics: any) => {
              return topics?.[selectedTopicId] || {};
            }));
        })
      );
  }


  deleteTopic(topicId, channelId, prevReadStatus, isRedirect: boolean = true, moveToTrash = false, showSnackbar = true) {
    topicId = String(topicId)?.replace("iom-", "");
    const changeInUnreadCount = !!prevReadStatus ? 0 : -1;
    this.channelService.deleteTopic(topicId, moveToTrash)
      .subscribe(() => {
        if (isRedirect) {
          this.removeTopicIntoStoreAndRedirect(topicId);
        } else {
          this.removeTopicIntoStore(topicId);
        }
        this.broadcaster.broadcast("DELETE_SOCIAL_POST_REDIRECT");
        this.updateChannelTopicCount(channelId, -1, changeInUnreadCount);
        if (showSnackbar) {
          const DELETE_KEY = isRedirect ? "TOPIC_DELETED" : "SOCIAL_POST_DELETED";
          this.translate.get(moveToTrash ? "TOPIC_MOVED_TO_TRASH" : DELETE_KEY).pipe(take(1)).subscribe(text => {
            this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
          });
        }
      }, err => {
        this.logger.info("[deleteTopic] err", err);
        if (err.error) {
          this.channelSnackBarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
        }
      });
  }

  restoreTopic(topicId) {
    return this.channelService.restoreTopic(topicId)
      .pipe(tap(res => {
        if (res?.topic) {
          res.topic.id = String(res.topic.id);
          if (res.topic.id.includes("iom"))
            res.topic.id = res.topic.is_iom ? res.topic.id : res.topic.id.replace("iom-", "");
          else
            res.topic.id = res.topic.is_iom ? "iom-" + res.topic.id : res.topic.id;
          this.updateTopicIntoStore(res?.topic);
        }
      }));

  }


  archiveTopic(topicId, channelId, prevReadStatus) {
    const changeInUnreadCount = !!prevReadStatus ? 0 : -1;
    this.channelService.archiveTopic(topicId)
      .subscribe(() => {
        this.removeTopicIntoStoreAndRedirect(topicId);
        this.updateChannelTopicCount(channelId, -1, changeInUnreadCount);
        this.translate.get("TOPIC_ARCHIVED").pipe(take(1)).subscribe(text => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
        });
      }, err => {
        this.logger.info("[archiveTopic] err", err);
        if (err.error) {
          this.channelSnackBarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
        }
      });
  }

  cloneTopic(topicId) {
    this.channelService.cloneTopic(topicId).subscribe(res => {
      res.topic.id = String(res.topic.id);
      if (res.topic.id.includes("iom"))
        res.topic.id = res.topic.is_iom ? res.topic.id : res.topic.id.replace("iom-", "");
      else
        res.topic.id = res.topic.is_iom ? "iom-" + res.topic.id : res.topic.id;
      this.addTopicIntoStore(res.topic);
      this.updateChannelTopicCount(res?.topic?.channel_id, 1, 0);
      this.translate.get("TOPIC_CLONED").pipe(take(1)).subscribe(text => {
        this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
      });
    }, err => {
      this.logger.info("[cloneTopic] err", err);
      if (err.error) {
        this.channelSnackBarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
      }
    });
  }

  cloneTopicToChannel(topicId, channelId) {
    this.channelService.cloneTopic(topicId).subscribe(res => {
      res.topic.id = String(res.topic.id);
      if (res.topic.id.includes("iom"))
        res.topic.id = res.topic.is_iom ? res.topic.id : res.topic.id.replace("iom-", "");
      else
        res.topic.id = res.topic.is_iom ? "iom-" + res.topic.id : res.topic.id;
      this.channelService.updateTopic(res.topic.id, {
        channel_id: channelId,
        subject: res.topic.subject.replace("(Copy)", "").trim(),
        is_draft: false,
        is_published: true,
      })
        .subscribe((updatedRes: any) => {
          this.addTopicIntoStore(updatedRes.topic);
          this.updateChannelTopicCount(channelId, 1, 0);
          this.translate.get("TOPIC_CLONED").pipe(take(1)).subscribe(text => {
            this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
            this.router.navigateByUrl(`/talk/channels/${channelId}/topics/${topicId}?comment=none`);
          });
        });
    });
  }

  setUnArchivedChannelId(channelId) {
    localStorage.setItem("selectedChannelId", channelId);
  }

  setLastStateChannelURL(url) {
    localStorage.setItem("lastStateChannelUrl", url);
  }

  getLastStateChannelURL() {
    return localStorage.getItem("lastStateChannelUrl");
  }

  selectTopicById(topicId: Topic["id"]) {
    return this.channelRootStore.select(state => getTopicById(state, topicId)).pipe(map(topic => {
      if (!topic) { this.getTopicDetail(topicId).subscribe(); }
      return topic;
    }), filter(topic => !!topic));
  }


  getTopicDetail(topicId: string, channel_id: string | null = null) {
    return this.channelService.getTopicDetails(topicId, channel_id)
      .pipe(
        map((v: any) => v.topic as Topic),
        tap((res: any) => {
          res.id = res.is_iom ? "iom-" + res.id : res.id;
          res.loaded = true;
          this.addTopicIntoStore(res);
        })
      );
  }


  getTopicById(topicId: Topic["id"]): Observable<Topic> {
    return this.channelRootStore.select(state => getTopicById(state, topicId));
  }


  createTopic(channelId, body, inviteJids, relatedTopicIds) {
    return this.channelService.createTopic(channelId, body, inviteJids, relatedTopicIds)
      .pipe(
        map((v: any) => v.topic as Topic),
        tap(res => {
          res.id = res?.is_iom ? "iom-" + res?.id : res?.id;
          this.updateChannelTopicCount(res?.channel_id, 1, 0);
          this.addTopicIntoStore(res);
          this.updateChannel(res?.channel_id, { updated_on: res?.updated_on });
        })
      );
  }


  updateTopic(topicId, body, relatedTopicIds?: any[], conflict_resolution?: string) {
    return this.channelService.updateTopic(topicId, body, relatedTopicIds)
      .pipe(
        map((res: any) => {
          if (res?.topic) {
            let topic: Topic = res?.topic as Topic;
            topic.id = topic.is_iom ? "iom-" + topic.id : topic.id;
            this.updateTopicIntoStore(topic);
            return topic;
          } else if (res?.conflicts) {
            return res?.conflicts;
          }
        })
      );
  }


  updateTopicRelations(topicId, relatedTopicIds?: any[]) {
    return this.channelService.updateTopicRelations(topicId, relatedTopicIds)
      .pipe(
        map((v: any) => v.topic as Topic),
        tap(res => {
          this.updateTopicIntoStore(res);
        })
      );
  }

  addTopicIntoStore(topic: Topic) {
    this.channelRootStore.dispatch(new TopicAdd(topic));
  }

  updateTopicIntoStore(topic: Topic) {
    this.channelRootStore.dispatch(new TopicUpdate(topic));
  }

  removeTopicIntoStore(topicId: string) {
    this.channelRootStore.dispatch(new TopicDelete(topicId));
  }

  updateTopicCommentCountForCreateComment(commentId: number, topicId: string, parentId?: number) {
    setTimeout(() => {
      this.getComment(commentId, parentId, topicId).pipe(take(1)).subscribe(
        comment => {
          this.logger.info("[COMMENT]", comment, commentId);
        }
      );
    }, 1000);
  }

  updateTopicCommentCountForDeleteComment(topicId: string) {
    this.getTopicById(topicId).pipe(take(1)).subscribe(
      topic => {
        if (!!topic) {
          this.channelService.getTopicDetails(topicId).subscribe((v: any) => {
            const topic = v.topic;
            if (!!topic) {
              this.updateTopicIntoStore({ ...topic });
            }
          });
        }
      });
  }

  updateTopicCommentCount(topicId: Topic["id"], changeInTotalCount = 0, changeInUnreadCount = 0) {
    this.logger.info("[MessageCableService][handleNotification]::::::: update topic count called", topicId);
    this.channelRootStore.select((state: any) => state?.channels?.topic?.entities?.[topicId]).pipe(take(1)).subscribe((topic: Topic) => {
      if (!!topic) {
        this.logger.info("[MessageCableService][handleNotification]:::::::", topic);
        let comments_count = topic.comments_count;
        if (changeInTotalCount > 0) {
          comments_count = !!topic.comments_count ? topic.comments_count + changeInTotalCount : 1;
        } else if (changeInTotalCount < 0) {
          comments_count = !!topic.comments_count ? topic.comments_count - 1 : 0;
        }

        let unread_comments_count = topic.unread_comments_count;
        if (changeInUnreadCount > 0) {
          unread_comments_count = !!topic.unread_comments_count ? topic.unread_comments_count + changeInTotalCount : 1;
        } else if (changeInUnreadCount < 0) {
          unread_comments_count = !!topic.unread_comments_count ? topic.unread_comments_count - 1 : 0;
        }
        this.logger.info("[MessageCableService][handleNotification]:::::::", unread_comments_count, comments_count);
        this.updateTopicIntoStore({ ...topic, comments_count, unread_comments_count });
      }
    });
  }


  likesTopic(topicId, channel_id?: string) {
    const updatedTopic = new Subject<Topic>();
    this.channelService.likesTopic(topicId, channel_id)
      .subscribe(res => {
        this.getTopicById(topicId).pipe(take(1)).subscribe(topic => {
          if (res.topic.is_iom) res.topic.id = "iom-" + res.topic.id;
          const updated_on = topic.updated_on;
          const newTopic = { ...res.topic, updated_on };
          this.updateTopicIntoStore(newTopic);
          updatedTopic.next(newTopic);
        });
      }, err => {
        this.logger.info("[likesTopic] err", err);
        if (err.error) {
          this.channelSnackBarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
        }
      });
    return updatedTopic;
  }


  unlikesTopic(topicId, channel_id?: string) {
    const updatedTopic = new Subject<Topic>();
    this.channelService.unlikesTopic(topicId, channel_id)
      .subscribe(res => {
        this.getTopicById(topicId).pipe(take(1)).subscribe(topic => {
          if (res.topic.is_iom) res.topic.id = "iom-" + res.topic.id;
          const updated_on = topic.updated_on;
          const newTopic = { ...res.topic, updated_on };
          this.updateTopicIntoStore(newTopic);
          updatedTopic.next(newTopic);
        });
      }, err => {
        this.logger.info("[unlikesTopic] err", err);
        if (err.error) {
          this.channelSnackBarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
        }
      });
    return updatedTopic;
  }

  getComment(commentId: CommentItem["id"], parentId?: CommentItem["id"], topicId?: any) {
    if (!!parentId && !!topicId) {
      return this.channelRootStore.select(state => getParentCommentInTopicById({ ...state }, parentId, topicId))
        .pipe(take(1), map(comment => {
          const findComment = (comment: CommentItem) => {
            for (const commentReply of (comment?.replies || [])) {
              if (commentReply.id === commentId) { return commentReply; }
              if (commentReply?.replies?.length) {
                const result = findComment(commentReply);
                if (result?.id === commentId) { return result; }
              }
            }
          };
          return findComment(comment);
        }));
    }
    return this.channelRootStore.select((state: any) => state?.channels?.comment?.entities?.[commentId]);
  }

  getCommentsLoading(topicId: Topic["id"]) {
    return this.channelRootStore.select(getTopicComments).pipe(map(val => {
      if (!!val?.[topicId]) {
        return val?.[topicId].isLoading;
      } else {
        return false;
      }
    }));
  }

  loadMoreComments(topicId: Topic["id"], channel_id?: string) {
    let topicComments = null;
    this.channelRootStore.select(getTopicComments).pipe(take(1)).subscribe(val => {
      this.logger.info("[COMMENT_LOAD_MORE]", val, val?.[topicId]);
      if (!!val?.[topicId]) {
        topicComments = val[topicId];
      }
    });
    if ((topicComments?.offset < topicComments?.total_count) && !topicComments?.isLoading) {
      this.channelRootStore.dispatch(new TopicCommentsLoadRequest(topicId));
      this.channelService.getComments(topicId, channel_id, { offset: topicComments.offset, limit: 25 }).subscribe((v: any) => {
        if (v && !v.error) {
          const comments = v.comments as CommentItem[];
          const offset = v.offset + v.comments.length;
          const total_count = v.total_count;
          this.channelRootStore.dispatch(new TopicCommentsLoadSuccess({ topicId, offset, total_count }));
          this.channelRootStore.dispatch(new CommentBulkAdd(CommonUtil.getMappedComments(comments)));
        }
      });
    }
  }

  loadMoreReplies(topicId: number, comment: CommentItem, parent?: CommentItem, channel_id?: string) {
    this.channelRootStore.dispatch(new CommentRepliesLoadRequest(comment.id));
    let commentReplies = null;
    this.channelRootStore.select(getCommentReplies).pipe(take(1)).subscribe(val => {
      this.logger.info("[COMMENT_REPLIES_LOAD_MORE]", val, val?.[comment.id]);
      if (!!val?.[comment.id]) {
        commentReplies = val[comment.id];
      }
    });
    if (commentReplies?.offset < commentReplies?.total_count) {
      this.channelService.getRepliesComments(topicId, comment.id, channel_id, { offset: commentReplies.offset, limit: 25 }).pipe(debounceTime(500)).subscribe((v: any) => {
        if (v && !v.error) {
          const comments = v.comments as CommentItem[];
          const offset = v.offset + v.comments.length;
          const total_count = v.total_count;
          this.channelRootStore.dispatch(new CommentRepliesLoadSuccess({ commentId: comment.id, offset, total_count }));
          this.channelRootStore.select(state => getParentCommentInTopicById(state, parent.id, topicId)).pipe(take(1)).subscribe(c => {
            this.logger.info("[CommentsComponent] getParentCommentInTopicById", c);
            if (!!c) {
              if (c.id === comment.id) {
                const replies = c.replies || [];
                this.channelRootStore.dispatch(new CommentUpdate({ id: parent.id, replies: [...replies, ...comments] }));
              } else {
                c.replies.forEach(c1 => {
                  if (c1.id === comment.id) {
                    const replies = c1.replies || [];
                    c1.replies = [...replies, ...comments];
                  }
                });
                this.channelRootStore.dispatch(new CommentUpdate({ id: parent.id, replies: c.replies }));
                this.commentService.broadcast(EVENTS.REPLIES_LOADED, { comment });
              }
            }
          });
        }
      });
    }
  }

  likeComment(commentId) {
    this.channelService.likeComment(commentId)
      .subscribe(res => {
        this.channelRootStore.dispatch(new CommentUpdate(res.comment));
      }, err => {
        this.logger.info("[likeComment] err", err);
        if (err.error) {
          this.channelSnackBarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
        }
      });
  }

  unlikeComment(commentId) {
    this.channelService.unlikeComment(commentId)
      .subscribe(res => {
        this.channelRootStore.dispatch(new CommentUpdate(res.comment));
      }, err => {
        this.logger.info("[likeComment] err", err);
        if (err.error) {
          this.channelSnackBarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
        }
      });
  }

  dislikeComment(commentId) {
    this.channelService.dislikeComment(commentId)
      .subscribe(res => {
        this.channelRootStore.dispatch(new CommentUpdate(res.comment));
      }, err => {
        this.logger.info("[likeComment] err", err);
        if (err.error) {
          this.channelSnackBarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
        }
      });
  }

  undislikeComment(commentId) {
    this.channelService.undislikeComment(commentId)
      .subscribe(res => {
        this.channelRootStore.dispatch(new CommentUpdate(res.comment));
      }, err => {
        this.logger.info("[likeComment] err", err);
        if (err.error) {
          this.channelSnackBarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
        }
      });
  }

  openSideBar(type: string, id: string, selectedTopicTab?: string) {
    if (type === "channel") {
      this.setSelectedTopic(null);
      this.setSelectedChannelId(id);
      this.setChannelSidebarTab(selectedTopicTab);
    }
    else if (type === "topic") {
      this.setSelectedTopic(id);
      this.setTopicSidebarTab(selectedTopicTab);
    }
    this.broadcaster.broadcast(ConstantsUtil.OPEN_SIDEBAR);
  }

  setTopicSidebarTab(tab: string) {
    this.channelRootStore.dispatch(new TopicSideBarTabChange(tab));
  }

  setChannelSidebarTab(tab: string) {
    this.channelRootStore.dispatch(new ChannelSideBarTabChange(tab));
  }

  getTopicSidebarTab(): Observable<string> {
    return this.channelRootStore.select(getTopicSideBarTab);
  }

  getChannelSidebarTab(): Observable<string> {
    return this.channelRootStore.select(getChannelSideBarTab);
  }

  copyLink(type: string, channelId: string, topicId?: string) {
    let currentUrl = CommonUtil.getBaseOriginUrl();
    if (type === "channel") {
      currentUrl = `${currentUrl}/talk/channels/${channelId}`;
    }
    else if (type === "topic") {
      currentUrl = `${currentUrl}/talk/channels/${channelId}/topics/${topicId}`;
    }
    CommonUtil.copyToClipboard([currentUrl]);
    this.translate.get("LINK_COPIED_TO_THE_CLIPBOARD").pipe(take(1)).subscribe(text => {
      this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
    });
  }


  subscribeTopic(topicId: string) {
    this.channelService.subscribeTopic(topicId)
      .subscribe(res => {
        this.store.dispatch(new SubsribeTopicDelete(topicId));
        this.store.dispatch(new SubsribeTopicAdd(res.topic));
        this.updateTopicIntoStore(res.topic);
        this.translate.get("SUBSCRIBED_TO_THE_TOPIC").pipe(take(1)).subscribe(text => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
        });
      });
  }


  unsubscribeTopic(topicId: string): Observable<any>  {
    return this.channelService.unsubscribeTopic(topicId)
      .pipe(tap(res => {
        if (res.topic.subscribed) {
          this.translate.get("CANT_UNSUBSCRIBE_FROM_THE_TOPIC").pipe(take(1)).subscribe(text => {
            this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
          });
        }
        else {
          this.updateTopicIntoStore(res.topic);
          this.store.dispatch(new SubsribeTopicDelete(topicId));
          this.translate.get("UNSUBSCRIBED_FROM_THE_TOPIC").pipe(take(1)).subscribe(text => {
            this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
          });
        }
      }));
  }


  getMembersOfChannel(channelId: string, force = false): Observable<Member[]> {
    let allChannelMemberState: { [channelId: string]: { members: { id: string, role: string }[], isLoading: boolean, isLoaded: boolean } } = null;
    this.channelRootStore.select(getChannelMembers)
      .pipe(take(1))
      .subscribe(val => allChannelMemberState = val);
    if (allChannelMemberState?.[channelId]?.isLoading !== true && (!allChannelMemberState?.[channelId]?.isLoaded || force)) { // already loaded or force
      this.channelRootStore.dispatch(new ChannelMembersLoadRequest(channelId));
      const data = { offset: 0, limit: ConstantsUtil.PAGINATION_LIMIT.CHANNEL_MEMBERS };
      this.setChannelMembers(channelId, data);
    }
    return this.selectChannelMembers(channelId)
      .pipe(debounceTime(100));
  }


  // ___________ CHANNELS MEMBERS ______________


  getMemberRole(channelId: string, memberId: string): Observable<any> {
    return this.selectChannelMembers(channelId)
      .pipe(map((members: Member[]) => members.filter(member => member.jid === memberId)[0]));
  }


  loadMoreMembers(channelId: Channel["id"]) {
    this.channelRootStore.select(getChannelMembers)
      .pipe(take(1))
      .subscribe(value => {
        const loadedMembers = value?.[channelId]?.members?.length;
        const totalMembers = value?.[channelId]?.total_count;
        if (loadedMembers < totalMembers) {
          const channelMemberOffset = value?.[channelId]?.offset;
          const data = { offset: channelMemberOffset, limit: ConstantsUtil.PAGINATION_LIMIT.CHANNEL_MEMBERS };
          this.setChannelMembers(channelId, data);
        }
      });
  }


  addMembersToChannel(channelId: string, members: { jid: string, role: string }[]): Observable<any> {
    return this.channelService.addChannelMembers(channelId, { ...members })
      .pipe(tap(async (res: any) => {
        const channelMemberState = await this.channelRootStore.select(getChannelMembers)
          .pipe(take(1))
          .toPromise();
        const membersAdded = (members || []).length;
        const total_count = channelMemberState?.[channelId]?.total_count + membersAdded;
        this.updateChannelMemberPageData(channelId, { total_count });
        this.channelRootStore.dispatch(new ChannelMembersAdd({ channelId, members }));
        this.channelRootStore.dispatch(new MembersAdd(res.members.map(k => {
          const copy = { ...k, isNew: true };
          delete copy.role;
          return copy;
        })));
        setTimeout(() => {
          this.channelRootStore.dispatch(new MembersAdd(res.members.map(k => {
            const copy = { ...k, isNew: false };
            delete copy.role;
            return copy;
          })));
        }, 2000);
      }));
  }


  updateChannelMembers(channelId: string, members: { jid: string, role: string }[]) {
    this.channelService.updateChannelMembers(channelId, { ...members })
      .subscribe(({ members }) => {
        this.channelRootStore.dispatch(new ChannelMembersUpdate({ channelId, members }));
      });
  }

  addMembersToChannelWithoutAPICalling(channelId: string, members: { jid: string, role: string }[], memberDetails: Member[]) {
    let channelMemberState = null;
    this.channelRootStore.select(getChannelMembers)
      .pipe(take(1))
      .subscribe(v => channelMemberState = v);
    const membersAdded = (members || []).length;
    const total_count = channelMemberState?.[channelId]?.total_count + membersAdded;
    this.updateChannelMemberPageData(channelId, { total_count });
    this.channelRootStore.dispatch(new ChannelMembersAdd({ channelId, members }));
    this.channelRootStore.dispatch(new MembersAdd(memberDetails.map(k => {
      const copy = { ...k, isNew: true };
      delete copy.role;
      return copy;
    })));
    setTimeout(() => {
      this.channelRootStore.dispatch(new MembersAdd(memberDetails.map(k => {
        const copy = { ...k, isNew: false };
        delete copy.role;
        return copy;
      })));
    }, 2000);
  }


  removeMembersFromChannel(channelId: string, memberIds: string[]): Observable<any> {
    const formattedPayload = {};
    memberIds.forEach((id, index) => {
      formattedPayload[index + ""] = { "jid": id };
    });
    return this.channelService.removeChannelMembers(channelId, formattedPayload)
      .pipe(tap(async () => {
        const channelMemberState = await this.channelRootStore.select(getChannelMembers)
          .pipe(take(1))
          .toPromise();
        const membersRemoved = (memberIds || []).length;
        const total_count = channelMemberState?.[channelId]?.total_count - membersRemoved;
        const offset = channelMemberState?.[channelId]?.offset - membersRemoved;
        this.updateChannelMemberPageData(channelId, { offset, total_count });
        this.channelRootStore.dispatch(new ChannelMembersRemove({ channelId, memberIds }));
      }));
  }

  memberAddedInChannel(user, channelId) {
    setTimeout(() => {
      let channelMemberState = null;
      this.channelRootStore.select(getChannelMembers).pipe(take(1)).subscribe(v => channelMemberState = v);
      if (!channelMemberState?.[channelId]?.members.map(v => v.id).includes(user.id)) {
        const total_count = channelMemberState?.[channelId]?.total_count + 1;
        this.updateChannelMemberPageData(channelId, { total_count });
        this.channelRootStore.dispatch(new ChannelMembersAdd({ channelId, members: [{ ...user }] }));
        let userData = { ...user, isNew: true };
        delete userData.role;
        delete userData.topic_id;
        this.channelRootStore.dispatch(new MembersAdd([{ ...userData }]));
        setTimeout(() => {
          this.channelRootStore.dispatch(new MembersAdd([{ ...userData, isNew: false }]));
        }, 2000);
      }
    }, 1000);
  }


  memberRemovedFromChannel(userId, channelId) {
    setTimeout(() => {
      let channelMemberState = null;
      this.channelRootStore.select(getChannelMembers).pipe(take(1)).subscribe(v => channelMemberState = v);
      if (channelMemberState?.[channelId]?.members.map(v => v.id).includes(userId)) {
        const total_count = channelMemberState?.[channelId]?.total_count - 1;
        const offset = channelMemberState?.[channelId]?.offset - 1;
        this.updateChannelMemberPageData(channelId, { offset, total_count });
        this.channelRootStore.dispatch(new ChannelMembersRemove({ channelId, memberIds: [userId] }));
      }
    }, 1000);
  }

  memberUpdatedInChannel(user, channelId) {
    setTimeout(() => {
      this.channelRootStore.dispatch(new ChannelMembersUpdate({ channelId, members: [{ ...user }] }));
    }, 1000);
  }

  updatePermissions(channelId) {
    this.channelRootStore.select(state => getChannelById(state, channelId)).pipe(take(1)).subscribe(
      channel => {
        if (!!channel) {
          this.channelService.getChannelById(channelId)
            .pipe(map((v: any) => v.channel as Channel))
            .subscribe(res => {
              this.updateChannelIntoStore(res);
            });
          if (channel.topics_count > 0 || channel.channel_type?.includes("iom")) {
            this.getTopicsInfoByChannelId(channelId).pipe(take(1)).subscribe(info => {
              if (info && info?.isLoaded) {
                const topicsLoaded = info.totalCount;
                this.channelService.getTopics(channelId, 0, topicsLoaded).subscribe((res: any) => {
                  if (res && !res.error) {
                    const topics: Topic[] = (res.topics as Topic[]).map((t) => {
                      return { ...t, id: t.is_iom ? "iom-" + t.id : t.id };
                    });
                    const info = { totalCount: res.total_count, offset: topics.length, isLoaded: true };
                    this.channelRootStore.dispatch(new TopicBulkAdd(topics));
                    this.channelRootStore.dispatch(new TopicInfoUpdate({ channelId, info }));
                    this.channelRootStore.dispatch(new TopicChannelAdd(channelId));
                  }
                  else {
                    const info = { totalCount: 0, offset: 0, isLoaded: true };
                    this.channelRootStore.dispatch(new TopicInfoUpdate({ channelId, info }));
                    this.channelRootStore.dispatch(new TopicChannelAdd(channelId));
                  }
                });
              }
            });
          }
        }
      }
    );
  }


  getTotalChannelMembers(channelId: Channel["id"]): Observable<number> {
    return this.channelRootStore.select(getChannelMembers)
      .pipe(map(value => (value?.[channelId]?.total_count || 0)));
  }


  getChannelFiles(channelId: Channel["id"], force = false): Observable<File[]> {
    this.channelRootStore.select(getChannelFiles)
      .pipe(take(1))
      .subscribe((channelFileState: ChannelFiles) => {
        const isLoaded = !!channelFileState?.[channelId]?.isLoaded;
        const isLoading = !!channelFileState?.[channelId]?.isLoading;
        if (!(isLoaded || isLoading) || force) {
          this.channelRootStore.dispatch(new ChannelFilesLoadRequest(channelId));
          const data = { offset: 0, limit: ConstantsUtil.PAGINATION_LIMIT.CHANNEL_FILES };
          this.setChannelFiles(channelId, data);
        }
      });
    return this.selectChannelFiles(channelId);
  }


  loadMoreFiles(channelId: Channel["id"]) {
    this.channelRootStore.select(getChannelFiles)
      .pipe(take(1))
      .subscribe(value => {
        const loadedFiles = value?.[channelId]?.fileIds?.length;
        const totalFiles = value?.[channelId]?.total_count;
        if (loadedFiles < totalFiles) {
          const channelFilesOffset = value?.[channelId]?.offset;
          const data = { offset: channelFilesOffset, limit: ConstantsUtil.PAGINATION_LIMIT.CHANNEL_FILES };
          this.setChannelFiles(channelId, data);
        }
      });
  }

  getTopicFiles(topicId: Topic["id"], force = false, channel_id?: string): Observable<File[]> {
    this.channelRootStore.select(getTopicFiles)
      .pipe(take(1))
      .subscribe((topicFilesState: TopicFiles) => {
        const isLoaded = !!topicFilesState?.[topicId]?.isLoaded;
        const isLoading = !!topicFilesState?.[topicId]?.isLoading;
        if (!isLoaded || !isLoading || force) {
          this.channelRootStore.dispatch(new TopicFilesLoadRequest(topicId));
          const data = { offset: 0, limit: ConstantsUtil.PAGINATION_LIMIT.CHANNEL_TOPIC_FILES, channel_id };
          this.setTopicFiles(topicId, data);
        }
      });
    return this.selectTopicFiles(topicId);
  }

  addFilesToTopicStore(topicId: Topic["id"], allAttachments: any[], extraFilesAdded: number) {
    this.channelRootStore.dispatch(new TopicFilesAdd({ topicId, allAttachments, extraFilesAdded }));
    this.channelRootStore.dispatch(new FilesAdd(allAttachments));

  }

  filesAddedInTopic(files: any[]) {
    this.broadcaster.broadcast(TOPIC_FILES_ADDED, { files });
  }

  fileRemovedFromTopic(fileId, topicId) {
    this.broadcaster.broadcast(TOPIC_FILE_REMOVED, { fileId, topicId });
    setTimeout(() => {
      let topicFilesState = null;
      this.channelRootStore.select(getTopicFiles).pipe(take(1)).subscribe(v => topicFilesState = v);
      if (topicFilesState?.[topicId]?.fileIds.includes(fileId)) {
        const total_count = topicFilesState?.[topicId]?.total_count - 1;
        const offset = topicFilesState?.[topicId]?.offset - 1;
        this.updateTopicFilesPageData(topicId, { offset, total_count });
        this.channelRootStore.dispatch(new TopicFileRemove({ fileId, topicId }));
        this.getTopicById(topicId).pipe(take(1)).subscribe(topic => {
          const updatedAttachments = [...topic.attachments].filter(a => a.id !== fileId);
          let newTopic = { ...topic, attachments: updatedAttachments };
          this.updateTopicIntoStore(newTopic);
        });
      }
    }, 2000);
  }

  loadMoreTopicFiles(topicId: Topic["id"], channel_id?: Topic["channel_id"]): Observable<File[]> {
    this.channelRootStore.select(getTopicFiles)
      .pipe(take(1))
      .subscribe(value => {
        const loadedTopicFiles = value?.[topicId]?.fileIds?.length;
        const totalTopicFiles = value?.[topicId]?.total_count;
        if (loadedTopicFiles < totalTopicFiles) {
          const topicFilesOffset = value?.[topicId]?.offset;
          const data = { offset: topicFilesOffset, limit: ConstantsUtil.PAGINATION_LIMIT.CHANNEL_TOPIC_FILES, channel_id };
          this.setTopicFiles(topicId, data);
        }
      });
    return this.selectTopicFiles(topicId);
  }

  getTopicFilesInfo(topicId: Topic["id"]): Observable<any> {
    return this.channelRootStore.select(getTopicFiles)
      .pipe(map(value => {
        return {
          loading: value?.[topicId]?.isLoading,
          loaded: value?.[topicId]?.isLoaded,
          totalCount: value?.[topicId]?.total_count
        };
      }));
  }


  // ___________ CHANNELS FILES ______________


  getMembersOfTopic(topicId: string, force = false, channel_id?: string): Observable<Member[]> {
    let allTopicMemberState: { [topicId: string]: { members: { id: string, role: string }[], isLoading: boolean, isLoaded: boolean } } = null;
    this.channelRootStore.select(getTopicMembers)
      .pipe(take(1))
      .subscribe(val => allTopicMemberState = val);
    if (allTopicMemberState?.[topicId]?.isLoading !== true && (!allTopicMemberState?.[topicId]?.isLoaded || force)) {
      this.channelRootStore.dispatch(new TopicMembersLoadRequest(topicId));
      const data = { offset: 0, limit: ConstantsUtil.PAGINATION_LIMIT.CHANNEL_TOPIC_MEMBERS, channel_id };
      this.setTopicMembers(topicId, data);
    }
    return this.selectTopicMembers(topicId)
      .pipe(debounceTime(100));
  }


  addMembersToTopic(topicId: string, members: { jid: string, role: string }[]) {
    this.channelService.addTopicMembers(topicId, { ...members })
      .subscribe((async (res: any) => {
        const topicMembersState = await this.channelRootStore.select(getTopicMembers)
          .pipe(take(1))
          .toPromise();
        const membersAdded = (members || []).length;
        const total_count = topicMembersState?.[topicId]?.total_count + membersAdded;
        this.updateTopicMembersPageData(topicId, { total_count });
        this.channelRootStore.dispatch(new TopicMembersAdd({ topicId, members }));
        this.channelRootStore.dispatch(new MembersAdd(res.members.map(k => {
          const copy = { ...k, isNew: true };
          delete copy.role;
          return copy;
        })));
        setTimeout(() => {
          this.channelRootStore.dispatch(new MembersAdd(res.members.map(k => {
            const copy = { ...k, isNew: false };
            // delete copy.role;
            return copy;
          })));
        }, 2000);
      }));
  }


  removeMembersFromTopic(topicId: string, memberIds: string[]) {
    const formattedPayload = {};
    memberIds.forEach((id, index) => {
      formattedPayload[index + ""] = { "jid": id };
    });

    this.channelService.removeTopicMembers(topicId, formattedPayload)
      .subscribe((async () => {
        const topicMembersState = await this.channelRootStore.select(getTopicMembers)
          .pipe(take(1))
          .toPromise();
        const membersRemoved = (memberIds || []).length;
        const total_count = topicMembersState?.[topicId]?.total_count - membersRemoved;
        const offset = topicMembersState?.[topicId]?.offset - membersRemoved;
        this.updateTopicMembersPageData(topicId, { offset, total_count });
        this.channelRootStore.dispatch(new TopicMembersRemove({ topicId, memberIds }));
      }));
  }


  memberAddedInTopic(user, topicId) {
    setTimeout(() => {
      let topicMembersState = null;
      this.channelRootStore.select(getTopicMembers).pipe(take(1)).subscribe(v => topicMembersState = v);
      if (!topicMembersState?.[topicId]?.members.map(v => v.id).includes(user.id)) {
        const total_count = topicMembersState?.[topicId]?.total_count + 1;
        this.updateTopicMembersPageData(topicId, { total_count });
        this.channelRootStore.dispatch(new TopicMembersAdd({ topicId, members: [{ ...user }] }));
        let userData = { ...user, isNew: true };
        delete userData.role;
        delete userData.topic_id;
        this.channelRootStore.dispatch(new MembersAdd([{ ...userData }]));
        setTimeout(() => {
          this.channelRootStore.dispatch(new MembersAdd([{ ...userData, isNew: false }]));
        }, 2000);
      }
    }, 1000);
  }


  memberRemovedFromTopic(userId, topicId) {
    setTimeout(() => {
      let topicMembersState = null;
      this.channelRootStore.select(getTopicMembers).pipe(take(1)).subscribe(v => topicMembersState = v);
      if (topicMembersState?.[topicId]?.members.map(v => v.id).includes(userId)) {
        const total_count = topicMembersState?.[topicId]?.total_count - 1;
        const offset = topicMembersState?.[topicId]?.offset - 1;
        this.updateTopicMembersPageData(topicId, { offset, total_count });
        this.channelRootStore.dispatch(new TopicMembersRemove({ topicId, memberIds: [userId] }));
      }
    }, 1000);
  }


  getTotalTopicMembers(topicId: Topic["id"]): Observable<number> {
    return this.channelRootStore.select(state => {
      // @ts-ignore
      return state.channel?.topic?.members?.[topicId]?.total_count || 0;
    });
  }


  loadMoreTopicMembers(topicId: Topic["id"], channel_id?: string) {
    this.channelRootStore.select(getTopicMembers)
      .pipe(take(1))
      .subscribe((topicMembers: any) => {
        const loadedTopicMembers = topicMembers?.[topicId]?.members?.length;
        const totalTopicMembers = topicMembers?.[topicId]?.total_count;
        if (loadedTopicMembers < totalTopicMembers) {
          const topicMembersOffset = topicMembers?.[topicId]?.offset;
          const data = { offset: topicMembersOffset, limit: ConstantsUtil.PAGINATION_LIMIT.CHANNEL_TOPIC_MEMBERS, channel_id };
          this.setTopicMembers(topicId, data);
        }
      });
  }

 selectChannelMembers(channelId: string): Observable<Member[]> {
    let roleAgainstIdInfo = {};
    return this.channelRootStore.select(getChannelMembers)
      .pipe(switchMap(value => {
        roleAgainstIdInfo = {};
        const memberIds = value?.[channelId]?.members?.map(k => {
          roleAgainstIdInfo[k.id] = k.role;
          return k.id;
        }) as string[];
        return this.channelRootStore.select(state => getCompleteMembers(state))
          .pipe(map(k => k.filter(l => memberIds?.includes(l?.jid))));
      }))
      .pipe(map(x => {
        return x.map(val => {
          return { ...val, role: roleAgainstIdInfo[val.jid] };
        });
      }));
  }


  private setChannelMembers(channelId: Channel["id"], data) {
    let isSocial: boolean = false;
    this.getChannelById(channelId).pipe(filter(v => !!v), take(1)).subscribe(channel => {
      if (channel.is_social) {
        isSocial = true;
      }
    });
    this.channelService.getMembers(channelId, data, isSocial)
      .subscribe((res: any) => {
        let newOffset = (res?.offset || 0) + ConstantsUtil.PAGINATION_LIMIT.CHANNEL_FILES;
        const total_count = res.total_count;
        newOffset = newOffset > total_count ? total_count : newOffset;
        this.updateChannelMemberPageData(channelId, { offset: newOffset });
        this.channelRootStore.dispatch(new ChannelMembersLoadSuccess({ channelId, members: res.members, total_count }));
        this.channelRootStore.dispatch(new MembersAdd(res.members.map(k => {
          const copy = { ...k };
          delete copy.role;
          return copy;
        })));
      });
  }


  private updateChannelMemberPageData(channelId: Channel["id"], data: { offset?: number, total_count?: number }) {
    if (!isNaN(data?.offset)) {
      this.channelRootStore.dispatch(new ChannelMemberOffsetUpdate({ channelId, offset: data.offset }));
    }
    if (!isNaN(data.total_count)) {
      this.channelRootStore.dispatch(new ChannelMembersLoadSuccess({
        channelId,
        members: [],
        total_count: data.total_count
      }));
    }
  }

  readAllTopicComments(topicId: string, channel_id?: string) {
    this.channelService.readAllTopicComments(topicId, channel_id).subscribe((res: any) => {
      if (res && !res.error) {
        this.updateTopicIntoStore({ ...res.topic, unread_comments_count: 0 });
      }
    });
  }


  readTopic(topicId: string, showNotification = true, hasUnreadFilter = false) {
    this.getTopicById(topicId).pipe(take(1)).subscribe();
    this.channelService.readTopic(topicId).subscribe(res => {
      this.updateTopicIntoStore(res.topic);
      hasUnreadFilter ? this.removeTopicFromFilteredTopics(res?.topic?.id, res?.topic?.channel_id) : noop();
      if (showNotification) {
        this.translate.get("TOPIC_MARKED_AS_READ").pipe(take(1)).subscribe(text => {
          const name = res.topic.subject;
          const shortName = name.length > 25 ? name.substring(0, 25) + "..." : name;
          const message = text.replace("XXXX", shortName);
          this.channelSnackBarService.openSnackBar(message, SnackbarType.CHECKMARK);
        });
      }
    });
  }

  removeTopicFromFilteredTopics(topicId: string, channelId: string) {
    this.channelRootStore.select(getFilteredTopicsInfo).pipe(take(1)).subscribe(filtersByChannelId => {
      const oldTopicIds = filtersByChannelId?.[channelId]?.ids;
      const channelFilterData = filtersByChannelId?.[channelId];
      const { isLoading, isLoaded, filterApplied } = channelFilterData;
      const newState = {
        isLoading,
        isLoaded,
        filterApplied,
        channelId,
        ids: oldTopicIds.filter(id => id !== topicId),
        totalCount: oldTopicIds.length - 1,
        offset: oldTopicIds.length - 1
      };
      this.channelRootStore.dispatch(new FilteredTopicsInfoUpdate(newState));
    });
  }

  unreadTopic(topicId: string) {
    this.getTopicById(topicId).pipe(take(1)).subscribe();
    this.channelService.unreadTopic(topicId).subscribe(res => {
      this.updateTopicIntoStore({ ...res.topic, unread_comments_count: res.topic.comments_count });
      this.translate.get("TOPIC_MARKED_AS_UNREAD").pipe(take(1)).subscribe(text => {
        const name = res.topic.subject;
        const shortName = name.length > 25 ? name.substring(0, 25) + "..." : name;
        const message = text.replace("XXXX", shortName);
        this.channelSnackBarService.openSnackBar(message, SnackbarType.CHECKMARK);
      });
    });
  }

  updateChannelCountForCreateTopic(topicId: string) {
    this.getTopicById(topicId).pipe(take(1)).subscribe(
      topic => {
        if (!topic) {
          this.updateChannelTopicCount(topic?.channel_id, 1, 0);
        }
      }
    );
  }

  updateChannelCountForArchiveTopic(topicId: string, channelId: string, changeInTotalCount: number) {
    this.getTopicById(topicId).pipe(take(1)).subscribe(
      topic => {
        if (!!topic) {
          const changeInUnreadCount = !!topic.read ? 0 : -1;
          this.updateChannelTopicCount(topic?.channel_id, changeInTotalCount, changeInUnreadCount);
        } else {
          this.channelRootStore.select(state => getChannelById(state, channelId)).pipe(take(1)).subscribe(
            channel => {
              if (!!channel) {
                this.channelService.getChannelById(channelId)
                  .pipe(map((v: any) => v.channel as Channel))
                  .subscribe(res => {
                    this.updateChannelIntoStore(res);
                  });
              }
            }
          );
        }
      }
    );
  }

  updateChannelCountForUnarchiveTopic(topicId: string, channelId: string, changeInTotalCount: number) {
    this.getTopicById(topicId).pipe(take(1)).subscribe(
      (topic) => {
        if (!topic) {
          const changeInUnreadCount = !!topic?.read ? 0 : -1;
          this.updateChannelTopicCount(topic?.channel_id, changeInTotalCount, changeInUnreadCount);
        }
      }
    );
  }

  updateChannelTopicCount(channelId: string, changeInTotalCount = 0, changeInUnreadCount = 0) {
    this.getChannelById(channelId).pipe(take(1)).subscribe(
      channel => {
        if (!!channel) {
          this.logger.info("[READ_UNREAD] update topic", changeInTotalCount, changeInTotalCount, channel.topics_count);
          let topics_count = channel.topics_count;
          if (changeInTotalCount > 0) {
            topics_count = !!channel.topics_count ? channel.topics_count + changeInTotalCount : 1;
          } else if (changeInTotalCount < 0) {
            topics_count = !!channel.topics_count ? channel.topics_count - 1 : 0;
          }

          let unread_topics_count = channel.unread_topics_count;
          if (changeInUnreadCount > 0) {
            unread_topics_count = !!channel.unread_topics_count ? channel.unread_topics_count + changeInTotalCount : 1;
          } else if (changeInUnreadCount < 0) {
            unread_topics_count = !!channel.unread_topics_count ? channel.unread_topics_count - 1 : 0;
          }
          let updated_on = channel.updated_on;
          this.updateChannelIntoStore({ ...channel, topics_count, unread_topics_count, updated_on });
        }
      }
    );
  }

  updateChannelUnreadTopicCount(channelId: string, unread_topics_count) {
    this.getChannelById(channelId).pipe(take(1)).subscribe(
      channel => {
        if (!!channel) {
          this.updateChannelIntoStore({ ...channel, unread_topics_count });
        }
      }
    );
  }

  updateTopicReadStatus(topicId: string, read) {
    this.getTopicById(topicId).pipe(take(1)).subscribe(
      topic => {
        if (!!topic) {
          this.updateTopicIntoStore({ ...topic, read });
        }
      }
    );
  }

  getMissingKeys() {
    const englishJson$ = this.channelService.getEnglishJson();
    const germanJson$ = this.channelService.getGermanJson();
    const frenchJson$ = this.channelService.getFrenchJson();

    combineLatest([englishJson$, germanJson$, frenchJson$]).
      pipe(map(([englishJson$, germanJson$, frenchJson$]) => ({
        english: englishJson$,
        german: germanJson$,
        french: frenchJson$
      }))).subscribe(
        ({ english, german, french }) => {
          let missingGermanKeys: string[];
          let missingFrenchKeys: string[];
          let missingGerman = {};
          let missingFrench = {};
          let englishKeys;
          let germanKeys;
          let frenchKeys;

          englishKeys = Object.keys(english);
          germanKeys = Object.keys(german);
          frenchKeys = Object.keys(french);

          missingGermanKeys = [...englishKeys].filter(k => !germanKeys.includes(k));
          missingGermanKeys.forEach(key => {
            missingGerman[key] = english[key];
          });
          this.logger.info("[MISSING_KEYS] german", missingGermanKeys);
          this.logger.info("[MISSING_KEYS] german TEXT", missingGerman);

          missingFrenchKeys = [...englishKeys].filter(k => !frenchKeys.includes(k));
          missingFrenchKeys.forEach(key => {
            missingFrench[key] = english[key];
          });
          this.logger.info("[MISSING_KEYS] french", missingFrenchKeys);
          this.logger.info("[MISSING_KEYS] french TEXT", missingFrench);
        }
      );

  }

  private setChannelFiles(channelId: Channel["id"], data) {
    this.channelService.getChannelFiles(channelId, data)
      .subscribe((res: { files: File[], total_count: number, offset: number }) => {
        let newOffset = (res?.offset || 0) + ConstantsUtil.PAGINATION_LIMIT.CHANNEL_FILES;
        const total_count = res.total_count;
        newOffset = newOffset > total_count ? total_count : newOffset;
        this.updateChannelFilesPageData(channelId, { offset: newOffset });
        this.channelRootStore.dispatch(new ChannelFilesLoadSuccess({ channelId, files: res.files, total_count }));
        this.channelRootStore.dispatch(new FilesAdd(res.files));
      });
  }


  // ___________ CHANNELS TOPIC MEMBERS ______________


  private updateChannelFilesPageData(channelId: Channel["id"], data: { offset?: number, total_count?: number }) {
    if (!isNaN(data?.offset)) {
      this.channelRootStore.dispatch(new ChannelFilesOffsetUpdate({ channelId, offset: data.offset }));
    }
    if (!isNaN(data?.total_count)) {
      this.channelRootStore.dispatch(new ChannelFilesLoadSuccess({ channelId, files: [], total_count: data.total_count }));
    }
  }


  private selectChannelFiles(channelId: string): Observable<File[]> { // returns all files of particular channel from store.
    return this.channelRootStore.select(getChannelFiles)
      .pipe(
        switchMap((val: ChannelFiles) => {
          const fileIds = val?.[channelId]?.fileIds || [];
          return this.channelRootStore.select(state => getCompleteFiles(state))
            .pipe(map((files: File[]) => files.filter(file => fileIds?.includes(file.id))));
        })
      );
  }


  private setTopicFiles(topicId: Topic["id"], data) {
    this.channelService.getTopicFiles(topicId, data)
      .subscribe((value?: { files?: File[], total_count?: number, offset?: number, error: any | null }) => {
        if (value && !value.error) {
          let newOffset = (value?.offset || 0) + ConstantsUtil.PAGINATION_LIMIT.CHANNEL_TOPIC_FILES;
          const total_count = value.total_count;
          newOffset = newOffset > total_count ? total_count : newOffset;
          this.updateTopicFilesPageData(topicId, { offset: newOffset });
          this.channelRootStore.dispatch(new TopicFilesLoadSuccess({ topicId, files: value.files, total_count }));
          this.channelRootStore.dispatch(new FilesAdd(value.files));
        }
      });
  }


  private updateTopicFilesPageData(topicId: Topic["id"], data: { offset?: number, total_count?: number }) {
    if (!isNaN(data?.offset)) {
      this.channelRootStore.dispatch(new TopicFilesOffsetUpdate({ topicId, offset: data.offset }));
    }
    if (!isNaN(data.total_count)) {
      this.channelRootStore.dispatch(new TopicFilesLoadSuccess({ topicId, files: [], total_count: data.total_count }));
    }
  }


  private selectTopicFiles(topicId: string): Observable<File[]> { // returns all files of particular topic from store.
    return this.channelRootStore.select(getTopicFiles)
      .pipe(
        switchMap((val: TopicFiles) => {
          const fileIds = val?.[topicId]?.fileIds || [];
          return this.channelRootStore.select(state => getCompleteFiles(state))
            .pipe(map((files: File[]) => files.filter(file => fileIds?.includes(file.id))));
        })
      );
  }


  private setTopicMembers(topicId: Topic["id"], data) {
    this.channelService.getTopicMembers(topicId, data)
      .subscribe((res: any) => {
        let newOffset = (res?.offset || 0) + ConstantsUtil.PAGINATION_LIMIT.CHANNEL_TOPIC_MEMBERS;
        const total_count = res.total_count;
        newOffset = newOffset > total_count ? total_count : newOffset;
        this.updateTopicMembersPageData(topicId, { offset: newOffset });
        this.channelRootStore.dispatch(new TopicMembersLoadSuccess({ topicId, members: res.members, total_count }));
        this.channelRootStore.dispatch(new MembersAdd(res.members.map(k => {
          const copy = { ...k };
          return copy;
        })));
      });
  }


  private updateTopicMembersPageData(topicId: Topic["id"], data: { offset?: number, total_count?: number }) {
    if (!isNaN(data?.offset)) {
      this.channelRootStore.dispatch(new TopicMembersOffsetUpdate({ topicId, offset: data.offset }));
    }
    if (!isNaN(data?.total_count)) {
      this.channelRootStore.dispatch(new TopicMembersLoadSuccess({ topicId, members: [], total_count: data.total_count }));
    }
  }


  private selectTopicMembers(topicId: string): Observable<Member[]> {
    return this.channelRootStore.select(getTopicMembers)
      .pipe(switchMap(value => {
        const memberIds = value?.[topicId]?.members?.map(k => k.id) as string[];
        return this.channelRootStore.select(state => getCompleteMembers(state))
          .pipe(map(k => k.filter(l => memberIds?.includes(l?.jid))));
      }));
  }

  removeRelatedTopic(topicId, ids: any[]) {
    this.channelService.removeRelationToTopic(topicId, ids)
      .subscribe(res => {
        this.updateTopicIntoStore(res.topic);
      }, err => {
        this.logger.info("[removeRelatedTopic] err", err);
        if (err.error) {
          this.channelSnackBarService.openSnackBar(err.error.error, SnackbarType.CLOSE);
        }
      });
  }

  getHeroThumbnailUrl(item) {
    let url = "";
    if (item && item.attachments) {
      let heroHeader = item.attachments.filter(attach => attach.is_header === true);
      if (heroHeader && heroHeader.length > 0) {
        if (heroHeader[0].thumbnail_url) {
          url = item?.is_iom ? heroHeader[0].thumbnail_url : CommonUtil.getAttachmentLocalAPIURL(heroHeader[0].thumbnail_url);
        }
        return url;
      }
      return url;
    }
    return url;
  }

  deleteFile(fileId: number, topicId, channelId): Observable<any> {
    return this.channelService.deleteFile(fileId)
      .pipe(tap(async () => {
        const channelFilesState = await this.channelRootStore.select(getChannelFiles)
          .pipe(take(1))
          .toPromise();
        if (!!channelFilesState) {
          const total_count = channelFilesState?.[channelId]?.total_count - 1;
          const offset = channelFilesState?.[channelId]?.offset - 1;
          this.updateChannelFilesPageData(channelId, { offset, total_count });
          this.channelRootStore.dispatch(new ChannelFilesRemove({ fileId, channelId }));
        }
        this.getTopicById(topicId).pipe(take(1)).subscribe(topic => {
          const updatedAttachments = [...topic.attachments].filter(a => a.id !== fileId);
          let newTopic = { ...topic, attachments: updatedAttachments };
          this.updateTopicIntoStore(newTopic);
        });
      }));
  }

  deleteTopicFile(fileId: number, topicId): Observable<any> {
    localStorage.setItem("toDelete", JSON.stringify(fileId));
    return this.channelService.deleteFile(fileId)
      .pipe(tap(async () => {
        const topicFilesState = await this.channelRootStore.select(getTopicFiles)
          .pipe(take(1))
          .toPromise();
        const total_count = topicFilesState?.[topicId]?.total_count - 1;
        const offset = topicFilesState?.[topicId]?.offset - 1;
        this.updateTopicFilesPageData(topicId, { offset, total_count });
        this.channelRootStore.dispatch(new TopicFileRemove({ fileId, topicId }));
        this.getTopicById(topicId).pipe(take(1)).subscribe(topic => {
          const updatedAttachments = [...topic?.attachments].filter(a => a.id !== fileId);
          let newTopic = { ...topic, attachments: updatedAttachments };
          this.updateTopicIntoStore(newTopic);
        });
      }));
  }

  createInvitations(userJids: string[], channelId: string, topicId?: string): Observable<{ invitations: Invitation[] }> {
    return this.channelService.createInvitations(userJids, channelId, topicId);
  }

  getInvitationDetails(invitationToken: string): Observable<{ invitation: Invitation }> {
    return this.channelService.getInvitationDetails(invitationToken);
  }

  accessRequest(channelId: string, topicId?: string): Observable<{ invitation: Invitation }> {
    const userJids: string[] = [];
    this.getChannelUserJid().pipe(take(1)).subscribe();
    this.getChannelUserJid().pipe(take(1)).subscribe(jid => {
      userJids.push(jid);
    });
    return this.channelService.accessRequest(userJids, channelId, topicId).pipe(
      tap(() => {
        this.translate.get("ACCESS_REQUEST_SENT").pipe(take(1)).subscribe(text => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
        });
      })
    );
  }

  acceptInvitations(invitationToken: string): Observable<{ invitation: Invitation }> {
    return this.channelService.acceptInvitations(invitationToken);
  }


  renameFile(fileName: string, fileId: string): Observable<File> {
    return this.channelService.renameFile(fileId, fileName)
      .pipe(tap(res => {
        const file = res.files[0];
        this.channelRootStore.dispatch(new FilesEdit(file));
      }));
  }

  approveInvitations(invitationToken: string): Observable<{ invitation: Invitation }> {
    return this.channelService.approveInvitations(invitationToken);
  }

  declineInvitations(invitationToken: string): Observable<{ invitation: Invitation }> {
    return this.channelService.declineInvitations(invitationToken);
  }

  deleteInvitations(invitationToken: string): Observable<any> {
    return this.channelService.deleteInvitations(invitationToken);
  }

  getReceivedInvitations(channelId?: string, topicId?: string, offset?: number, limit?: number): Observable<{ invitations: Invitation[] }> {
    return this.channelService.getReceivedInvitations(channelId, topicId, offset, limit);
  }

  getSentInvitations(channelId: string, topicId?: string, offset?: number, limit?: number): Observable<{ invitations: Invitation[] }> {
    return this.channelService.getSentInvitations(channelId, topicId, offset, limit);
  }

  moveTopic(topic: Topic, destinationChannel: Channel, previousChannelId: string) {
    this.updateChannelIntoStore({
      ...destinationChannel,
      topics_count: destinationChannel.topics_count - 1,
      unread_topics_count: topic.read ? destinationChannel.unread_topics_count - 1 : destinationChannel.unread_topics_count
    });
    this.getChannelById(previousChannelId).pipe(take(1)).subscribe(
      channel => {
        this.updateChannelIntoStore({
          ...channel,
          topics_count: channel.topics_count - 1,
          unread_topics_count: topic.read ? channel.unread_topics_count : channel.unread_topics_count - 1
        });
      }
    );
    this.updateChannelIntoStore({
      ...destinationChannel,
      topics_count: destinationChannel.topics_count + 1,
      unread_topics_count: topic.read ? destinationChannel.unread_topics_count : destinationChannel.unread_topics_count + 1
    });
  }

  addCommentIntoStore(comment) {
    if (!!comment?.parent_comment_id) {
      this.channelRootStore.dispatch(new CommentAdd(comment));
    } else {
      this.channelRootStore.select(state => getParentCommentInTopicById(state, comment?.parent_comment_id, comment?.topic_id)).pipe(take(1)).subscribe(c => {
        this.logger.info("[ChannelRepository] getParentCommentInTopicById", c);
        if (!!c) {
          if (c.id === comment?.parent_comment_id) {
            let replies = c.replies || [];
            replies = replies.filter(replay => replay.id !== comment.id) || [];
            replies.push(comment);
            this.channelRootStore.dispatch(new CommentUpdate({ id: comment?.parent_comment_id, replies_count: replies.length, replies: replies }));
          } else {
            c.replies.forEach(c1 => {
              if (c1.id === comment?.parent_comment_id) {
                let replies = c1.replies || [];
                replies = replies.filter(replay => replay.id !== comment.id) || [];
                replies.push(comment);
                c1.replies = replies;
                c1.replies_count = replies.length;
              }
            });
            this.channelRootStore.dispatch(new CommentUpdate({ id: c.id, replies: c.replies }));
          }
        }
      });
    }
  }

  updateCommentIntoStore(comment) {
    if (!comment?.parent_comment_id) {
      this.channelRootStore.dispatch(new CommentUpdate(comment));
    } else {
      this.channelRootStore.select(state => getParentCommentInTopicById(state, comment?.parent_comment_id, comment?.topic_id)).pipe(take(1)).subscribe(c => {
        this.logger.info("[ChannelRepository] getParentCommentInTopicById", c);
        if (!!c) {
          if (c.id === comment?.parent_comment_id) {
            let replies = c.replies || [];
            replies = replies.map(oldComment => {
              if (oldComment.id === comment.id) {
                return { ...oldComment, ...comment };
              }
              return oldComment;
            });
            this.logger.info("[ChannelRepository] CommentUpdate", replies);
            this.channelRootStore.dispatch(new CommentUpdate({ id: comment?.parent_comment_id, replies: replies }));
          }
          else {
            c.replies.forEach(c1 => {
              if (c1.id === comment?.parent_comment_id) {
                let replies = c1.replies || [];
                replies = replies.map(oldComment => {
                  if (oldComment.id === comment.id) {
                    return comment;
                  }
                  return oldComment;
                });
                c1.replies = replies;
              }
            });
            this.logger.info("[ChannelRepository] CommentUpdate", c.replies);
            this.channelRootStore.dispatch(new CommentUpdate({ id: comment?.parent_comment_id, replies: c.replies }));
          }
        }
      });
    }
  }

  removeCommentIntoStore(commentId: number, parentId?: number, topic_id?: number) {
    if (!parentId) {
      this.channelRootStore.dispatch(new CommentDelete(commentId));
    } else {
      this.channelRootStore.select(state => getParentCommentInTopicById(state, parentId, topic_id)).pipe(take(1)).subscribe(c => {
        this.logger.info("[CommentsComponent] getParentCommentInTopicById", c);
        if (!!c) {
          if (c.id === parentId) {
            const replies = c.replies || [];
            let replyFilter = replies.filter(v => v.id !== commentId);
            this.channelRootStore.dispatch(new CommentUpdate({ id: parentId, replies_count: replyFilter.length, replies: replyFilter }));
          } else {
            c.replies.forEach(c1 => {
              if (c1.id === parentId && c1.replies) {
                c1.replies = c1.replies.filter(v => v.id !== commentId);
                c1.replies_count = c1?.replies?.length;
              }
            });
            this.channelRootStore.dispatch(new CommentUpdate({ id: parentId, replies: c.replies }));
          }
        }
      });
    }
  }

  getAllNotifications() {
    const data = this.buildDataObject(0, 0, [], true, false);
    this.channelRootStore.dispatch(new NotificationInfoUpdate(data));
    this.channelService.getNotifications(0, null)
      .subscribe(res => {
        let notifications: Notification[] = res.notifications;
        const data = {
          totalCount: res.total_count,
          offset: res.notifications.length,
          ids: this.extractIdsFromNotifications(notifications),
          isLoading: false,
          isLoaded: true
        };
        notifications = notifications.map(notification => {
          const defaultImageIndex = Math.floor(Math.random() * 12);
          return { ...notification, defaultImageIndex };
        });
        this.channelRootStore.dispatch(new NotificationBulkAdd(notifications));
        this.channelRootStore.dispatch(new NotificationInfoUpdate(data));
      });
  }

  getUnreadNotifications() {
    const data = this.buildDataObject(0, 0, [], true, false);
    this.channelRootStore.dispatch(new UreadNotificationInfoUpdate(data));
    this.channelService.getNotifications(0, null, true)
      .subscribe(res => {
        let notifications: Notification[] = res.notifications;
        const data = {
          totalCount: res.total_count,
          offset: res.notifications.length,
          ids: this.extractIdsFromNotifications(notifications),
          isLoading: false,
          isLoaded: true
        };
        notifications = notifications.map(notification => {
          const defaultImageIndex = Math.floor(Math.random() * 12);
          return { ...notification, defaultImageIndex };
        });
        this.channelRootStore.dispatch(new NotificationBulkAdd(notifications));
        this.channelRootStore.dispatch(new UreadNotificationInfoUpdate(data));
        this.channelRootStore.dispatch(new UnreadNotificationCountUpdate(res.total_count));
      });
  }

  getMoreNotifications(offset: number, limit?: number) {
    let totalCount = 0;
    let previousIds = [];
    let previousOffset = 0;
    this.channelRootStore.select(getNotificationInfo).pipe(take(1))
      .subscribe(info => {
        totalCount = info.totalCount;
        previousIds = info.ids;
        previousOffset = info.offset;
      });

    if (totalCount > offset) {
      const data = this.buildDataObject(totalCount, previousOffset, previousIds, true, false);
      this.channelRootStore.dispatch(new NotificationInfoUpdate(data));
      this.channelService.getNotifications(offset, limit)
        .subscribe(res => {
          let notifications: Notification[] = res.notifications;
          const data = {
            totalCount: res.total_count,
            offset: offset + res.notifications.length,
            ids: [...previousIds, ...this.extractIdsFromNotifications(notifications, previousIds)],
            isLoading: false,
            isLoaded: true
          };
          notifications = notifications.map(notification => {
            const defaultImageIndex = Math.floor(Math.random() * 12);
            return { ...notification, defaultImageIndex };
          });
          this.channelRootStore.dispatch(new NotificationBulkAdd(notifications));
          this.channelRootStore.dispatch(new NotificationInfoUpdate(data));
        });
    }
  }

  getMoreUnreadNotifications(offset: number, limit?: number) {
    let totalCount = 0;
    let previousIds = [];
    let previousOffset = 0;
    this.channelRootStore.select(getUnreadNotificationInfo).pipe(take(1))
      .subscribe(info => {
        totalCount = info.totalCount;
        previousIds = info.ids;
        previousOffset = info.offset;
      });

    if (totalCount > offset) {
      const data = this.buildDataObject(totalCount, previousOffset, previousIds, true, false);
      this.channelRootStore.dispatch(new UreadNotificationInfoUpdate(data));
      this.channelService.getNotifications(offset, limit, true)
        .subscribe(res => {
          let notifications: Notification[] = res.notifications;
          const data = {
            totalCount: res.total_count,
            offset: offset + res.notifications.length,
            ids: [...previousIds, ...this.extractIdsFromNotifications(notifications, previousIds)],
            isLoading: false,
            isLoaded: true
          };
          notifications = notifications.map(notification => {
            const defaultImageIndex = Math.floor(Math.random() * 12);
            return { ...notification, defaultImageIndex };
          });
          this.channelRootStore.dispatch(new NotificationBulkAdd(notifications));
          this.channelRootStore.dispatch(new UreadNotificationInfoUpdate(data));
        });
    }
  }

  getUnreadNotificationCount() {
    this.channelService.getUnreadNotificationCount()
      .subscribe(res => {
        const count = res.total_count;
        this.channelRootStore.dispatch(new UnreadNotificationCountUpdate(count));
      });
  }

  deleteAllChannelNotifications(channelId: string) {
    const notificationsToDelete = [];
    this.channelRootStore.select(getAllNotificationList).pipe(take(1)).subscribe(
      notifications => {
        if (!!notifications && notifications.length > 0) {
          [...notifications].forEach(n => {
            if (!!n.data && (
              (!!n.data.channel_id && n.data.channel_id === channelId) ||
              (!!n.data.channel && n.data.channel.id === channelId) ||
              (!!n.data.topic && n.data.topic.channel_id === channelId) ||
              (!!n.data.comment && n.data.comment.channel_id === channelId)
            )) {
              notificationsToDelete.push(n.id);
            }
          });
        }
      }
    );
    this.channelRootStore.dispatch(new NotificationBulkDelete(notificationsToDelete));
    this.updateNotificationInfoForDeletedNotifications(notificationsToDelete);
  }

  deleteAllTopicNotifications(topicId: string) {
    const notificationsToDelete = [];
    this.channelRootStore.select(getAllNotificationList).pipe(take(1)).subscribe(
      notifications => {
        if (!!notifications && notifications.length > 0) {
          [...notifications].forEach(n => {
            if (!!n.data && (
              (!!n.data.topic_id && n.data.topic_id === topicId) ||
              (!!n.data.topic && n.data.topic.id === topicId) ||
              (!!n.data.comment && n.data.comment.topic_id === topicId)
            )) {
              notificationsToDelete.push(n.id);
            }
          });
        }
      }
    );
    this.channelRootStore.dispatch(new NotificationBulkDelete(notificationsToDelete));
    this.updateNotificationInfoForDeletedNotifications(notificationsToDelete);
  }

  deleteAllCommentNotifications(commentId: string, parentCommentId: string) {
    const notificationsToDelete = [];
    let pCommentId = [];
    this.channelRootStore.select(getAllNotificationList).pipe(take(1)).subscribe(
      notifications => {
        if (!!notifications && notifications.length > 0) {
          [...notifications].forEach(n => {
            if (!!n.data && (
              (!!n.data.comment_id && n.data.comment_id === commentId) ||
              (!!n.data.comment && n.data.comment.id === commentId)
            )) {
              notificationsToDelete.push(n.id);
            }
            if (!!n.data && (!!n.data.comment && n?.data?.comment?.parent_comment_id && n?.data?.comment?.parent_comment_id === commentId)) {
              notificationsToDelete.push(n.id);
              if (!parentCommentId) {
                pCommentId.push(n?.data?.comment?.id);
              }
            }
          });
          if (!parentCommentId) {
            [...notifications].forEach(n => {
              pCommentId.forEach(commentId => {
                if (!!n.data && (!!n.data.comment && n?.data?.comment?.parent_comment_id && n?.data?.comment?.parent_comment_id === commentId)) {
                  notificationsToDelete.push(n.id);
                }
              });
            });
          }
        }
      }
    );
    this.channelRootStore.dispatch(new NotificationBulkDelete(notificationsToDelete));
    this.updateNotificationInfoForDeletedNotifications(notificationsToDelete);
  }

  updateNotificationInfoForDeletedNotifications(notificationsToDelete: number[] = []) {
    let notificationInfo = null;
    let unreadNotificationInfo = null;
    this.channelRootStore.select(getUnreadNotificationInfo).pipe(take(1)).subscribe(info => {
      unreadNotificationInfo = info;
    });
    this.channelRootStore.select(getNotificationInfo).pipe(take(1)).subscribe(info => {
      notificationInfo = info;
    });

    if (notificationsToDelete.length > 0) {
      notificationInfo = {
        ...notificationInfo,
        totalCount: notificationInfo.totalCount - notificationsToDelete.length,
        ids: notificationInfo.ids.filter(id => !notificationsToDelete.includes(id)),
        offset: notificationInfo.offset - notificationsToDelete.length,
      };

      unreadNotificationInfo = {
        ...unreadNotificationInfo,
        totalCount: unreadNotificationInfo.totalCount - notificationsToDelete.length,
        ids: unreadNotificationInfo.ids.filter(id => !notificationsToDelete.includes(id)),
        offset: unreadNotificationInfo.offset - notificationsToDelete.length,
      };
      this.channelRootStore.dispatch(new UreadNotificationInfoUpdate(unreadNotificationInfo));
      this.channelRootStore.dispatch(new NotificationInfoUpdate(notificationInfo));
    }
  }

  extractIdsFromNotifications(notifications: Notification[], previousIds?: string[]) {
    let ids = [];
    if (previousIds && previousIds.length > 0) {
      notifications.forEach(notification => !previousIds.includes(notification.id) && ids.push(notification.id));
    } else {
      notifications.forEach(notification => ids.push(notification.id));
    }
    return ids;
  }

  selectAllNotifications(): Observable<Notification[]> {
    let allNotifications = [];
    const $allNotificationInfoOb = this.channelRootStore.select(getNotificationInfo);
    const $allAvailableNotificationOb = this.channelRootStore.select(getAllNotificationList);
    return combineLatest([$allNotificationInfoOb, $allAvailableNotificationOb])
      .pipe(map((value: [{ totalCount: number, offset: number, ids: string[] }, Notification[]]) => {
        const ids = value?.[0]?.ids;
        const allAvailableNotifications = value?.[1];
        allNotifications = allAvailableNotifications.filter(v => ids.includes(v.id));
        return allNotifications;
      }));
  }

  selectUnreadNotifications(): Observable<Notification[]> {
    let unreadNotifications = [];
    const $unreadNotficationInfoOb = this.channelRootStore.select(getUnreadNotificationInfo);
    const $allAvailableNotificationOb = this.channelRootStore.select(getUnreadNotifications);
    return combineLatest([$unreadNotficationInfoOb, $allAvailableNotificationOb])
      .pipe(map((value: [{ totalCount: number, offset: number, ids: string[] }, Notification[]]) => {
        const ids = value?.[0]?.ids;
        const allAvailableNotifications = value?.[1];
        unreadNotifications = allAvailableNotifications.filter(v => ids.includes(v.id));
        return unreadNotifications;
      }));
  }

  getPhotoLastUpdateTimeStamp(bare) {
    let timeStamp = -1;
    this.store.select(state => getLastPhotoUpdate(state, bare)).pipe(take(1)).subscribe(photoLastUpdate => {
      timeStamp = photoLastUpdate;
    });
    return `${timeStamp > -1 ? timeStamp : Math.abs(new Date().getTime())}`;
  }

  getLatestAvatarUrl(bare, avatarUrl) {
    let newAvatar = avatarUrl;
    const lastUpdateTimeStamp = this.getPhotoLastUpdateTimeStamp(bare);
    if (lastUpdateTimeStamp) {
      newAvatar = newAvatar.split("?ver")?.[0] + "?ver=" + lastUpdateTimeStamp;
    }
    return newAvatar;
  }

  async getNotificationCenterItem(notification: Notification) {
    if (notification) {
      if (notification.notification_type && (
        notification.notification_type === NotificationStatus.CHANNEL_CREATED ||
        notification.notification_type === NotificationStatus.PRIVATE_CHANNEL_INVITE ||
        notification.notification_type === NotificationStatus.CHANNEL_ACCESS_REQUEST ||
        notification.notification_type === NotificationStatus.CHANNEL_ACCESS_GRANTED)) {
        if (notification?.data?.channel) {
          let title;
          let description;
          let type;
          let isSocial: boolean = false;
          if (notification.notification_type === NotificationStatus.CHANNEL_CREATED) {
            title = await this.translate.get("NEW_CHANNEL_TITLE").pipe(take(1)).toPromise();
            description = await this.translate.get("NEW_CHANNEL_DESCRIPTION", { author_name: notification?.actor?.name, channel_name: notification?.data?.channel?.name }).pipe(take(1)).toPromise();
            type = NotificationCenterItemType.CHANNEL_NEW;
          } else if (notification.notification_type === NotificationStatus.PRIVATE_CHANNEL_INVITE) {
            title = await this.translate.get("PRIVATE_CHANNEL_INVITE").pipe(take(1)).toPromise();
            description = await this.translate.get("PRIVATE_CHANNEL_INVITE_DESCRIPTION", { channel_name: CommonUtil.truncateTextWithElipses(notification?.data?.channel?.name, this.notificationTruncateCharLength), author_name: notification?.actor?.name }).pipe(take(1)).toPromise();
            type = NotificationCenterItemType.CHANNEL_INVITE_PRIVATE;
          } else if (notification.notification_type === NotificationStatus.CHANNEL_ACCESS_REQUEST) {
            title = await this.translate.get("CHANNEL_ACCESS_REQUEST").pipe(take(1)).toPromise();
            description = await this.translate.get("CHANNEL_ACCESS_REQUEST_DESCRIPTION", { actor_name: notification?.actor?.name, channel_name: CommonUtil.truncateTextWithElipses(notification?.data?.channel?.name, this.notificationTruncateCharLength), author_name: notification?.data?.channel?.author?.name }).pipe(take(1)).toPromise();
            type = NotificationCenterItemType.CHANNEL_ACCESS_REQUEST;
          } else if (notification.notification_type === NotificationStatus.CHANNEL_ACCESS_GRANTED) {
            title = await this.translate.get("CHANNEL_ACCESS_GRANTED").pipe(take(1)).toPromise();
            description = await this.translate.get("CHANNEL_ACCESS_GRANTED_DESCRIPTION", { channel_name: CommonUtil.truncateTextWithElipses(notification?.data?.channel?.name, this.notificationTruncateCharLength), recepient_name: notification?.recepient?.name }).pipe(take(1)).toPromise();
            type = NotificationCenterItemType.CHANNEL_ACCESS_GRANTED;
          }
          let item: NotificationCenterItem = {
            type: type,
            object: notification,
            description: description,
            timing: notification?.created_at,
            imgSrc: notification?.data?.channel?.avatar_url,
            authorAvatar: this.getLatestAvatarUrl(notification?.actor?.jid, notification?.actor?.avatar_url),
            title: title,
            isPrivate: notification?.data?.channel?.is_public ? false : true,
            unread: notification?.read,
            defaultImageIndex: notification?.defaultImageIndex,
            isSocial: isSocial
          };
          return item;
        }
      }
      else if (notification.notification_type && (
        notification.notification_type === NotificationStatus.TOPIC_CREATED ||
        notification.notification_type === NotificationStatus.PRIVATE_TOPIC_INVITE ||
        notification.notification_type === NotificationStatus.TOPIC_ACCESS_REQUEST ||
        notification.notification_type === NotificationStatus.TOPIC_ACCESS_GRANTED)) {
        if (notification?.data?.topic) {
          let title;
          let description;
          let type;
          let isSocial: boolean = false;
          if (notification.notification_type === NotificationStatus.TOPIC_CREATED) {
            title = await this.translate.get("NEW_TOPIC_TITLE").pipe(take(1)).toPromise();
            if (notification?.data?.topic?.is_social) {
              description = await this.translate.get("NEW_SOCIAL_TOPIC_DESCRIPTION", { author_name: notification?.actor?.name, channel_name: notification?.data?.topic?.channel_name }).pipe(take(1)).toPromise();
              isSocial = true;
            } else {
              description = await this.translate.get("NEW_TOPIC_DESCRIPTION", { author_name: notification?.actor?.name, topic_subject: CommonUtil.truncateTextWithElipses(notification?.data?.topic?.subject, this.notificationTruncateCharLength), channel_name: notification?.data?.topic?.channel_name }).pipe(take(1)).toPromise();
            }
            type = NotificationCenterItemType.TOPIC_NEW;
          } else if (notification.notification_type === NotificationStatus.PRIVATE_TOPIC_INVITE) {
            title = await this.translate.get("PRIVATE_TOPIC_INVITE").pipe(take(1)).toPromise();
            description = await this.translate.get("PRIVATE_TOPIC_INVITE_DESCRIPTION", { topic_subject: CommonUtil.truncateTextWithElipses(notification?.data?.topic?.subject, this.notificationTruncateCharLength), channel_name: CommonUtil.truncateTextWithElipses(notification?.data?.topic?.channel_name, this.notificationTruncateCharLength), author_name: notification?.actor?.name }).pipe(take(1)).toPromise();
            type = NotificationCenterItemType.TOPIC_INVITE_PRIVATE;
          } else if (notification.notification_type === NotificationStatus.TOPIC_ACCESS_REQUEST) {
            title = await this.translate.get("TOPIC_ACCESS_REQUEST").pipe(take(1)).toPromise();
            description = await this.translate.get("TOPIC_ACCESS_REQUEST_DESCRIPTION", { actor_name: notification?.actor?.name, topic_subject: CommonUtil.truncateTextWithElipses(notification?.data?.topic?.subject, this.notificationTruncateCharLength), author_name: notification?.data?.topic?.author?.name }).pipe(take(1)).toPromise();
            type = NotificationCenterItemType.TOPIC_ACCESS_REQUEST;
          } else if (notification.notification_type === NotificationStatus.TOPIC_ACCESS_GRANTED) {
            title = await this.translate.get("TOPIC_ACCESS_GRANTED").pipe(take(1)).toPromise();
            description = await this.translate.get("TOPIC_ACCESS_GRANTED_DESCRIPTION", { topic_subject: CommonUtil.truncateTextWithElipses(notification?.data?.topic?.subject, this.notificationTruncateCharLength), recepient_name: notification?.recepient?.name }).pipe(take(1)).toPromise();
            type = NotificationCenterItemType.TOPIC_ACCESS_GRANTED;
          }
          let item: NotificationCenterItem = {
            type: type,
            object: notification,
            description: description,
            timing: notification?.created_at,
            imgSrc: this.getHeroThumbnailUrl(notification?.data?.topic),
            authorAvatar: this.getLatestAvatarUrl(notification?.actor?.jid, notification?.actor?.avatar_url),
            title: title,
            isPrivate: notification?.data?.topic?.is_public ? false : true,
            unread: notification?.read,
            defaultImageIndex: notification?.defaultImageIndex,
            isSocial: isSocial
          };
          return item;
        }
      }
      else if (notification.notification_type && (
        notification.notification_type === NotificationStatus.COMMENT_CREATED ||
        notification.notification_type === NotificationStatus.COMMENT_CREATED_MENTION ||
        notification.notification_type === NotificationStatus.COMMENT_CREATED_REPLY)) {
        if (notification?.data?.comment) {
          let title;
          let description;
          let type;
          let isSocial: boolean = false;
          if (notification.notification_type === NotificationStatus.COMMENT_CREATED) {
            title = await this.translate.get("NEW_COMMENT_TITLE").pipe(take(1)).toPromise();
            if (notification?.data?.comment?.topic_is_social) {
              description = await this.translate.get("NEW_SOCIAL_COMMENT_DESCRIPTION", { author_name: notification?.actor?.name, topic_subject: notification?.data?.comment?.topic_subject, comment_author: notification?.data?.comment?.topic_author?.name }).pipe(take(1)).toPromise();
              isSocial = true;
            } else {
              description = await this.translate.get("NEW_COMMENT_DESCRIPTION", { author_name: notification?.actor?.name, topic_subject: notification?.data?.comment?.topic_subject }).pipe(take(1)).toPromise();
            }
            type = NotificationCenterItemType.TOPIC_NEW_COMMENT;
          } else if (notification.notification_type === NotificationStatus.COMMENT_CREATED_REPLY) {
            title = await this.translate.get("NEW_COMMENT_REPLAY_TITLE").pipe(take(1)).toPromise();
            description = await this.translate.get("NEW_COMMENT_REPLAY_DESCRIPTION", { author_name: notification?.actor?.name, topic_subject: notification?.data?.comment?.topic_subject }).pipe(take(1)).toPromise();
            type = NotificationCenterItemType.TOPIC_COMMENT_REPLY;
          } else if (notification.notification_type === NotificationStatus.COMMENT_CREATED_MENTION) {
            title = await this.translate.get("NEW_MENTION_TITLE").pipe(take(1)).toPromise();
            if (notification?.data?.comment?.topic_is_social) {
              description = await this.translate.get("NEW_SOCIAL_MENTION_DESCRIPTION", { topic_subject: CommonUtil.truncateTextWithElipses(notification?.data?.comment?.topic_subject, this.notificationTruncateCharLength), author_name: notification?.data?.comment?.topic_author?.name }).pipe(take(1)).toPromise();
              isSocial = true;
            } else {
              description = await this.translate.get("NEW_MENTION_DESCRIPTION", { topic_subject: CommonUtil.truncateTextWithElipses(notification?.data?.comment?.topic_subject, this.notificationTruncateCharLength), actor_name: notification?.actor?.name, }).pipe(take(1)).toPromise();
            }
            type = NotificationCenterItemType.TOPIC_MENTION;
          }
          let item: NotificationCenterItem = {
            type: type,
            object: notification,
            description: description,
            timing: notification?.created_at,
            imgSrc: CommonUtil.getAttachmentLocalAPIURL(notification?.data.comment?.avatar_url),
            authorAvatar: this.getLatestAvatarUrl(notification?.actor?.jid, notification?.actor?.avatar_url),
            title: title,
            unread: notification?.read,
            isPrivate: false,
            defaultImageIndex: notification?.defaultImageIndex,
            isSocial: isSocial
          };
          return item;
        }
      } else if (this.configService.get("isSocialEnabled") && notification.notification_type &&
        (notification.notification_type === NotificationStatus.SOCIAL_POST_CREATED ||
          notification.notification_type === NotificationStatus.SOCIAL_POST_REPLY_CREATED ||
          notification.notification_type === NotificationStatus.SOCIAL_POST_REPOST_CREATED)) {
        if (notification?.data?.post) {
          let title;
          let description;
          let type;
          let isSocial: boolean = false;
          if (notification.notification_type === NotificationStatus.SOCIAL_POST_CREATED) {
            title = await this.translate.get("NEW_POST").pipe(take(1)).toPromise();
            if (notification?.data?.post) {
              description = await this.translate.get("NEW_SOCIAL_POST_DESCRIPTION", { author_name: notification?.actor?.name }).pipe(take(1)).toPromise();
              isSocial = true;
            }
            type = NotificationCenterItemType.TOPIC_NEW;
          } else if (notification.notification_type === NotificationStatus.SOCIAL_POST_REPLY_CREATED) {
            title = await this.translate.get("NEW_REPLY").pipe(take(1)).toPromise();
            description = await this.translate.get("NEW_SOCIAL_POST_REPLY_DESCRIPTION", { author_name: notification?.actor?.name, comment_author: notification?.data?.post?.parent_post?.author?.name }).pipe(take(1)).toPromise();
            isSocial = true;
            type = NotificationCenterItemType.TOPIC_NEW_COMMENT;
          } else if (notification.notification_type === NotificationStatus.SOCIAL_POST_REPOST_CREATED) {
            title = await this.translate.get("NEW_REPOST").pipe(take(1)).toPromise();
            if (notification?.data?.post) {
              description = await this.translate.get("NEW_SOCIAL_REPOST_DESCRIPTION", { author_name: notification?.actor?.name }).pipe(take(1)).toPromise();
              isSocial = true;
            }
            type = NotificationCenterItemType.TOPIC_NEW;
          }
          let item: NotificationCenterItem = {
            type: type,
            object: notification,
            description: description,
            timing: notification?.created_at,
            imgSrc: "",
            authorAvatar: this.getLatestAvatarUrl(notification?.actor?.jid, notification?.actor?.avatar_url),
            title: title,
            isPrivate: false,
            unread: notification?.read,
            defaultImageIndex: 0,
            isSocial: isSocial
          };
          return item;
        }
      }
    }
    return null;
  }

  markAsReadNotification(notification: any) {
    if(notification?.id && notification) {
      this.channelService.markAsReadNotification(notification.id)
      .subscribe(res => {
        if (res?.notification) {
          this.channelRootStore.dispatch(new NotificationUpdate(res?.notification));
          this.getUnreadNotificationCount();
        }
      }, err => {
        this.logger.info("[markAsReadNotification] err", err);
      });
    }
  }

  redirectToObjectfromNotification(notification) {
    this.logger.info("[redirectToObjectfromNotification][notification]: ", notification);
    if (notification) {
      if (notification.notification_type && (
        notification.notification_type === NotificationStatus.CHANNEL_CREATED ||
        notification.notification_type === NotificationStatus.PRIVATE_CHANNEL_INVITE ||
        notification.notification_type === NotificationStatus.CHANNEL_ACCESS_GRANTED ||
        notification.notification_type === NotificationStatus.CHANNEL_ACCESS_REQUEST)) {
        if (notification?.data?.channel) {
          this.router.navigateByUrl(`/talk/channels/${notification?.data?.channel?.id}`);
          if (notification.notification_type === NotificationStatus.CHANNEL_ACCESS_REQUEST) {
            this.getSelectedChannelId()
              .pipe(take(1)).subscribe(id => {
                if (id !== null && id === notification?.data?.channel?.id) {
                  this.openSideBar("channel", notification?.data?.channel?.id, "Users");
                } else {
                  this.router.events
                    .pipe(filter(e => e instanceof NavigationEnd), take(1))
                    .subscribe(() => {
                      this.openSideBar("channel", notification?.data?.channel?.id, "Users");
                    });
                }
              });
          }
        }
      }
      else if (notification.notification_type && (
        notification.notification_type === NotificationStatus.TOPIC_CREATED ||
        notification.notification_type === NotificationStatus.PRIVATE_TOPIC_INVITE ||
        notification.notification_type === NotificationStatus.TOPIC_ACCESS_GRANTED ||
        notification.notification_type === NotificationStatus.TOPIC_ACCESS_REQUEST)) {
        if (notification?.data?.topic) {
          if (notification?.data?.topic?.is_social) {
            this.router.navigateByUrl(`/talk/channels/${notification?.data?.topic?.channel_id}/socialTopic/${notification?.data?.topic?.id}`);
          } else {
            this.router.navigateByUrl(`/talk/channels/${notification?.data?.topic?.channel_id}/topics/${notification?.data?.topic?.id}`);
          }
          if (notification.notification_type === NotificationStatus.TOPIC_ACCESS_REQUEST) {
            this.getSelectedTopicId()
              .pipe(take(1)).subscribe(id => {
                if (id !== null && id === notification?.data?.topic?.id) {
                  this.openSideBar("topic", notification?.data?.topic?.id, "Users");
                } else {
                  this.router.events
                    .pipe(filter(e => e instanceof NavigationEnd), take(1))
                    .subscribe(() => {
                      this.openSideBar("topic", notification?.data?.topic?.id, "Users");
                    });
                }
              });
          }
        }
      }
      else if (notification.notification_type && (
        notification.notification_type === NotificationStatus.COMMENT_CREATED ||
        notification.notification_type === NotificationStatus.COMMENT_CREATED_REPLY ||
        notification.notification_type === NotificationStatus.COMMENT_CREATED_MENTION)) {
        if (notification?.data?.comment) {
          this.getChannelById(notification?.data?.comment?.channel_id).pipe(filter(v => !!v), take(1)).subscribe(channel => {
            if (channel?.is_social) {
              this.router.navigateByUrl(`/talk/channels/${notification?.data?.comment?.channel_id}/socialTopic/${notification?.data?.comment?.topic_id}?commentId=${notification?.data?.comment?.id}`);
            } else {
              this.router.navigateByUrl(`/talk/channels/${notification?.data?.comment?.channel_id}/topics/${notification?.data?.comment?.topic_id}?commentId=${notification?.data?.comment?.id}`);
            }
          });
        }
      } else if (notification.notification_type && (notification.notification_type === NotificationStatus.SOCIAL_POST_CREATED || notification.notification_type === NotificationStatus.SOCIAL_POST_REPOST_CREATED)) {
        const post = notification?.data?.post;
        if (post) {
          this.router.navigateByUrl("/talk/social/" + post?.author?.jid + "/status/" + post?.id);
        }
      } else if (notification.notification_type && notification.notification_type === NotificationStatus.SOCIAL_POST_REPLY_CREATED) {
        const post = notification?.data?.post;
        if (post) {
          this.router.navigateByUrl("/talk/social/" + post?.author?.jid + "/status/" + post?.id);
        }
      } else {
        this.translate.get("UNDER_DEVELOPMENT")
          .pipe(take(1))
          .subscribe(text => {
            this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
          });
      }
    }
  }

  addNotificationIntoStore(notification) {
    if (notification) {
      const defaultImageIndex = Math.floor(Math.random() * 12);
      const period_type = CommonUtil.getCalendarDateText(notification?.created_at);
      notification = { ...notification, defaultImageIndex, period_type };
      this.channelRootStore.dispatch(new NotificationAdd(notification));
      const $notificationInfoOb = this.channelRootStore.select(getNotificationInfo);
      $notificationInfoOb.pipe(take(1), map(notificationInfo => {
        return {
          ...notificationInfo,
          ids: this.includeItem(notificationInfo.ids, notification.id)
        };
      }))
        .subscribe(newNotificationInfo => {
          this.channelRootStore.dispatch(new NotificationInfoUpdate(newNotificationInfo));
        });
      const $unreadNotificationInfoOb = this.channelRootStore.select(getUnreadNotificationInfo);
      $unreadNotificationInfoOb.pipe(take(1), map(notificationInfo => {
        return {
          ...notificationInfo,
          ids: this.includeItem(notificationInfo.ids, notification.id)
        };
      }))
        .subscribe(newNotificationInfo => {
          this.channelRootStore.dispatch(new UreadNotificationInfoUpdate(newNotificationInfo));
        });
    }
  }

  getDefaultCovers(): Observable<DefaultCover[]> {
    return this.channelRootStore.select(getTopicDefaultCovers)
      .pipe(
        tap(res => {
          if (!res || res.length === 0) {
            this.channelService.getDefaultCovers()
              .subscribe((res: any) => {
                this.channelRootStore.dispatch(new TopicDefaultCoversAdd(res.default_covers));
              });
          }
        })
      );
  }

  getDefaultCoverByCoverName(name) {
    return this.channelRootStore.select(state => getDefaultCoverByName(state, name));
  }

  isFileSizeExceedLimit(file) {
    if (this.userConfig && this.userConfig.attachment_max_size) {
      let size = CommonUtil.bytesToKiloBytes(file?.size);
      if (size > this.userConfig.attachment_max_size) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  showFileExceedNotification(file) {
    if (this.userConfig && this.userConfig.attachment_max_size) {
      const limitSize = CommonUtil.bytesToSize(this.userConfig.attachment_max_size * 1024);
      this.translate.get("FILE_LIMIT").pipe(take(1)).subscribe(text => {
        this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
      });
    }
  }

  selectTopicDetail(topicId: Topic["id"]) {
    return this.channelRootStore.select(state => getTopicById(state, topicId)).pipe(switchMap(topic => {
      const $topicDetail = new BehaviorSubject<any>(topic);
      if (!topic) {
        this.channelService.getTopicDetails(topicId)
          .pipe(map((v: any) => v.topic as Topic))
          .subscribe(value => {
            this.channelRootStore.dispatch(new TopicAdd(value));
          }, value => {
            const errorMessage = value.error.error;
            if (errorMessage === "Not Found") {
              $topicDetail.next({ [SmartLinkErrorType.NOT_FOUND]: true });
            } else if (errorMessage === "Forbidden") {
              $topicDetail.next({ [SmartLinkErrorType.NOT_ACCESSIBLE]: true });
            }
          });
      }
      return $topicDetail.asObservable().pipe(filter(i => !!i));
    }));
  }

  selectChannelDetail(channelId: Channel["id"]) {
    return this.channelRootStore.select(state => getChannelById(state, channelId)).pipe(switchMap(channel => {
      const $channelDetail = new BehaviorSubject<any>(channel);
      if (!channel) {
        this.channelService.getChannelById(channelId)
          .pipe(map((v: any) => v.channel as Channel))
          .subscribe(value => {
            if (!!value) {
              this.channelRootStore.dispatch(new ChannelAdd(value));
            }
          }, value => {
            const errorMessage = value.error.error;
            if (errorMessage === "Not Found") {
              $channelDetail.next({ [SmartLinkErrorType.NOT_FOUND]: true });
            } else if (errorMessage === "Forbidden") {
              $channelDetail.next({ [SmartLinkErrorType.NOT_ACCESSIBLE]: true });
            }
          });
      }
      return $channelDetail.asObservable().pipe(filter(i => !!i));
    }));
  }

  selectCommentById(commentId: CommentItem["id"], parentId?: CommentItem["id"], topicId?: any) {
    return this.getComment(commentId, parentId, topicId).pipe(switchMap(comment => {
      const $commentDetail = new BehaviorSubject<any>(comment);
      if (!comment) {
        this.channelService.getCommentDetails(commentId)
          .pipe(map((v: any) => v.comment as CommentItem))
          .subscribe(value => {
            this.channelRootStore.dispatch(new CommentAdd(value));
          }, value => {
            const errorMessage = value.error.error;
            if (errorMessage === "Not Found") {
              $commentDetail.next({ [SmartLinkErrorType.NOT_FOUND]: true });
            } else if (errorMessage === "Forbidden") {
              $commentDetail.next({ [SmartLinkErrorType.NOT_ACCESSIBLE]: true });
            }
          });
      }
      return $commentDetail.asObservable().pipe(filter(i => !!i));
    }));
  }

  getCommentForSmartLink(commentId: CommentItem["id"], parentId?: CommentItem["id"], topicId?: any) {
    if (!!parentId && !!topicId) {
      return this.channelRootStore.select(state => getParentCommentInTopicById({ ...state }, commentId, topicId))
        .pipe(take(1), map(comment => {
          const findComment = (comment: CommentItem) => {
            for (const commentReply of (comment?.replies || [])) {
              if (commentReply.id === commentId) { return commentReply; }
              if (commentReply?.replies?.length) {
                const result = findComment(commentReply);
                if (result?.id === commentId) { return result; }
              }
            }
          };
          return findComment(comment);
        }));
    }
    return this.channelRootStore.select((state: any) => state?.channels?.comment?.entities?.[commentId]);
  }

  redirectToTalkIfChannelRemoved(channelId) {
    this.getSelectedChannelId()
      .pipe(take(1))
      .subscribe(id => {
        if (id !== null && id === channelId) {
          this.setActiveTabChannel();
          this.broadcaster.broadcast(ConstantsUtil.CLOSE_SIDEBAR);
          this.channelRootStore.dispatch(new SetSelectedChannelId(null));
          this.router.navigateByUrl(`/talk`);
        }
      });
  }

  removeTopicIntoStoreAndRedirect(topicId) {
    if (this.router.url.includes("topics/" + topicId)) {
      this.getSelectedChannelId()
        .pipe(take(1)).subscribe(channelId => {
          if (channelId !== null) {
            this.channelRootStore.dispatch(new SetSelectedChannelId(channelId));
            this.router.navigateByUrl(`/talk/channels/${channelId}`);
            this.router.events
              .pipe(filter(e => e instanceof NavigationEnd)
                , take(1))
              .subscribe(() => {
                this.removeTopicIntoStore(topicId);
              });
          }
        });
    } else {
      this.removeTopicIntoStore(topicId);
    }
  }

  translations: any = {};
  headerActions: any;

  async showFilePreview(fileAttachments: any[], file: any, isSocialImages?: boolean, is_iom?: boolean, playVid?: boolean, isGloablFile?: boolean) {
    this.logger.info("[previewDocument]", file?.content_type, file, fileAttachments);
    this.previewDocument(file, fileAttachments, isGloablFile);
  }

  previewDialog :any;
  mapper(current){
    return {
      "avatarFallback": {
        "email": current?.author?.jid,
        "fullName": current?.author?.name,
        "avatarUrl": current?.author?.avatar_url
      },
      "fileName": current?.filename,
      "fileType": current?.content_type,
      "fullName": current?.author?.name,
      "id": current?.id,
      "thumbnail_url": (!!current.content_type && current.content_type.startsWith("audio/")) ? "assets/img/icon-product-file-audio.svg" : current?.thumbnail_url,
      "timestamp": new Date(current?.created_on).getTime(),
      "url": current?.content_url
    };
  }

  previewDocument(document1, files?: any[], isGlobalFile?: boolean) {
    let document;
    if (!isGlobalFile) {
      document = this.mapper(document1);
      let updatedFiles = files.map(file => {
        return this.mapper(file);
      });
      files = updatedFiles.filter(f => (f.fileType.includes("audio") || f.fileType.includes("image") || f.fileType.includes("video")));
    } else {
      document = document1;
      files = files.filter(f => (f.fileType.includes("audio") || f.fileType.includes("image") || f.fileType.includes("video") || f.fileType.includes("webp")));
    }
    const style: any = {
      width: "100vw",
      maxWidth: "100vw",
      height: "100vh"
    };
    const triggerEvent = new Subject<any>();
    triggerEvent.subscribe(v => {
      switch (v?.action) {
        case "download": {
          if (isGlobalFile) {
            this.downloadFileGlobalSearch(v?.document);
          } else this.downloadFile(v.document);
          break;
        }
        case "copy":{
          CommonUtil.copyToClipboard([v?.document?.url]);
          this.translate.get("LINK_COPIED_TO_THE_CLIPBOARD").pipe(take(1)).subscribe(text => {
            this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
          });
          break;
        }
        case "image-copy-success-keyboard-shortcut":
        case "image-copy-success-trigger-event": {
          this.notificationService.openSnackBarWithTranslation("IMAGE_COPY_SUCCESS");
          break;
        }
        case "image-copy-error": {
          this.notificationService.openSnackBarWithTranslation("UNABLE_TO_COPY_FILE");
          break;
        }
        case "fullScreen": {
          break;
        }
        case "delete": {
          this.deleteAttachment(v?.document);
          break;
        }
        case "indexChange": {
          // do nothing!
          break;
        }
        default: {
          this.sharingService.underDevelopment(); break;
        }
      }
    });
    const translationsKey = ["CLOSE", "MORE_TEXT", "GALLERY_VIEW", "DELETE", "CREATE_VNCTASK", "EMAIL_TO_A_FRIEND", "ADD_TO_FAVORITES", "GOTO_SOURCE", "COPY_TO_CLIPBOARD", "SHARE", "FORWARD", "DOWNLOAD", "COPY_TO_CLIPBOARD", "SEND_TO_OWNCLOUD"];
    let translations: any = {};
    this.translate.get(translationsKey).pipe(take(1)).subscribe(v => {
      translations = v;
    });
    const headerActions = [
      {
        label: translations.DOWNLOAD,
        vncIconName: "download-new",
        action: "download"
      },

      {
        label: translations.COPY_TO_CLIPBOARD,
        vncIconName: "content-copy",
        action: "copy"
      },

    ];
    let menuActions = [

      {
        label: translations.DELETE,
        vncIconName: "delete-new",
        action: "delete"
      }
    ];
    let contextMenuActions = [
      {
        label: translations.FORWARD,
        vncIconName: "forward-new",
        action: "forward"
      }
    ];
    if (CommonUtil.isMobileSize()) {
      menuActions.push({
        label: translations.SEND_TO_OWNCLOUD,
        vncIconName: "own-cloud",
        action: "ownCloud"
      });
    }
    const fileExtension = !!document.fileName ? document.fileName.substr(document.fileName.lastIndexOf(".") + 1, document.fileName.length).toLowerCase() : "";
    this.logger.info("[channel.repo preview fileExtension: ", fileExtension, document);
    let isCommonMediaFile = fileExtension === "pdf"
      || CommonUtil.isImage(fileExtension)
      || CommonUtil.isAudio(fileExtension)
      || CommonUtil.isSupportedVideo(fileExtension);

    if (isCommonMediaFile) {
      let openDocument = { ...document };
      if (fileExtension === "pdf") {
        openDocument.fileType = "pdf";
        let serverURL = window.location.origin;
        if (environment.isCordova || environment.isElectron) {
          serverURL = localStorage.getItem("serverURL");
        }

        let openUrl = (document.url.startsWith("http")) ? document.url : serverURL + "/" + document.url;
        if (openUrl.startsWith("http://localhost")) {
          openUrl = "https://vnctalk.dev.vnc.de/" + document.url;
        }

        openDocument.url = encodeURIComponent(CommonUtil.isChannelFileURL(document.url) ? CommonUtil.addTokenToURL(openUrl) : document.url);
      }
      this.previewDialog = this.matDialog.open(DocumentPreviewComponent, Object.assign({
        backdropClass: "vnc-dialog-backdrop",
        panelClass: "vnc-preview-dialog-panel",
        disableClose: true,
        hasBackdrop: false,
        data: {
          forMobile: CommonUtil.isMobileSize(),
          document: openDocument,
          selectedId: openDocument.id,
          showOwnCloudIcon: "",
          showOwnCloudText: translations.SEND_TO_OWNCLOUD,
          closeText: translations.CLOSE,
          menuText: translations.MORE_TEXT,
          menuActions: menuActions,
          contextMenuActions: contextMenuActions,
          headerActions: headerActions,
          files: fileExtension === "pdf" ? [] : (isGlobalFile ? files : files.reverse()),
          triggerEvent: triggerEvent,
          incomingActionEvent: "",
          viewerMode: fileExtension === "pdf" ? "pdf" : this.configService.get("viewerMode"),
          triggerGallary: "",
          imageZoomSlider: true,
        },
        autoFocus: false
      }, style));
      this.previewDialog.afterClosed().pipe(take(1)).subscribe();
    } else {
      let serverURL = window.location.origin;
      if (environment.isCordova || environment.isElectron) {
        serverURL = localStorage.getItem("serverURL");
      }

      let openUrl = (document.url.startsWith("http")) ? document.url : serverURL + "/" + document.url;
      if (openUrl.startsWith("http://localhost")) {
        openUrl = "https://vnctalk.dev.vnc.de/" + document.url;
      }
      this.logger.info("channelrepo preview openOfficeDoc: ", document, openUrl);
      const docData = {
        fileType: fileExtension,
        fileName: document.fileName,
        url: CommonUtil.isChannelFileURL(document.url) ? CommonUtil.addTokenToURL(openUrl) : document.url
      };

      this.matDialog.open(FilePreviewDialogComponent, Object.assign({
          autoFocus: true,
          panelClass: "file-preview-dialog",
          data: {
              attachment: docData,
              triggerEvent: triggerEvent,
              headerActions: headerActions,
          }
      }, style));
    }
  }

  downloadFile(doc) {
    if (!doc.url || doc.url === "") {
      return;
    }
    window.open(doc.url);
  }

  deleteAttachment(doc) {
    this.getSelectedTopic().pipe(take(1)).subscribe(resp => {
      if (this.getPermission(resp?.permissions, "editable")) {
        this.channelService.deleteTopicAttachments([doc?.id]).pipe(take(1)).subscribe(resp => {
          this.translate.get("FILE_DELETED_SUCCESSFULLY")
            .pipe(take(1))
            .subscribe(text => {
              this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
              this.matDialog.closeAll();
            });
        }, (err) => {
          this.translate.get("SOME_UNKNOWN_ERROR")
            .pipe(take(1))
            .subscribe(text => {
              this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
            });
        });
      } else {
        this.translate.get("NOT_ALLOWED").pipe(take(1)).subscribe(text => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
        });
      }
    });
  }

  getPermission(permissions: any[] | null = null, toCheck: string) {
    return CommonUtil.getPermission(permissions, toCheck);
  }

  setThumbNailUrl(attachment) {
    let thumbnail_url = "";
    if (attachment?.content_url?.toLowerCase()?.endsWith("txt")) {
      thumbnail_url = "assets/img/icon-product-file-txt.svg";
    } else if (attachment?.content_url?.toLowerCase()?.endsWith("css")
      || attachment?.content_url?.toLowerCase()?.endsWith("html")
      || attachment?.content_url?.toLowerCase()?.endsWith("php")
      || attachment?.content_url?.toLowerCase()?.endsWith("c")
      || attachment?.content_url?.toLowerCase()?.endsWith("cpp")
      || attachment?.content_url?.toLowerCase()?.endsWith("h")
      || attachment?.content_url?.toLowerCase()?.endsWith("hpp")
      || attachment?.content_url?.toLowerCase()?.endsWith("js")
    ) {
      thumbnail_url = "assets/img/icon-product-file-markupcode.svg";
    } else if (attachment?.content_url?.toLowerCase()?.endsWith("doc") || attachment?.content_url?.toLowerCase()?.endsWith("docx")) {
      thumbnail_url = "assets/img/icon-product-file-word-box.svg";
    } else if (attachment?.content_url?.toLowerCase()?.endsWith("xls") || attachment.content_url?.toLowerCase()?.endsWith("xlsx")) {
      thumbnail_url = "assets/img/icon-product-file-excel-box.svg";
    } else if (attachment?.content_url?.toLowerCase()?.endsWith("ppt") || attachment?.content_url?.toLowerCase()?.endsWith("pptx")) {
      thumbnail_url = "assets/img/icon-product-file-powerpoint-box.svg";
    } else if (attachment?.content_url?.toLowerCase()?.endsWith("pages")) {
      thumbnail_url = "assets/img/icon-product-file-pages.svg";
    } else if (attachment?.content_url?.toLowerCase()?.endsWith("ai")) {
      thumbnail_url = "assets/img/icon-product-file-ai.svg";
    } else if (attachment?.content_url?.toLowerCase()?.endsWith("psd")) {
      thumbnail_url = "assets/img/icon-product-file-psd.svg";
    } else if (attachment?.content_url?.toLowerCase()?.endsWith("tiff")) {
      thumbnail_url = "assets/img/icon-product-file-tiff.svg";
    } else if (attachment?.content_url?.toLowerCase()?.endsWith("dxf")) {
      thumbnail_url = "assets/img/icon-product-file-autodesk.svg";
    } else if (attachment?.content_url?.toLowerCase()?.endsWith("svg")) {
      thumbnail_url = "assets/img/icon-product-file-svg.svg";
    } else if (attachment?.content_url?.toLowerCase()?.endsWith("ttf")) {
      thumbnail_url = "assets/img/icon-product-file-ttf.svg";
    } else if (attachment?.content_url?.toLowerCase()?.endsWith("xps")) {
      thumbnail_url = "assets/img/icon-product-file-xml.svg";
    } else if (attachment?.content_url?.toLowerCase()?.endsWith("zip") || attachment?.content_url?.toLowerCase()?.endsWith("rar")) {
      thumbnail_url = "assets/img/icon-product-file-zip-box.svg";
    } else if (attachment?.content_url?.toLowerCase()?.endsWith("eps") || attachment?.content_url?.toLowerCase()?.endsWith("ps")) {
      thumbnail_url = "assets/img/icon-product-file-eps.svg";
    } else if (attachment?.content_url?.toLowerCase()?.endsWith("xps")) {
      thumbnail_url = "assets/img/icon-product-file-xml.svg";
    }

    return thumbnail_url;

  }
  markAsReadAllNotifications() {
    this.channelService.markAsReadAllNotification().subscribe(() => {
      this.getUnreadNotificationCount();
      this.channelRootStore.select(getAllNotificationList).pipe(take(1)).subscribe(notifications => {
        notifications = notifications.map(notification => {
          const read = true;
          return { ...notification, read };
        });
        this.channelRootStore.dispatch(new NotificationBulkAdd(notifications));
      });
    });
  }

  markAsReadNotifications(periodName) {
    this.channelService.markAsReadNotificationByPeriod(periodName).subscribe(res => {
      let first_notification_id = res?.first_notification_id;
      let last_notification_id = res?.last_notification_id;
      this.getUnreadNotificationCount();
      this.markAsreadNotificationRangeInStore(first_notification_id, last_notification_id);
    });
  }

  markAsreadNotificationRangeInStore(first_notification_id, last_notification_id) {
    this.channelRootStore.select(getAllNotificationList).pipe(take(1)).subscribe(notifications => {
      notifications = notifications.filter(notification => notification.id >= first_notification_id && notification.id <= last_notification_id);
      notifications = notifications.map(notification => {
        const read = true;
        return { ...notification, read };
      });
      this.channelRootStore.dispatch(new NotificationBulkAdd(notifications));
    });
  }

  getUnreadNotificationCountStore() {
    return this.channelRootStore.select(getUnreadNotificationCount);
  }

  shareLink(shareURL: string): void {
    const options = {
      message: shareURL,
      subject: "VNCchannels",
      chooserTitle: "Share with" // Android only, you can override the default share sheet title
    };
    const onSuccess = function () { };
    const onError = function () { };
    window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
  }

  getSubscribeTopics(): Observable<any> {
    return this.store.select(getAllSubscribeTopics);
  }

  setTopicToSubscribeTopics(topics: Topic[]): void {
    this.store.dispatch(new SubsribeTopicLoadSuccess(topics));
  }

  getSubscribeTopicList(offset, limit, query): void {
    let userId: number = 0;
    this.store.select(getUserConfig).pipe(filter(res => !!res), take(1)).subscribe(userConfig => {
      userId = userConfig.id;
    });
    this.channelService.getSubscribeTopics(userId, offset, limit, query).pipe(take(1)).subscribe(res => {
      res.topics.forEach((t) => (t.id = t.is_iom ? "iom-" + t.id : t.id));
      const info = {
        totalCount: res.total_count,
        offset: offset + res.topics.length,
        isLoaded: true,
        isLoading: false
      };
      this.channelRootStore.dispatch(new AllSubscribeTopicsInfoUpdate(info));
      this.setTopicToSubscribeTopics(res.topics);
    });
  }

  loadMoreSubscribeTopicList(offset, limit, query): void {
    let userId: number = 0;
    this.store.select(getUserConfig).pipe(filter(res => !!res), take(1)).subscribe(userConfig => {
      userId = userConfig.id;
    });
    let totalCount = 0;
    this.channelRootStore.select(getAllSubscribeTopicsInfo).pipe(take(1)).subscribe(resp => {
      if (resp) {
        totalCount = resp.totalCount;
        if (offset > resp.offset) {
          offset = resp.offset;
        }
      }
    });
    if (totalCount > offset) {
      this.channelService.getSubscribeTopics(userId, offset, limit, query).pipe(take(1)).subscribe(res => {
        res.topics.forEach((t) => (t.id = t.is_iom ? "iom-" + t.id : t.id));
        this.setTopicToSubscribeTopics(res.topics);
        const info = {
          totalCount: res.total_count,
          offset: offset + res.topics.length,
          isLoaded: true,
          isLoading: false
        };
        this.channelRootStore.dispatch(new AllSubscribeTopicsInfoUpdate(info));
      });
    }
  }

  underDevelopmemnt(): void {
    this.translate.get("UNDER_DEVELOPMENT").pipe(take(1)).subscribe(text => {
      this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
    });
  }


  async openProfileDialog(member: Member) {
    let options: any = {
      width: "600px",
      height: "680px",
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        width: "100vw",
        height: "100vh"
      };
    }
    if (member.jid === this.userJID?.bare) {
      this.broadcaster.broadcast("SHOW_PROFILE_DIALOG");
    } else {
      const { ProfileInfoDialogComponent } = await import(
        "app/shared/components/profile-info-dialog/profile-info-dialog.component");
      this.matDialog.open(ProfileInfoDialogComponent, Object.assign({
        backdropClass: "vnctalk-form-backdrop",
        panelClass: "vnctalk-form-panel",
        disableClose: true,
        data: {
          jid: member.jid
        },
        autoFocus: true
      }, options));
    }
  }

  isMemberLoginUser(member: Member) {
    if (member.jid === this.userJID?.bare) {
      return true;
    }
    return false;
  }

  getNotificationInfo() {
    return this.channelRootStore.select(getNotificationInfo);
  }

  getAllSubscribeTopicInfo() {
    return this.channelRootStore.select(getAllSubscribeTopicsInfo);
  }

  getRightbarExpanded() {
    return this.store.select(getIsRightSideBarExpanded);
  }

  getSidebarRoasterExpanded() {
    return this.store.select(getIsSideRostersExpanded);
  }

  loadMoreSocialTopics(channelId: string, query?: string, offset?: number, limit?: number, isMedia?: boolean) {
    let totalCount = 0;
    if (!isMedia) {
      this.channelRootStore.select(state => getTopicsInfoByChannelId(state, channelId))
        .pipe(take(1))
        .subscribe(topicInfo => {
          if (topicInfo) {
            totalCount = topicInfo.totalCount;
            if (offset > topicInfo.offset) {
              offset = topicInfo.offset;
            }
          }
        });
    } else {
      this.channelRootStore.select(state => getMediaTopicsInfoByChannelId(state, channelId))
        .pipe(take(1))
        .subscribe(topicInfo => {
          if (topicInfo) {
            totalCount = topicInfo.totalCount;
            if (offset > topicInfo.offset) {
              offset = topicInfo.offset;
            }
          }
        });
    }
    if (totalCount > offset) {
      this.channelService.getTopics(channelId, offset, limit, query)
        .pipe(take(1))
        .subscribe((res: any) => {
          if (res && !res.error) {
            const topics: Topic[] = (res.topics as Topic[]).map((t) => {
              return { ...t, id: t.is_iom ? "iom-" + t.id : t.id };
            });
            const info = {
              totalCount: res.total_count,
              offset: offset + topics.length,
              isLoaded: true
            };
            this.channelRootStore.dispatch(new TopicBulkAdd(topics));
            this.channelRootStore.dispatch(new TopicInfoUpdate({ channelId, info }));
          }
          else {
            const info = {
              totalCount: totalCount,
              offset: offset,
              isLoaded: true
            };
            this.channelRootStore.dispatch(new TopicInfoUpdate({ channelId, info }));
          }
        });
    }
  }

  getMediaTopicsInfoByChannelId(channelId: string) {
    return this.channelRootStore.select(state => getMediaTopicsInfoByChannelId(state, channelId));
  }

  copySocialLink(type: string, channelId: string, topicId?: string) {
    let currentUrl = CommonUtil.getBaseOriginUrl();
    if (type === "channel") {
      currentUrl = `${currentUrl}/talk/channels/${channelId}/socialTopic`;
    }
    else if (type === "topic") {
      currentUrl = `${currentUrl}/talk/channels/${channelId}/socialTopic/${topicId}`;
    }
    CommonUtil.copyToClipboard([currentUrl]);
    this.translate.get("LINK_COPIED_TO_THE_CLIPBOARD").pipe(take(1)).subscribe(text => {
      this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
    });
  }

  addCommentReply(text: string, topicId: any, isReply = false, attachments: any[] = [], parentId?: number) {
    const body = {
      comments: text,
      parent_comment_id: parentId,
      uploads: { ...attachments }
    };
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(text, "text/html");
    const users = htmlDoc.getElementsByClassName("mention");
    const userIds = Array.from(users).map(user => user.getAttribute("data-id"));
    this.logger.info("[COMMENT_MENTION]", isReply, userIds);

    this.channelService.addComment(body, topicId).subscribe((v: any) => {
      if (v && !v.error) {
        this.logger.info("[CommentsComponent] addComment", v);
        this.updateTopicCommentCount(topicId, 1, 0);
        if (v.comment) {
          const comment = CommonUtil.getMappedComments([v.comment])[0] as CommentItem;
          this.addCommentIntoStore(comment);
        }
      }
    });
  }

  generateHeaderAttachments(topic: Topic) {
    let headerAttachments = [];
    if (topic.topic_type === "video") {
      if (topic?.external_video_url) {
        const thumbnail = topic.attachments.find(attachment => attachment?.is_default_thumbnail === true);
        if (!!thumbnail) {
          headerAttachments = [{ ...thumbnail }];
        }
      } else {
        const video = topic.attachments.find(attachment => attachment?.is_video === true);
        let defaultThumbnail = topic.attachments.find(attachment => attachment?.is_default_thumbnail === true);
        if (!!video) {
          let thumbnail_url = video?.thumbnail_url;
          if (video && defaultThumbnail) {
            thumbnail_url = defaultThumbnail?.thumbnail_url;
          }
          headerAttachments = [{ ...video, thumbnail_url }];
        }
      }
    } else {
      headerAttachments = topic?.attachments.filter(attachment => attachment?.is_header === true);
    }
    return headerAttachments;
  }

  generateLocalAttachmentsURLs(attachments: Attachment[], is_iom?: boolean): Attachment[] {
    const updatedAttachments = attachments.map(attachment => {
      let content_url = is_iom ? attachment?.content_url : CommonUtil.getAttachmentLocalAPIURL(attachment?.content_url);
      let thumbnail_url = is_iom ? attachment?.thumbnail_url : CommonUtil.getAttachmentLocalAPIURL(attachment?.thumbnail_url);
      return { ...attachment, content_url, thumbnail_url };
    });
    return updatedAttachments;
  }

  getActiveMemberOfChannelFromSolr(channel): Observable<any> {
    const response = new Subject<any>();
    this.channelService.getChannelActiveMemberFromSolr(channel?.id).pipe(take(1)).subscribe((res: any) => {
      if (!!res) {
        res = JSON.parse(res);
        const allMembers = res.map(mem => {
          return {
            id: mem?.id,
            active: true,
            avatar_url: mem?.avatarUrl,
            jid: mem?.jid,
            name: mem?.name,
            role: mem?.role
          };
        }) || [];
        response.next(allMembers);
      } else {
        response.next([]);
      }
    }, error => {
      response.next([]);
      this.logger.info("[Error][getActiveMemberOfChannelFromSolr]", error);
    });
    return response.asObservable();
  }

  getTranslatedText(text, textToBeReplaced?: { key: string, value: string }) {
    let translatedText = "";
    this.translate.get(text, { [textToBeReplaced?.key]: textToBeReplaced?.value }).pipe(take(1)).subscribe(res => {
      translatedText = res;
    });
    return translatedText;
  }

  async addSubchannel(item) {
    item.createNew = true;
    let options: any = {
      width: "480px",
      height: "730px",
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        width: "100vw",
        height: "100vh"
      };
    }
    const { CreateSubChannelComponent } = await import(
      /* webpackPrefetch: true */
      "app/channels/create-subchannel/create-subchannel.component");
    this.matDialog.open(CreateSubChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      disableClose: true,
      data: item,
      autoFocus: true
    }, options))
      .afterClosed()
      .pipe(take(1))
      .subscribe(res => {
        if (res && res.channel) {
          if (item.is_public === !res.channel.is_public) {
            this.changeChannelCategory(item, "is_public");
          }
          this.addChannelIntoStore(res.channel);
          item.sub_channels_count = item.sub_channels_count + 1;
          this.updateChannelIntoStore(item);
          this.router.navigateByUrl(`/talk/channels/${res.channel.id}`);
        }
      });
  }

  downloadFileGlobalSearch(message) {
    if (!message.url || message.url === "") {
      return;
    }
    // if download image on mobile
    if (environment.isCordova &&
      (message.url.includes(".jpeg") || message.url.includes(".png") || message.url.includes(".jpg") || message.url.includes(".gif"))) {
      this.toastService.showSnackbar("FILE_STARTED_DOWNLOADING", 2000);

      this.conversationRepository.downloadFileToDownloadsOrGallery(message.url).subscribe(() => {
        this.toastService.showSnackbar("FILE_DOWNLOADED", 2000);
      }, err => {
        this.logger.error("[PreviewImageDialogComponent][downloadFile] error", err);
      });
    } else if (environment.isElectron) {
      // ElectronService.downloadFile(this.message.attachment.url, "");
      this.electronService.fileDownloader(message.url).subscribe(() => {
        this.toastService.showSnackbar("FILE_DOWNLOADED", 2000);
      }, (error) => {
        this.logger.info("[PreviewImageDialogComponent][downloadFile] error: ", error);
      });
    } else {
      let messageDetails = {
        messageId: message?.id,
        fileSize: message?.fileSize,
        fileName: message?.fileName,
        url: message?.url,
        loaded: 0,
        total: parseFloat(message?.fileSizeNumber)
      };
      this.conversationRepository.downloadFile(messageDetails);
    }
  }
}



export enum FileGrouping {
  "DATE_ASC" = "created_on:asc",
  "DATE_DESC" = "created_on:desc",
  "TOPIC_ASC" = "topic_asc",
  "TOPIC_DESC" = "topic_desc",
  "AUTHOR_ASC" = "author_asc",
  "AUTHOR_DESC" = "author_desc"
}
