
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Observable, Subject, take } from "rxjs";
import { CommonUtil } from "app/talk/utils/common.util";
import { LoggerService } from "app/shared/services/logger.service";
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";

@Injectable()
export class FilesStorageService {
  private currentDownloadPromise;

  constructor(private logger: LoggerService,
    private http: HttpClient) {
    document.addEventListener("deviceready", this.deviceReady.bind(this), false);
  }

  private deviceReady() {
    this.logger.info("[FilesStorageService] deviceReady");
  }


  //// pathes

  privateFilePath(fileName: string){
    return cordova.file.dataDirectory + fileName;
  }

  androidDownloadFolderFilePath(fileName: string){
    return `Download/${fileName}`;
  }

  androidExternalDataDirectoryFilePath(fileName: string){
    // return cordova.file.externalDataDirectory + "vnctalk_downloads/" + fileName;
    return cordova ? cordova.file.externalDataDirectory + fileName : fileName;
  }

  ////

  saveBlobToDisc(blob: Blob, fileName: string, localFileSystemURL = null): Observable<string> {
    const response = new Subject<string>();

    localFileSystemURL = CommonUtil.isOnIOS() ? cordova.file.dataDirectory : cordova.file.externalDataDirectory;

    this.logger.info("[FilesStorageService][saveBlobToDisc] success", fileName, blob, localFileSystemURL);
    const self = this;
    window.resolveLocalFileSystemURL(localFileSystemURL, (dir) => {
      dir.getFile(fileName, { create: true, exclusive: false},  (file) => {
        file.createWriter( (fileWriter) => {
          fileWriter.write(blob);
          fileWriter.onwriteend = () => {
            let localFileUrl = this.privateFilePath(fileName);
            if (CommonUtil.isOnAndroid()) {
              localFileUrl = `${cordova.file.externalDataDirectory}${fileName}`;
            }
            self.logger.info("[FilesStorageService][saveBlobToDisc] success", fileName, localFileUrl);
            response.next(localFileUrl);
          };
          fileWriter.onerror = (err) => {
            self.logger.info("[FilesStorageService][saveBlobToDisc] error1", err);
            response.error(err);
            file.remove( () => {}, () => {}, () => {});
          };
        }, (err)  => {
          self.logger.info("[FilesStorageService][saveBlobToDisc] error2", err);
          response.error(err);
        });
      });
    }, (err) => {
      self.logger.info("[FilesStorageService][saveBlobToDisc] error3", err);
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  readBlobFromDisc(fileName: string): Observable<Blob> {
    const response = new Subject<Blob>();

    const localFileUrl = this.privateFilePath(fileName);
    const self = this;
    window.resolveLocalFileSystemURL(localFileUrl,  (fileEntry) => {
      fileEntry.file((file) => {
        let reader = new FileReader();
        reader.onloadend = function () {
          let blob = new Blob([new Uint8Array(this.result as ArrayBuffer)]);
          // var blob = new Blob([new Uint8Array(this.result)], { type: "image/png" });

          response.next(blob);
        };
        reader.readAsArrayBuffer(file);
      });
    }, (err) => {
      self.logger.info("[FilesStorageService] readBlobFromDisc error", err);
      response.next(null);
    });

    return response.asObservable().pipe(take(1));
  }

  downloadFileAsBlob(serverUrl, headers = []): Observable<Blob> {
    this.logger.info("[FilesStorageService][downloadFileAsBlob]", serverUrl);

    const response = new Subject<Blob>();
    const self = this;
    let xhr = new XMLHttpRequest();
    xhr.open("GET", serverUrl);
    headers.forEach(function(header){
      self.logger.info("[FilesStorageService][downloadFileAsBlob] header: " + header.Key + " - " + header.Value);
      xhr.setRequestHeader(header.Key, header.Value);
    });
    xhr.responseType = "blob"; // force the HTTP response, response-type header to be blob
    xhr.onreadystatechange =  () => {
      if (xhr.status === 0) {
        response.error(new Error("Error in file download: internet is not available"));
        return;
      }

      if (xhr.readyState === 4) {
        if (xhr.status === 200 || xhr.status === 304) {
          const blob = xhr.response; // xhr.response is now a blob object
          self.logger.info("[FilesStorageService][downloadFileAsBlob] blob", serverUrl, blob);
          response.next(blob);
        } else if (xhr.status >= 400) {
          response.error(new Error("Error in file download: status" + xhr.status));
        }
      }
    };

    xhr.send();

    return response.asObservable().pipe(take(1));
  }

  isFileDownloadedToDownloadsOrGallery(fileName, isIOS = false): Observable<any> {
    const response = new Subject<any>();

    const localFileUrl = isIOS ? this.privateFilePath(fileName) : this.androidDownloadFolderFilePath(fileName);

    this.logger.info("[FilesStorageService][isFileDownloadedToDownloadsOrGallery]", fileName, localFileUrl);
    const self = this;
    if (isIOS) {
      window.resolveLocalFileSystemURL(cordova.file.dataDirectory, (dir) => {
        dir.getFile(fileName, { create: false},  () => {
          self.logger.info("[FilesStorageService][isFileDownloadedToDownloadsOrGallery] file exists");
          response.next(localFileUrl);
        }, (err) => {
          self.logger.info("[FilesStorageService][isFileDownloadedToDownloadsOrGallery] error1", err);
          response.next(false);
        });
      }, (err) => {
        self.logger.info("[FilesStorageService][isFileDownloadedToDownloadsOrGallery] error3", err);
        response.next(false);
      });
    } else {
      window["requestFileSystem"](window["PERSISTENT"], 0, (fs) => {
        fs.root.getFile(localFileUrl, { create: false }, () => {
          self.logger.info("[FilesStorageService][isFileDownloadedToDownloadsOrGallery] file exists");
          response.next(localFileUrl);
        }, (err) => {
          self.logger.info("[FilesStorageService][isFileDownloadedToDownloadsOrGallery] error1", err);
          response.next(false);
        });
      }, (err) => {
        self.logger.info("[FilesStorageService][isFileDownloadedToDownloadsOrGallery] error2", err);
        response.next(false);
      });
    }

    return response.asObservable().pipe(take(1));
  }

  downloadAndSaveFileInBackground(fileServerUrl, headers = [], isIOS = false): Observable<string> {
    const response = new Subject<string>();
    const self = this;
    let localFileName = fileServerUrl.substring(fileServerUrl.lastIndexOf("/") + 1);
    if (fileServerUrl.indexOf("fetchRecording") !== -1) {
      localFileName += ".mp4";
    }
    self.logger.info("[FilesStorageService][downloadInBackgroundFileAsBlob]", fileServerUrl, localFileName, isIOS);
    if (isIOS) {
      window.resolveLocalFileSystemURL(cordova.file.dataDirectory, (dir) => {

         // https://github.com/pwlin/cordova-plugin-file-opener2/issues/14
         self.logger.info("[FilesStorageService][downloadAndSaveFileInBackground] localFileName origin: ", localFileName);
        localFileName = decodeURIComponent(localFileName);
        localFileName = localFileName.replace(/[^a-zA-Z0-9-_.]/g, "_");
        localFileName = encodeURIComponent(localFileName);
        self.logger.info("[FilesStorageService][downloadAndSaveFileInBackground] localFileName processed: ", localFileName);

        dir.getFile(localFileName, { create: true, exclusive: false},  (targetFile) => {
          self.logger.info("[FilesStorageService][downloadAndSaveFileInBackground] getFile, targetFile: ", JSON.stringify(targetFile));

          this.backgroundFileDownload(fileServerUrl, targetFile, headers).subscribe(() => {
            const localFileUrl = this.privateFilePath(localFileName);
            response.next(localFileUrl);
          }, err => {
            response.error(err);
          });
        });
      }, (err) => {
        self.logger.error("[FilesStorageService][downloadAndSaveFileInBackground] resolveLocalFileSystemURL error", err);
        response.error(err);
      });
    } else {
      window.resolveLocalFileSystemURL(cordova.file.externalRootDirectory, (directoryEntry) => {
        self.logger.info("[FilesStorageService][downloadInBackgroundFileAsBlob] directoryEntry ", directoryEntry);
        const localFileUrl = this.androidDownloadFolderFilePath(localFileName);
        directoryEntry.getFile(localFileUrl, { create: true, exclusive: false }, (fileEntry) => {
          self.logger.info("[FilesStorageService][downloadInBackgroundFileAsBlob] getFile ", fileEntry, fileEntry.toURL());
        const fileTransfer = new FileTransfer();
        const filePath = fileEntry.nativeURL;
        const decodedPath = decodeURIComponent(filePath);
        self.logger.info("[FilesStorageService][downloadInBackgroundFileAsBlob] getFile decodedPath", decodedPath);

        const fileURL = decodedPath;
          fileTransfer.download(
            fileServerUrl,
            fileURL,
            function (entry) {
              self.logger.info("Successful download...");
              self.logger.info("download complete: " + entry.toURL());
              response.next(entry.toURL());
            },
            function (error) {
              self.logger.info("download error source " + error.source);
              self.logger.info("download error target " + error.target);
              self.logger.info("upload error code" + error.code);
              response.error(error);
            },
            null, // or, pass false
            {
              headers: headers
            }
          );
        }, (err) => {
          self.logger.info("[FilesStorageService][downloadInBackgroundFileAsBlob] getFile err", err);
          response.error(err);
        });
      }, (err) => {
        self.logger.info("[FilesStorageService][downloadInBackgroundFileAsBlob] resolveLocalFileSystemURL error", err);
        response.error(err);
      });
    }

    return response.asObservable().pipe(take(1));
  }

  private backgroundFileDownload(fileServerUrl, targetFile, headers = []) {
    this.logger.info("[FilesStorageService][backgroundFileDownload]", fileServerUrl);

    const response = new Subject<any>();
    const self = this;
    const onSuccess = () => {
      self.logger.info("[FilesStorageService][backgroundFileDownload] onSuccess");
      response.next(true);
    };
    const onError = (err) => {
      self.logger.error("[FilesStorageService][backgroundFileDownload] onError", err);
      response.error(err);
    };
    const onProgress = (progress) => {
      self.logger.info("[FilesStorageService][backgroundFileDownload] onProgress", progress);
    };

    const downloader = new BackgroundTransfer.BackgroundDownloader();
    const download = downloader.createDownload(fileServerUrl, targetFile, null, headers);

    // Start the download and persist the promise to be able to cancel the download.
    self.currentDownloadPromise = download.startAsync().then(onSuccess, onError, onProgress);

    return response.asObservable().pipe(take(1));
  }

  // Save to Downloads (Android)

  saveBlobToAndroidDownloadFolder(blob: Blob, fileName: string): Observable<string> {
    const response = new Subject<string>();

    const fileUrl = this.androidDownloadFolderFilePath(fileName);
    const self = this;
    window.resolveLocalFileSystemURL(cordova.file.dataDirectory, (directoryEntry) => {
      self.logger.info("[FilesStorageService][saveBlobToAndroidDownloadFolder] directoryEntry", directoryEntry);
      directoryEntry.getFile(fileUrl, { create: true, exclusive: false }, (fileEntry) => {
        self.logger.info("[FilesStorageService][saveBlobToAndroidDownloadFolder] getFile", fileEntry);
        fileEntry.createWriter((fileWriter) => {
          fileWriter.onwriteend = () => {
            self.logger.info("[FilesStorageService] saveBlobToAndroidDownloadFolder success", fileUrl);
            response.next(fileUrl);
          };
          fileWriter.onerror = function (e) {
            response.error(e);
          };
          fileWriter.write(blob);
        });
      }, (err) => self.logger.info("[FilesStorageService][saveBlobToAndroidDownloadFolder] getFile err", err));
    }, (err) => {
      self.logger.info("[FilesStorageService][saveBlobToAndroidDownloadFolder] resolveLocalFileSystemURL error", err);
    });

    // window["requestFileSystem"](window["PERSISTENT"], blob.size, function (fs) {
    //   fs.root.getFile(fileUrl, { create: true, exclusive: false }, (fileEntry) => {
    //     fileEntry.createWriter((fileWriter) => {
    //       fileWriter.onwriteend = () => {
    //         this.logger.info("[FilesStorageService] saveBlobToAndroidDownloadFolder success", fileUrl);
    //         response.next(fileUrl);
    //       };
    //       fileWriter.onerror = function (e) {
    //         response.error(e);
    //       };
    //       fileWriter.write(blob);
    //     });
    //   }, (err) => {
    //     this.logger.info("[FilesStorageService] saveBlobToAndroidDownloadFolder error1", err);
    //     response.error(err);
    //   });
    // }, (err) => {
    //   this.logger.info("[FilesStorageService] saveBlobToAndroidDownloadFolder error2", err);
    //   response.error(err);
    // });

    return response.asObservable().pipe(take(1));
  }

  isFileDownloadedToExternalOrGallery(fileName, isIOS = false): Observable<any> {
    const response = new Subject<any>();

    const localFileUrl = isIOS ? this.privateFilePath(fileName) : this.androidExternalDataDirectoryFilePath(fileName);

    this.logger.info("[FilesStorageService][isFileDownloadedToExternalOrGallery]", fileName, localFileUrl);

    // https://stackoverflow.com/a/29167015/574475
    const fileSystemUrl = isIOS ? cordova.file.dataDirectory : cordova.file.externalDataDirectory;
    const self = this;
    window.resolveLocalFileSystemURL(fileSystemUrl, (dir) => {
      dir.getFile(fileName, { create: false},  () => {
        self.logger.info("[FilesStorageService][isFileDownloadedToExternalOrGallery] file exists");
        response.next(localFileUrl);
      }, (err) => {
        self.logger.info("[FilesStorageService][isFileDownloadedToExternalOrGallery] error1", err);
        response.next(false);
      });
    }, (err) => {
      self.logger.info("[FilesStorageService][isFileDownloadedToExternalOrGallery] error3", err);
      response.next(false);
    });

    return response.asObservable().pipe(take(1));
  }

  downloadFileFromBlob(blob: Blob, filename: string) {
    if (CommonUtil.isOnNativeMobileDevice()) {

    } else {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }

  download(fileUrl: string) {
    return this.http.get(fileUrl, {
      responseType : "blob",
      reportProgress : true,
      observe : "events",
    });
  }

}
